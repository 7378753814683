import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../store/hooks";
import {selectAuth} from "../../store/modules/authSlice";
import PageTitle, {
    FlexBottom, FlexColumn,
    FlexColumnCenter, FlexRowCenter,
    FullScreen,
    PageContent,
    PageRootContainer,
    PopupBodyWrapper
} from "../common/pageArea";
import QRCode from "qrcode.react";
import styles from "../../assets/styles/uni.module.scss";
import {ellipseAddress} from "../../helpers/utilities";
import CopyToClipboard from "react-copy-to-clipboard";
import {Toast} from "../common/popup/ToastAlert";
import BottomButton from "../common/button/BottomButton";
import React from "react";
import {IQrPopup} from "../coin/qrPopup";
import EmptyContent from "../common/EmptyContent";


export default function BoostList(props:IQrPopup){
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            {
                props.show && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle textTitle={t('DRM 자금내역')}
                                           rightSrc={require('../../assets/images/home/close.png')}
                                           onClickRight={()=>{props.setShow(false)}}
                                />
                                <PageContent style={{paddingTop:'30px'}}>
                                    <EmptyContent titleText={t("자금 내역이 없습니다.")} titleImage={require('../../assets/images/list_x.png')}/>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}