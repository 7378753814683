import moment from "moment";
import React, {useEffect, useState} from "react";

import PageTitle, {
    FlexColumEnd,
    FlexColumn, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen, PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../../components/common/pageArea";

import { OrgDiagram } from 'basicprimitivesreact';
// import { PageFitMode, Enabled, Colors ,GroupByType,OrientationType} from 'basicprimitives';
import { Thickness, LevelAnnotationConfig, AnnotationType, Colors, LineType, GroupByType, PageFitMode, Enabled,OrientationType } from 'basicprimitives';

import {Slider} from "@material-ui/core";
import EmptyContent from "../../components/common/EmptyContent";
import {decimalFormat, ellipseAddress, ellipseAddressNew, maskingEmail} from "../../helpers/utilities";
import ProfileService from "../../services/profile.service";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../store/modules/profileSlice";

import grd1Img from "../../assets/images/grd/grd1.png";
import grd2Img from "../../assets/images/grd/grd2.png";
import grd3Img from "../../assets/images/grd/grd3.png";
import grd4Img from "../../assets/images/grd/grd4.png";
import grd5Img from "../../assets/images/grd/grd5.png";

// export interface RefOrgResDto {
//     id: string;
//     parent: string;
//     usrId: string;
//     usrNm: string;
//     shortNm: string;
//     grdCd: string;
//     stkVlm: string;
//     frstRefCnt: string;
//     depth: number;
// }

interface IRefPopup {
    show: boolean;
    setShow: any;
    // refList:any;
}
export default function RefPopup(props:IRefPopup) {
    const [refLoading, setRefLoading] = useState(false);
    const [allShow, setAllShow] = useState(false);
    // const [refList, setRefList] = useState<RefOrgResDto[]>([]);
    const {refList } = useAppSelector(selectProfile);

    const GRD_NM = {
        "GRD0":"일반"
        ,"GRD1":"1직급"
        ,"GRD2":"2직급"
        ,"GRD3":"3직급"
        ,"GRD4":"4직급"
        ,"GRD5":"5직급"
    }

    const [config, setConfig] = useState({
        pageFitMode: PageFitMode.FitToPage,
        highlightItem: 0,
        cursorItem: 0,
        hasSelectorCheckbox: Enabled.False,
        arrowsDirection: GroupByType.Children,
        orientationType: OrientationType.Top,
        annotations: [
            {
                annotationType: AnnotationType.Level,
                levels: [0],
                title: "Lv.1",
                titleColor: Colors.RoyalBlue,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
                // borderColor: Colors.Gray,
                // fillColor: Colors.Gray,
                // lineType: LineType.Dotted
            },
            new LevelAnnotationConfig({
                levels: [1],
                title: "Lv.2",
                titleColor: Colors.Green,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0.08,
            }),
            new LevelAnnotationConfig({
                levels: [2],
                title: "Lv.3",
                titleColor: Colors.Red,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),

            new LevelAnnotationConfig({
                levels: [3],
                title: "Lv.4",
                titleColor: Colors.Black,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),
            new LevelAnnotationConfig({
                levels: [4],
                title: "Lv.5",
                titleColor: 'rgb(176 121 23)',
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),
            new LevelAnnotationConfig({
                levels: [5],
                title: "Lv.6",
                titleColor: Colors.RoyalBlue,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0.08,
            }),
            new LevelAnnotationConfig({
                levels: [6],
                title: "Lv.7",
                titleColor: Colors.Green,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0.08,
            }),
            new LevelAnnotationConfig({
                levels: [7],
                title: "Lv.8",
                titleColor: Colors.Red,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),

            new LevelAnnotationConfig({
                levels: [8],
                title: "Lv.9",
                titleColor: Colors.Black,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),
            new LevelAnnotationConfig({
                levels: [9],
                title: "Lv.10",
                titleColor: 'rgb(176 121 23)',
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),
            new LevelAnnotationConfig({
                levels: [10],
                title: "Lv.11",
                titleColor: Colors.RoyalBlue,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0.08,
            }),
            new LevelAnnotationConfig({
                levels: [11],
                title: "Lv.12",
                titleColor: Colors.Green,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0.08,
            }),
            new LevelAnnotationConfig({
                levels: [12],
                title: "Lv.13",
                titleColor: Colors.Red,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),

            new LevelAnnotationConfig({
                levels: [13],
                title: "Lv.14",
                titleColor: Colors.Black,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),
            new LevelAnnotationConfig({
                levels: [14],
                title: "Lv.15",
                titleColor: 'rgb(176 121 23)',
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),
            new LevelAnnotationConfig({
                levels: [15],
                title: "Lv.16",
                titleColor: Colors.RoyalBlue,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0.08,
            }),
            new LevelAnnotationConfig({
                levels: [16],
                title: "Lv.17",
                titleColor: Colors.Green,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0.08,
            }),
            new LevelAnnotationConfig({
                levels: [17],
                title: "Lv.18",
                titleColor: Colors.Red,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),

            new LevelAnnotationConfig({
                levels: [18],
                title: "Lv.19",
                titleColor: Colors.Black,
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),
            new LevelAnnotationConfig({
                levels: [19],
                title: "Lv.20",
                titleColor: 'rgb(176 121 23)',
                offset: new Thickness(0, 0, 0, -1),
                lineWidth: new Thickness(0, 0, 0, 0),
                opacity: 0,
            }),
        ],
        templates: [{
            name: "contactTemplate",
            itemSize: { width: 140, height: 100 },
            onItemRender: ({ context: itemConfig }) => {
                const itemTitleColor = itemConfig.stkVlm>0 ? itemConfig.itemTitleColor01 : itemConfig.itemTitleColor;
                return <FlexColumn style={{ backgroundColor:  itemConfig.stkVlm>0 ? itemConfig.itemTitleColor01 : itemConfig.itemTitleColor ,width:'140px',height:'100px'}}>
                    <FlexColumn style={{ backgroundColor:  itemConfig.stkVlm>0 ? itemConfig.itemTitleColor01 : itemConfig.itemTitleColor , color:'#ffffff', alignItems:'center',gap:'3px',padding:'3px' }}>
                        <div>{itemConfig.usrNm}</div>
                        <div>{itemConfig.title}</div>
                        <div>{decimalFormat(itemConfig.stkVlm,0)} DRM</div>

                            {
                                itemConfig.grdCd=='GRD5'?
                                    (
                                        <FlexRowCenter style={{backgroundColor:'#a90707'}}>직급 : {GRD_NM[itemConfig.grdCd]}</FlexRowCenter>
                                    )
                                : itemConfig.grdCd=='GRD4'?
                                    (
                                        <FlexRowCenter style={{backgroundColor:'#a95807'}}>직급 : {GRD_NM[itemConfig.grdCd]}</FlexRowCenter>
                                    )
                                : itemConfig.grdCd=='GRD3'?
                                    (
                                        <FlexRowCenter style={{backgroundColor:'#0753a9'}}>직급 : {GRD_NM[itemConfig.grdCd]}</FlexRowCenter>
                                    )
                                : itemConfig.grdCd=='GRD2'?
                                    (
                                        <FlexRowCenter style={{backgroundColor:'#D2A902FF',color:'black'}}>직급 : {GRD_NM[itemConfig.grdCd]}</FlexRowCenter>
                                    )
                                : itemConfig.grdCd=='GRD1'?
                                    (
                                        <FlexRowCenter style={{backgroundColor:'#ff9f9f',color:'black'}}>직급 : {GRD_NM[itemConfig.grdCd]}</FlexRowCenter>
                                    )
                                :
                                    (
                                        <FlexRowCenter>직급 : {GRD_NM[itemConfig.grdCd]}</FlexRowCenter>
                                    )


                            }


                    </FlexColumn>
                </FlexColumn>;
            },
        }],
        scale: 0.5,
        items: [
        ]
    });

    // const fetchData = async() =>{
    //     let response = await ProfileService.getRefOrg();
    //     setRefList(response.data);
    // }
    //
    // useEffect(() => {
    //     if (props.show) {
    //         console.log('11111122211');
    //         fetchData();
    //     }
    // }, [props.show]);

    useEffect(() => {
        console.log(refList);
        if(refList.length > 0)
        {
            setRefLoading(true);
            const newItems = refList.map((item, index) => {
                let newItem = {
                    id: item.id,
                    parent: item.parent,
                    isVisible: true,
                    description: item.nftCnt,
                    usrNm: item.usrNm,
                    shortNm: item.shortNm,
                    stkVlm: item.stkVlm,
                    // itemTitleColor: "#4169e1",
                    itemTitleColor: "#969696FF",
                    itemTitleColor01: "#000000",
                    grdCd: item.grdCd,
                    title: ellipseAddressNew(item.usrId, 4),
                    templateName: "contactTemplate",
                }
                return newItem;
            });
            // console.log("newItems", newItems)
            setConfig({
                ...config,
                items: newItems
            });
        }
    }, [refList]);

    useEffect(()=>{
        return () => {
            if (props.show) {
                console.log("SHOPW =>>", props.show);
            }
        };

    },[props.show]);


    return (
        <>
            {
                props.show && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle textTitle={'추천내역'} rightSrc={require('../../assets/images/home/close.png')} onClickRight={()=>{props.setShow(false)}}/>
                                <PageContent>
                                    <FlexRowSpace>

                                        <Slider
                                            style={{width:'260px'}}
                                            defaultValue={50} max={200} min={10}
                                            onChange={(event, value) => {
                                                setConfig({
                                                    ...config,
                                                    scale: value / 100
                                                })
                                            }
                                            } aria-label="Default" valueLabelDisplay="auto"/>

                                        <div onClick={() => {
                                            setConfig({
                                                ...config,
                                                pageFitMode: !allShow ? PageFitMode.None : PageFitMode.FitToPage
                                            })
                                            setAllShow(!allShow);

                                        }

                                        }>
                                            {allShow ? '간략하게' : '펼쳐보기'}
                                        </div>
                                    </FlexRowSpace>
                                    <div className="placeholder" style={{width: '100%', height: '600px', border: '1px solid'}}>
                                        {
                                            refLoading ? (
                                                <OrgDiagram style={{whiteSpace: 'pre-wrap'}} centerOnCursor={true} config={config}/>
                                            ) : (
                                                <EmptyContent titleText={'추천인이 없습니다.'}
                                                              titleImage={require('../../assets/images/ref/empty.png')}/>
                                            )}
                                    </div>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
            </>
    );
}
