import PageTitle, {
    FlexColumn,
    FlexColumnCenter, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../../components/common/pageArea";
import {useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

import styles from "../../assets/styles/uni.module.scss";
import HomeLayer2 from "../../components/home/home_layer2";
import HomeLayer1 from "../../components/home/home_layer1";
import Footer from "../../components/layer/footer";
import React from "react";

export default function Welcome() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    return (
        <PageRootContainer>
            <PageTitle
                leftSrc={require('../../assets/images/home/logo.png')}
                leftSrcSize={113}
                onClickLeft={() => {
                    navigate("/")
                }}
            />
            <PageContent>
                <FlexColumn gap={20}>
                    <div className={styles.txt30_700}><Trans t={t} i18nKey={('👋<br/>K-DREAM에<br/>오신 것을 환영합니다.')}/></div>
                    <FlexColumnCenter className={styles.blue_in_btn}
                                      style={{borderRadius: '10px', width: '100%', fontSize: '15px', fontWeight: '700'}}
                                      onClick={() => {
                                          navigate('/login')
                                      }}>{t('지갑에 로그인을 해주세요.')}</FlexColumnCenter>
                    <FlexColumnCenter>
                        <img src={require('../../assets/images/home/welcole.png')} width={225}/>
                    </FlexColumnCenter>
                    <HomeLayer2 title={'\uD83E\uDD47 다양한 실물자산'}
                                contents={'카지노 정캣, 쇼핑, 엔터테이먼트 , 지적재산권등 다양한 실물자산을 K-DREAM에서 소유하세요.'}/>
                    <HomeLayer2 title={'\uD83D\uDE80 스마트 스테이킹'}
                                contents={'카지노 정캣 및 다양한 실물자산의 지분과 연결된 DRM토큰을 스테이킹하고 매달 자산운영에 대한 배당을 받으세요.'}/>
                </FlexColumn>
            </PageContent>
            <Footer/>
        </PageRootContainer>
    )
}