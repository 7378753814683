import PageTitle, {FlexBottom, FlexColumn, PageContent, PageRootContainer} from "../../components/common/pageArea";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import styles from "../../assets/styles/uni.module.scss";
import BottomButton from "../../components/common/button/BottomButton";
import {useAppDispatch} from "../../store/hooks";
import {useForm} from "react-hook-form";
import {useState} from "react";
import TermsPage from "../../components/login/termsPage";
import JoinForm from "../../components/login/joinForm";
import MailConfirm from "../../components/login/mailConfirm";
import {UserRegister} from "UserModels";
export interface IJoinDepth {
    setPageDepth:any;
    joinData?:any;
    setJoinData?:any;
}

export default function Join(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [pageDepth, setPageDepth] = useState(1);
    const [joinData, setJoinData] = useState<UserRegister>();

    return (
        <PageRootContainer>
            {
                pageDepth === 1 ? (
                    <PageTitle
                        leftSrc={require('../../assets/images/home/logo.png')}
                        leftSrcSize={113}
                        rightSrc={require('../../assets/images/home/close.png')}
                        onClickRight={()=>{navigate(-1)}}
                    />
                )
                :
                (
                    pageDepth === 3 ? (
                        <PageTitle
                            textTitle={t('이메일 인증')}
                            onClickLeft={()=>{setPageDepth(pageDepth -1)}}
                        />
                    )
                    :
                    (
                        <PageTitle
                            leftSrc={require('../../assets/images/home/logo.png')}
                            leftSrcSize={113}
                            rightSrc={require('../../assets/images/home/close.png')}
                            onClickRight={()=>{setPageDepth(pageDepth -1)}}
                        />
                    )
                )
            }
            { pageDepth === 1 && ( <TermsPage setPageDepth={setPageDepth}/>) }
            { pageDepth === 2 && ( <JoinForm setPageDepth={setPageDepth} setJoinData={setJoinData}/>) }
            { pageDepth === 3 && ( <MailConfirm setPageDepth={setPageDepth} joinData={joinData}/>) }

        </PageRootContainer>
    )
}