import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langUs from './Files/us.json';
import langKr from './Files/kr.json';
import langTh from './Files/th.json';

const resources = {
    us: { translations: langUs },
    kr: { translations: langKr },
    th: { translations: langTh },
}


i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || 'kr',
    fallbackLng: 'kr',
    debug: true,
    defaultNS: 'translations',
    ns: 'translations',
    keySeparator: false,
    interpolation: {
        escapeValue: false
    },
    react: {
        defaultTransParent: 'div',
        transEmptyNodeValue: '',
        transSupportBasicHtmlNodes: true,
        // <Trans> 컴포넌트 내부에 들어가는 html 태그들
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'button', 'a', 'span', 'div', 'input', 'b'],
        transWrapTextNodes: '',
    },
})

export default i18n;