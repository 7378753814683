import styles from "../../../assets/styles/uni.module.scss";
import { FlexBottom, FlexColumEnd,
    FlexColumn,
    FlexColumnCenter, FlexRowCenter, FlexRowEnd, FlexRowSpace,
    FlexRowStart,
    PageContent,
    PageRootContainer } from "../pageArea";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import mobileStyle from "../../../assets/styles/toast.scss";
export interface IPopAction {
    btnTxt?: string  | JSX.Element | null;
    onClick?: () => void | null | Promise<void>;
}

export const NetConfirmPopup = (title: string | JSX.Element,
                 message: string | JSX.Element,
                 subMessage: string | JSX.Element,
                 ok: IPopAction,
                 cancel: IPopAction)=>{
    confirmAlert({
        overlayClassName: mobileStyle.AlertWrapper,
        closeOnClickOutside: false,
        customUI: ({onClose}) => {
            let isTitleStr = false;
            let isStr = false;
            let isSubStr = false;
            if (typeof title === "string") {
                isTitleStr = true;
            }
            if (typeof message === "string") {
                isStr = true;
            }
            if (typeof subMessage === "string") {
                isSubStr = true;
            }
            return   (
                <FlexColumn gap={20} style={{backgroundColor: '#F8F8F8', color:'#49536E', width:'300px', borderRadius:'24px', padding:'10px 20px 22px 20px'}}>
                    <FlexRowEnd>
                        <img
                            onClick={() => {
                                if (cancel?.onClick) {
                                    cancel.onClick();
                                }else{

                                }
                                onClose();
                            }}
                            src={require('../../../assets/images/home/close_20.png')}
                            alt="close"
                            width={20}
                        />
                    </FlexRowEnd>
                    <FlexRowCenter className={styles.txt20_700}>
                        {isTitleStr ? (
                                <FlexRowCenter style={{textAlign:'center'}} className={styles.txt16_B02} dangerouslySetInnerHTML={{ __html: title.toString() }} />
                            )
                            :
                            (<FlexRowCenter style={{textAlign:'center'}} className={styles.txt16_B02}>{title}</FlexRowCenter>)
                        }
                    </FlexRowCenter>
                    <FlexColumn gap={4}>
                        {isStr ? (
                                <FlexRowCenter style={{textAlign:'center'}} className={styles.txt16_B02} dangerouslySetInnerHTML={{ __html: message.toString() }} />
                            )
                            :
                            (<FlexRowCenter style={{textAlign:'center'}} className={styles.txt16_B02}>{message}</FlexRowCenter>)
                        }
                        {isSubStr ? (
                                <FlexRowCenter style={{textAlign:'center'}} className={styles.txt14_B05} dangerouslySetInnerHTML={{ __html: subMessage.toString() }} />
                            )
                            :
                            (<FlexRowCenter style={{textAlign:'center'}} className={styles.txt14_B05}>{subMessage}</FlexRowCenter>)
                        }
                    </FlexColumn>
                    <FlexRowCenter gap={10}>
                        {
                            cancel?.onClick && cancel?.btnTxt && (
                                <FlexColumnCenter className={styles.white_in_box}
                                                  style={{padding:'15px 12px', fontWeight:'700'}}
                                                  onClick={() => {
                                                      onClose();
                                                      if (cancel?.onClick) {

                                                          cancel.onClick();
                                                      }else{

                                                      }
                                                  }}
                                >
                                    {cancel?.btnTxt ? cancel.btnTxt : "취소"}
                                </FlexColumnCenter>
                            )
                        }
                        <FlexColumnCenter className={styles.blue_in_box}
                                          style={{padding:'15px 12px', fontWeight:'700', color:'#FFFFFF'}}
                             onClick={() => {
                                 onClose();
                                 if (ok?.onClick) {

                                     ok.onClick();
                                 }else{

                                 }
                             }}
                        >{ok?.btnTxt ? ok.btnTxt : "확인"}</FlexColumnCenter>

                    </FlexRowCenter>

                </FlexColumn>
            );
        }
    });
}