import PageTitle, {
    Bar,
    FlexColumn, FlexColumnCenter, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart,
    PageContent,
    PageRootContainer
} from "../../components/common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import {useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import Footer from "../../components/layer/footer";
import refMiningComplete from "./refMiningComplete";
import React, {useEffect, useState} from "react";
import RefMiningComplete from "./refMiningComplete";

import bigDecimal from "js-big-decimal";
import {TnBoostRef} from "./refModule";
import {ellipseAddress} from "../../helpers/utilities";
import ProfileService from "../../services/profile.service";
import {NetConfirmPopup} from "../../components/common/popup/ConfirmPopup";
import {ToastError} from "../../components/common/popup/ToastAlert";
import profileService from "../../services/profile.service";
import RefPopup_old from "../ref/RefPopup_old";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../store/modules/profileSlice";

export interface IRefMining {
    show: boolean;
    setShow: any;
    inTxId:string;
}
interface IBoostRefResDto{
    vlm:string;
    prc:string;
    mineRwrdPercent:number;
}
interface selectRefPolicy{
    code:string;
    nftPrc:number;
    selectRefPolicy:number;
}
export default function RefMining(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [showPop, setShowPop] = useState(false);
    const [inTxId, setInTxId] = useState('');
    const [cnt, setCnt] = useState(0);
    const [pageView, setPageView] = useState('mining');
    const {refCnt, miningCnt } = useAppSelector(selectProfile);
    const [viewData, setViewData] = useState<IBoostRefResDto>({
        vlm:'',
        prc:'',
        mineRwrdPercent:0
    });
    const [viewNotice, setViewNotice] = useState<Array<selectRefPolicy>>([{
        code:'',
        nftPrc:0,
        selectRefPolicy:0
    }]);


    const onClickHandleHarvesting = ()=>{
        if(new bigDecimal(viewData.vlm).compareTo(new bigDecimal("100")) >=0){
            NetConfirmPopup(<FlexColumnCenter><img src={require('../../assets/images/product/product_x.png')} width={56} /></FlexColumnCenter>
                ,<FlexColumnCenter className={styles.txt20_700}><Trans t={t} i18nKey={"100개 이상부터<br/>수확이 가능 합니다."} /></FlexColumnCenter>
                , ""
                ,{btnTxt:t("닫기"),
                    onClick: () => {

                    }}
                , {btnTxt:"",
                    onClick: () => {
                    }}
            );
        }else{
            NetConfirmPopup(t("수확하기")
                ,<FlexColumnCenter className={styles.txt20_700}><Trans t={t} i18nKey={"정말 수확하시겠습니까?"} /></FlexColumnCenter>
                , ""
                ,{btnTxt:t("확인"),
                    onClick: () => {
                        ProfileService.rcvBoostRef().then(res=>{
                            if(res.data){
                                if(res.data.successYn === "Y"){
                                    setInTxId(res.data.inTxid);
                                    setShow(true);
                                }else{
                                    ToastError(t(res.data.message))
                                }
                            }

                        });
                    }}
                , {btnTxt:t("취소"),
                    onClick: () => {
                    }}
            );
        }
    }

    useEffect(()=>{   
        ProfileService.getBoostRef().then(res=> {
            setViewData(res.data);
        });
        ProfileService.selectRefPolicy().then(res=>{
            setViewNotice(res.data)
        })
    },[])
    return (
        <PageRootContainer>
            <RefPopup_old setShow={setShowPop} show={showPop} pageView={pageView}/>
            <RefMiningComplete show={show} setShow={setShow} inTxId={inTxId}/>
            <PageTitle
                textTitle={t('추천인 채굴보상')}
                onClickLeft={()=>{navigate("/")}}
            />
            <PageContent style={{height:'80vh'}}>
                <FlexColumn gap={32}>
                    <FlexColumn gap={10}>
                        <FlexRowStart gap={12} className={styles.txt14_500}>
                            {t('추천인 보상 채굴량')} <div className={styles.yellow_in_box}>{viewData?.mineRwrdPercent}%</div> <img src={require('../../assets/images/mining/reader-outline.png')} width={24} onClick={()=>{setShowPop(true)}}/>
                        </FlexRowStart>
                        <FlexRowSpace>
                            <FlexRowStart>
                                <FlexColumn gap={10}>
                                    <FlexRowStart gap={5}>
                                    <img src={require('../../assets/images/symbol/BOOST.png')} width={30} />
                                    <div className={styles.txt20_500}>{viewData?.vlm}</div>
                                    </FlexRowStart>
                                    <div className={styles.txt14_500_828282}>≈ ${viewData?.prc}</div>
                                </FlexColumn>
                            </FlexRowStart>
                            <FlexRowEnd>
                                <div className={styles.black_in_box} style={{borderRadius:'8px', padding:'18px 32px'}} onClick={()=>{onClickHandleHarvesting()}}>{t('받기')}</div>
                            </FlexRowEnd>
                        </FlexRowSpace>
                    </FlexColumn>
                    <Bar />
                    <FlexColumn gap={4} className={styles.txt16_500_828282}>
                        <div style={{color:'#EB4E46'}}>{t('현재 채굴중인 친구가 {0}명이 있네요.', {0:cnt})}</div>
                        <div><Trans t={t} i18nKey={("지금 NFT를 구매하면,<br/>친구 <span style={{color:'#374151'}}>채굴량의 최대 10%</span>를<br/>추천인보상으로 받을 수 있어요.")}/></div>
                    </FlexColumn>
                    <FlexColumnCenter className={styles.blue_in_btn} style={{borderRadius:'10px', width:'100%', fontSize:'15px', fontWeight:'700'}} onClick={()=>{navigate("/main")}}>{t('NFT 구매하고 보상받기')}</FlexColumnCenter>
                    <Bar/>
                    <FlexColumn className={styles.white_in_btn}>
                        <FlexRowStart gap={5}><img src={require('../../assets/images/home/mark_blue.png')} width={28} /> {t("추천인 보상내용안내")}</FlexRowStart>
                        <div className={styles.txt12_828282}>
                            <Trans t={t} i18nKey={("NFT가 한개라도 있으면 1%보상을 받을 수 있고,<br/>보유한 NFT의 총 가격에 따라 더 많은 보상을 받을 수 있어요.<br/><br/>보유한 NFT 가격의 총 합이,<br/><br/>$100 이상이면 채굴량의 2%보상<br/>$300 이상이면 채굴량의 4%보상<br/>$500 이상이면 채굴량의 6%보상<br/>$1,000 이상이면 채굴량의 10%보상<br/>(가격은 NFT발행가 기준 입니다.)")}/>
                        </div>
                    </FlexColumn>
                </FlexColumn>
            </PageContent>
            <Footer/>
        </PageRootContainer>
    )
}