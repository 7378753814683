import PageTitle, {
    Bar,
    FlexBottom, FlexColumEnd,
    FlexColumn,
    FlexColumnCenter, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../../components/common/pageArea";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import styles from "../../assets/styles/uni.module.scss";
import Footer from "../../components/layer/footer";
import BoostList from "../../components/boost/boostList";

export default function Boost(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    return (
        <PageRootContainer>
            <BoostList show={show} setShow={setShow}/>
            <PageTitle
                textTitle ={t("K-DREAM 수익현황")}
                onClickLeft={()=>{
                    navigate(-1);
                }}
            />
            <PageContent>
                <FlexColumn gap={30}>
                    <FlexColumnCenter className={styles.gray_in_btn} style={{borderRadius:'12px', backgroundColor:'#E8E8E8', color:'#EB4E46'}}>
                        <div className={styles.txt14_300}>{t("DRM 평가자산 규모")}</div>
                        <div className={styles.txt24_700}>$ 0</div>
                    </FlexColumnCenter>
                    <Bar/>
                    <FlexColumn gap={10}>
                        <div className={styles.txt24_700}>
                            <Trans t={t}  i18nKey={"DRM 자금은<br/>안전하게 보관됩니다."}/>
                        </div>
                        <div className={styles.txt16_400}>
                            {t("DRM 자금은 보안이 뛰어난 독립 보관업체와 협력하여 소중한 DRM 자금을 안전하게 보호합니다.우리의 파트너인 BitGo와 Fireblocks는 우리에게 기관급 보안을 제공하게 해주며, 사용의 용이성이나 자금 접근에 어려움이 없도록 합니다.")}
                        </div>
                    </FlexColumn>
                    <Bar/>
                    <FlexColumn className={styles.txt18_500}>
                        <Trans t={t} i18nKey={"DRM 자금의 흐름은 BitGo와<br/> Fireblocks를 통해 투명하게 공개 됩니다."}/>
                    </FlexColumn>
                    <FlexColumn gap={20}>
                        <img src={require('../../assets/images/setting/bitgo_logo.png')} width={178}/>
                        <FlexColumn gap={10}>
                            <div className={styles.txt18_700}>{t("보관중인 DRM자금")}</div>
                            <FlexRowSpace>
                                <FlexRowStart gap={10} className={styles.txt16_700}><img src={require('../../assets/images/symbol/BTC.png')} width={30}/><div>BTC :</div> </FlexRowStart>
                                <FlexRowEnd className={styles.txt16_400}>0</FlexRowEnd>
                            </FlexRowSpace>
                            <FlexRowSpace>
                                <FlexRowStart gap={10} className={styles.txt16_700}><img src={require('../../assets/images/symbol/ETH.png')} width={30}/><div>ETH :</div> </FlexRowStart>
                                <FlexRowEnd className={styles.txt16_400}>0</FlexRowEnd>
                            </FlexRowSpace>
                            <FlexRowSpace>
                                <FlexRowStart gap={10} className={styles.txt16_700}><img src={require('../../assets/images/symbol/BNB.png')} width={30}/><div>BNB :</div> </FlexRowStart>
                                <FlexRowEnd className={styles.txt16_400}>0</FlexRowEnd>
                            </FlexRowSpace>
                            <FlexRowSpace>
                                <FlexRowStart gap={10} className={styles.txt16_700}><img src={require('../../assets/images/symbol/USDT.png')} width={30}/><div>USDT :</div> </FlexRowStart>
                                <FlexRowEnd className={styles.txt16_400}>0</FlexRowEnd>
                            </FlexRowSpace>
                            <FlexRowSpace>
                                <FlexRowStart gap={10} className={styles.txt16_700}><img src={require('../../assets/images/symbol/BUSDT.png')} width={30}/><div>BUSD :</div> </FlexRowStart>
                                <FlexRowEnd className={styles.txt16_400}>0</FlexRowEnd>
                            </FlexRowSpace>
                            <FlexRowEnd className={styles.txt14_828282}><span style={{textDecorationLine:'underline'}} onClick={()=>{window.open('https://bitgo.com')}}>bitGo.com</span> {t("알아보기")}</FlexRowEnd>
                        </FlexColumn>
                        <Bar/>
                        <img src={require('../../assets/images/setting/fireblocks_logo.png')} width={178}/>
                        <FlexColumn gap={10}>
                            <div className={styles.txt18_700}>{t("보관중인 DRM자금")}</div>
                            <FlexRowSpace>
                                <FlexRowStart gap={10} className={styles.txt16_700}><img src={require('../../assets/images/symbol/BTC.png')} width={30}/><div>BTC :</div> </FlexRowStart>
                                <FlexRowEnd className={styles.txt16_400}>0</FlexRowEnd>
                            </FlexRowSpace>
                            <FlexRowSpace>
                                <FlexRowStart gap={10} className={styles.txt16_700}><img src={require('../../assets/images/symbol/ETH.png')} width={30}/><div>ETH :</div> </FlexRowStart>
                                <FlexRowEnd className={styles.txt16_400}>0</FlexRowEnd>
                            </FlexRowSpace>
                            <FlexRowSpace>
                                <FlexRowStart gap={10} className={styles.txt16_700}><img src={require('../../assets/images/symbol/BNB.png')} width={30}/><div>BNB :</div> </FlexRowStart>
                                <FlexRowEnd className={styles.txt16_400}>0</FlexRowEnd>
                            </FlexRowSpace>
                            <FlexRowSpace>
                                <FlexRowStart gap={10} className={styles.txt16_700}><img src={require('../../assets/images/symbol/USDT.png')} width={30}/><div>USDT :</div> </FlexRowStart>
                                <FlexRowEnd className={styles.txt16_400}>0</FlexRowEnd>
                            </FlexRowSpace>
                            <FlexRowSpace>
                                <FlexRowStart gap={10} className={styles.txt16_700}><img src={require('../../assets/images/symbol/BUSDT.png')} width={30}/><div>BUSD :</div> </FlexRowStart>
                                <FlexRowEnd className={styles.txt16_400}>0</FlexRowEnd>
                            </FlexRowSpace>
                            <FlexRowEnd className={styles.txt14_828282}><span style={{textDecorationLine:'underline'}} onClick={()=>{window.open('https://fireblocks.com')}}>fireblocks.com</span> {t("알아보기")}</FlexRowEnd>
                        </FlexColumn>
                        <Bar/>

                        <FlexRowCenter onClick={()=>{setShow(true)}}>
                            <img src={require('../../assets/images/home/main_icon.png')} width={115}/>
                            <FlexColumn gap={20}>
                                <div className={styles.txt18_500}>{t("DRM 자금 사용내역은 블록체인을 통해 투명하게 공개 됩니다.")}</div>
                                <FlexRowEnd><img src={require('../../assets/images/mining/reader-outline.png')} width={24} /><div className={styles.txt18_500} style={{textDecorationLine:'underline'}}>{t("DRM 자금 내역")}</div></FlexRowEnd>
                            </FlexColumn>
                        </FlexRowCenter>
                        <Bar/>
                    </FlexColumn>
                </FlexColumn>
            </PageContent>
            <Footer/>
        </PageRootContainer>
    )
}