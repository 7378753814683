import styles from "../../assets/styles/uni.module.scss";
import {FlexColumn} from "../common/pageArea";
import {Trans, useTranslation} from "react-i18next";


export default function HomeLayer2(props:{title:string, contents:string}){
    const { t } = useTranslation();
    return (
        <FlexColumn className={styles.layer_box} style={{ background:'#FFFFFF', fontSize:'24px', fontWeight:'700'}} gap={12}>
            <div className={styles.txt18_500}><Trans t={t} i18nKey={props.title} /></div>
            <div className={styles.txt16_400} style={{color:'#6B7280',lineHeight:'28px'}}><Trans t={t} i18nKey={props.contents} /></div>
        </FlexColumn>
    )
}

