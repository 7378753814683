import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Toggle from 'react-toggle'
import "../../assets/styles/togle.scss"
import PageTitle, {
    Bar,
    FlexColumn,
    FlexColumnCenter, FlexRowAround, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../../components/common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import MainTop from "../../components/layer/mainTop";
import React, {useEffect, useState} from "react";
import Stakinglist from "../../components/staking/stakinglist";
import StakingService from "../../services/staking.service";
import {WalletHis} from "../coin/coinModule";
import {useInView} from "react-intersection-observer";
import WalletService from "../../services/wallet.service";
import EmptyContent from "../../components/common/EmptyContent";
import SettingPopup from "../setting/settingPopup";
import StakingPopup from "./stakingPopup";
import StakingEndPopup from "./stakingEndPopup";
import StakingNoBnsPopup from "./stakingNoBnsPopup";

export interface IStakingList{
    stkVlm: string;
    dDay: string;
    autoYn: string;
    stkIdx:string;
    startDtm:string;
    endDtm:string;
    stusCd:string;
    bnsYn:string;
    mtchBnsVlm:string;
    intrstVlm:string;
    intrstYn:string;
    wthdrwInTxid:string;
    dpstInTxid:string;
    regDtm:string;
    regDtm:string;
    regDtmTime:number;


}


export default function StakingPage() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [dataList, setDataList]= useState<Array<IStakingList>>([]);
    const [currPage, setCurrPage] = useState(1);
    const [blockSize] = useState(10);
    const [searchTxt, setSearchTxt] = useState('');
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [showPop, setShowPop] = useState(false);
    const [showEndPop, setShowEndPop] = useState(false);
    const [showRefPop, setShowRefPop] = useState(false);
    const [popupData, setPopupData] = useState<IStakingList>()

    const [searchParams, setSearchParams] = useSearchParams();

    const onClickViewItem = (selectItem: any) =>{
        console.log(selectItem);
        setPopupData(selectItem);
        setShowPop(true);
    }

    useEffect(() => {
        const fetchData = async() =>{
            const response = await StakingService.getSelectStaking(currPage, blockSize, true);
            setDataList([...dataList, ...response.data]);
            if (!response.data.length) {
                setLastList(true);
                return;
            }
        }
        if (!lastList) {
            fetchData();
        }
    },[currPage, searchTxt]);
    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    useEffect(()=>{
        if(searchParams.get("open")==='Y'){
            searchParams.delete("open");
            setShowRefPop(true);
        }
    },[])

    return (
        <>
            <MainTop />
            <StakingNoBnsPopup show={showRefPop} setShow={setShowRefPop}/>
            <StakingEndPopup show={showEndPop} setShow={setShowEndPop}/>
            <StakingPopup show={showPop} setShow={setShowPop} popupData={popupData}/>
            <PageRootContainer style={{paddingTop:'85px', minHeight:'80vh'}}>
                <FlexColumn>
                    <img src={require('../../assets/images/staking/staking_banner.png')} height={'196px'} width={'100%'} style={{borderRadius: '16px 16px 0px 0px'}}/>
                    <FlexColumn
                        style={{borderRadius: '16px', background: '#FFFFFF', boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.08)'}}
                        gap={20}>
                        <FlexColumnCenter className={styles.txt16_700} style={{
                            borderRadius: '0px 0px 10px 10px',
                            padding: '13px 20px',
                            color: '#FFFFFF',
                            background: '#0F8CFF',
                            lineHeight:'25px'
                        }} onClick={() => {
                            navigate("/staking/00001");
                        }}>
                            <div>스마트 스테이킹 참여하기</div>
                            {/*<div className={styles.txt20_700}>2023.02.28 09시 오픈 예정</div>*/}
                        </FlexColumnCenter>
                        <FlexColumn style={{padding: '0px 20px 20px 20px'}} gap={24}>
                            <FlexColumn gap={4}>
                                <div className={styles.txt16_500}>매칭 보너스 지급안내</div>
                                <div className={styles.txt16_500_828282} style={{lineHeight:'30px'}}>
                                    스테이킹 참여시 지급되는 매칭 보너스는<br/>
                                    스테이킹 참여 후 <span className={styles.txt16_700} style={{textDecorationLine:'underline'}}>48시간 이후에 자동 지급</span> 됩니다.
                                </div>
                            </FlexColumn>
                        </FlexColumn>
                    </FlexColumn>

                    <FlexRowAround className={styles.txt14_500} style={{marginTop:'15px'}}>
                        <FlexRowAround className={styles.yellow_in_btn} style={{borderRadius:'10px', width:'100%'}} onClick={()=>setShowRefPop(true)}>
                            하부 추천인 스테이킹 현황
                        </FlexRowAround>
                    </FlexRowAround>

                    <FlexRowEnd className={styles.txt15_500} style={{marginTop:"20px",textDecorationLine:"underline"}} onClick={()=>{setShowEndPop(true)}}>
                        스테이킹 내역보기
                    </FlexRowEnd>

                    <FlexColumn style={{marginTop:"10px"}} gap={20}>
                        {
                            dataList.length > 0 ? (
                                dataList.map((item, index) => {
                                    return (
                                        <Stakinglist key={item.stkIdx} stkData={item} onClickViewItem={onClickViewItem}/>
                                    )
                                })
                            ) : (
                                <EmptyContent titleText={t("내역이 없습니다.")} titleImage={require('../../assets/images/list_x.png')}/>
                            )
                        }
                        {
                            Object.values(dataList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                        }


                    </FlexColumn>
                </FlexColumn>
            </PageRootContainer>
        </>
    )
}