import http from './http';
import TokenService from './token.service';
import {IWalletSetup} from "../components/coin/walletPopup";
///////////////// ajeitar /////////
class AuthService {
  async login(usrId: string, usrPw: string) {
    const params = new FormData();
    params.set("usrId", usrId);
    params.set("usrPw", usrPw);
    return http
      .post('/anony/login', params)
      .then((response) => {
          TokenService.setUser(response.data.data);

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
    window.location.href="/";
  }
  async certNum(usrId:string, usrPw:string, usrNm:string, upRefCd:string) {
      return http
          .post('/anony/certNum', {
              usrId,
              usrPw,
              usrNm,
              upRefCd
          })
          .then((response) => {
              return response.data;
          });
  }
  async register(usrId:string, usrPw:string, usrNm:string, upRefCd:string, certNum:string) {
    return http
      .post('/anony/join', {
          usrId,
          usrPw,
          usrNm,
          upRefCd,
          certNum
      })
      .then((response) => {
        return response.data;
      });
  }
  async checkRefCd(usrRefCode:string ){
      return http
      .get('/anony/checkRefCd/?refCd='+usrRefCode)
      .then((response) => {
          return response.data;
      });
  }
  async agreeTerms(usrId: string, usrPw: string) {
      const params = new FormData();
      params.set("usrId", usrId);
      params.set("usrPw", usrPw);
      return http
          .post('/anony/agreeTerms', params)
          .then((response) => {
              console.log("response.data.token",response.data)
              TokenService.setUser(response.data.data);
              return response.data;
          });
  }
  async termsView(){
      return http
          .get('/anony/terms', )
          .then((response) => {
              return response.data;
          });
  }
  async changeNickName(usrNm:string){
      return http
      .post('/user/updateUsrNm', {
          usrNm
      })
      .then((response) => {
          return response.data;
      });
  }
    async changePass(curUsrPw:string, newUsrPw:string, newUsrPw1:string){
        return http
            .post('/user/updateUsrPw', {
                curUsrPw,
                newUsrPw,
                newUsrPw1
            })
            .then((response) => {
                return response.data;
            });
    }
    async changeEmail(email:string){
        return http
            .post('/user/updateEmail', {
                email
            })
            .then((response) => {
                return response.data;
            });
    }
    async getUserInfo(){
        return http
            .get('/user/getUserInfo', )
            .then((response) => {
                TokenService.setUser(response.data.data);
                return response.data;
            });
    }
    async findPw(usrId:string){
        return http
            .post('/anony/findPw', {
                usrId
            })
            .then((response) => {
                return response.data;
            });
    }






}

export default new AuthService();
