import React, {useEffect, useState} from "react";

import PageTitle, {
    FlexColumn,
    FullScreen,
    PageContent,
    PageRootContainer,
    PopupBodyWrapper
} from "../../components/common/pageArea";
import {useTranslation} from "react-i18next";
import styles from "../../assets/styles/uni.module.scss";
import {useInView} from "react-intersection-observer";
import StakingService from "../../services/staking.service";
import {IStakingList} from "./stakingPage";
import EmptyContent from "../../components/common/EmptyContent";
import StakingEndList from "../../components/staking/stakingEndlist";

export interface ISettingPopup {
    show: boolean;
    setShow: any;
}
export default function StakingEndPopup(props:ISettingPopup){
    const {t} = useTranslation();
    const [dataList, setDataList]= useState<Array<IStakingList>>([]);
    const [currPage, setCurrPage] = useState(1);
    const [blockSize] = useState(10);
    const [searchTxt, setSearchTxt] = useState('');
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [showPop, setShowPop] = useState(false);
    const [popupData, setPopupData] = useState<IStakingList>()
    const onClickViewItem = (selectItem: any) =>{
        console.log(selectItem);
        setPopupData(selectItem);
        setShowPop(true);
    }

    useEffect(() => {
        const fetchData = async() =>{
            const response = await StakingService.getSelectStakingEnd(currPage, blockSize, true);
            if (!response.data) {
                return;
            }
            setDataList([...dataList, ...response.data]);
            if (!response.data?.length) {
                setLastList(true);
                return;
            }
        }
        if (!lastList && props.show) {
            fetchData();
        }
    },[currPage, searchTxt, props.show]);
    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <>
            {
                props.show && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle textTitle={'스테이킹 종료 내역'} rightSrc={require('../../assets/images/home/close.png')} onClickRight={()=>{props.setShow(false)}}/>
                                <PageContent>
                                    <FlexColumn gap={30}>
                                        <div className={styles.txt20_400}>스테이킹 내역</div>
                                        <FlexColumn style={{marginTop:"10px"}} gap={20}>
                                            {
                                                dataList.length > 0 ? (
                                                    dataList.map((item, index) => {
                                                        return (
                                                            <StakingEndList key={item.stkIdx} stkData={item} onClickViewItem={onClickViewItem}/>
                                                        )
                                                    })
                                                ) : (
                                                    <EmptyContent titleText={t("내역이 없습니다.")} titleImage={require('../../assets/images/list_x.png')}/>
                                                )
                                            }
                                            {
                                                Object.values(dataList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                                            }
                                        </FlexColumn>
                                    </FlexColumn>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>

    )
}