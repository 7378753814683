import {FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {decimalFormat} from "../../helpers/utilities";
import {useAppSelector} from "../../store/hooks";
import {selectAuth} from "../../store/modules/authSlice";
import WalletService from "../../services/wallet.service";
import {ITcCrncy} from "../../pages/coin/coinModule";
import Footer from "../layer/footer";


export default function CoinList(props:{pageMode:number}){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {isLoggedIn, user, asyncError}  = useAppSelector(selectAuth);
    const [coinList, setCoinList] = useState<Array<ITcCrncy>>([]);
    useEffect(()=>{
        if(props.pageMode ===1){
            WalletService.selectCoin().then(async res=>{
                const resultList = await Promise.all(res.data.map(async(item:any, index:number)=>{
                    console.info('item', item);
                    // const result = await getOnchainBalance(item.mainnetCd, user.walletAddr, item.decimalLen, item.cntrctAddr);
                    // console.log(item.crncyCd+ "result", result);
                    // item.balance = result;
                    return item;
                }));
                setCoinList(resultList);
            });
        }

    },[props.pageMode])
    return (
        <FlexColumn gap={24}>
            <FlexRowSpace className={styles.txt12_8D94A0}>
                <div style={{width:'60%'}}>{t('코인')}</div>
                <div style={{width:'20%'}}>{t('보유수량')}</div>
                <FlexRowEnd style={{width:'20%'}}>{t('현재가')}</FlexRowEnd>
            </FlexRowSpace>
            <FlexColumn gap={24}>
                {
                    coinList.map((item, index) =>{
                        return(
                            <FlexRowSpace key={index} className={styles.txt14_500} onClick={()=>{navigate('/coin/'+item.crncyCd)}}>
                                <FlexRowStart gap={10} style={{width:'60%'}}>
                                    <img src={item.imgPath} width={40}/>
                                    <FlexColumn gap={2}>
                                        <div className={styles.txt18_700}>{item.crncyCd}</div>
                                        <div className={styles.txt14_828282}>{item.mainnetDisplayNm}</div>
                                    </FlexColumn>
                                </FlexRowStart>
                                <FlexColumn style={{width:'20%'}}>
                                    <FlexRowEnd>
                                        {decimalFormat(item.usrBalance, 3)}<br/>
                                    </FlexRowEnd>
                                    <FlexRowEnd className={styles.txt14_500_828282}>
                                        (${decimalFormat( item.usrBalancePrice , 2)})
                                    </FlexRowEnd>
                                </FlexColumn>
                                <FlexRowEnd style={{width:'20%'}}>${decimalFormat(item.dealPrc, 3)}</FlexRowEnd>
                            </FlexRowSpace>
                        )
                    })
                }
            </FlexColumn>
            <Footer/>
        </FlexColumn>
    )
}