import DefaultInput from "../common/input/DefaultInput";
import styles from "../../assets/styles/uni.module.scss";
import React from "react";
import {useForm} from "react-hook-form";
import PageTitle, {
    FlexBottom,
    FlexColumEnd,
    FlexColumn, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen, PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../../components/common/pageArea";
import BottomButton from "../common/button/BottomButton";
import {useAppDispatch} from "../../store/hooks";
import {changeEmailAsync, changePassAsync} from "../../store/modules/authSlice";
import {Toast} from "../common/popup/ToastAlert";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function EmailItem(props:{setShow:any;}){
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        register,
        formState: { errors, isDirty, isValid },
        setError,
        clearErrors,
        handleSubmit
    } = useForm();
    const onSubmit = (data: any) => {
        console.log(data);
        const {email} = data;

        dispatch(changeEmailAsync( {email}))
            .unwrap()
            .then(() => {
                Toast(t('변경 되었습니다.'));
                props.setShow(false);

            })
            .catch(() => {
            });

    }
    return (
        <>
            <PageContent style={{paddingTop:'83px'}}>
                <DefaultInput
                    lable={t("변경할 이메일")}
                    error={errors.email}
                    inputElement={
                        <input
                            type={"text"}
                            className={styles.inputText_44}
                            placeholder={t("변경할 이메일을 입력해주세요.")}
                            {...register("email", {
                                required: t("변경할 이메일을 입력해주세요."),
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                                    message: t("이메일 형식이 올바르지 않습니다."),
                                },
                            })}
                        />
                    }
                />
            </PageContent>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#FFFFFF"}
                        textColor={"black"}
                        // border={"solid 1px #dbdee6"}
                        onClick={()=>{props.setShow(false);}}
                        text={t("취소하기")}
                    />
                </FlexColumn>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#FFDD00"}
                        textColor={"black"}
                        // border={"solid 1px #dbdee6"}
                        onClick={handleSubmit(onSubmit)}
                        text={t("변경하기")}
                    />
                </FlexColumn>
            </FlexBottom>
        </>
    )
}