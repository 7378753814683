import styled from "styled-components";
import {decimalFormat} from "../../helpers/utilities";
import {useEffect, useState} from "react";
const Slider = styled.input`
  position: absolute;
  top: calc(50% - 6px);
  width: 100%;
  height: 12px;
  -webkit-appearance: none;
  background: transparent;
  margin: 0;
`;
const SliderContainer = styled.div`
  width: 100%;
  height: 24px;
  position: relative;
`;

const SliderRail = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: gray;
  position: absolute;
  top: calc(50% - 2px);
`;

const SliderFillTrack = styled.div`
  height: 4px;
  border-radius: 2px;
  background-color: blue;
  position: absolute;
  top: calc(50% - 2px);
`;
interface ISliderFillTrack {
    fill : string;
}
interface ISliderComponent {
    value :number;
    min : number;
    max : number;
    step: number;
    onChange?: (item: number)=> void;
}
const SliderComponent = (props:ISliderComponent) => {
    const { value, min, max, step, onChange} = props;

    return (
        <SliderContainer>
            <SliderRail />
            <SliderFillTrack id={'track'} style={{width: `${(value / (max - min)) * 100}%`}} />
            <Slider
                type={'range'}
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={e => {
                    const newValue = parseInt(e.target.value);
                    const track = document.getElementById('track');
                    if(track) {
                        track.style.width = `${(newValue / (max - min)) * 100}%`;
                    }
                    if(onChange) {
                        onChange(newValue);
                    }
                }}
            />
        </SliderContainer>
    );
};

export default SliderComponent;