import moment from "moment";
import React, {useEffect, useState} from "react";

import PageTitle, {
    FlexBottom,
    FlexColumEnd,
    FlexColumn, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen, PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../../components/common/pageArea";
import BottomButton from "../../components/common/button/BottomButton";
import NickItem from "../../components/setting/nickItem";
import PassItem from "../../components/setting/passItem";
import EmailItem from "../../components/setting/emailItem";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import SecretKey from "../../components/setting/secretKey";
import {inspect} from "util";
import styles from "../../assets/styles/uni.module.scss";

export interface ISettingPopup {
    show: boolean;
    setShow: any;
    popupData:any;
}
export default function StakingPopup(props:ISettingPopup){
    const {t} = useTranslation();
    const [balance, setBalance] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [leftDate, setLeftDate] = useState(0);

    useEffect(()=>{
        if(props.popupData){
            console.log(moment(props.popupData.startDtm));
            setBalance( props.popupData.stkVlm);
            setStartDate(moment(props.popupData.startDtm).format("YYYY-MM-DD HH:mm:ss"));
            setEndDate(moment(props.popupData.endDtm).format("YYYY-MM-DD HH:mm:ss"));
            setLeftDate(moment(props.popupData.endDtm).diff(moment(props.popupData.startDtm) ,"days"))
        }

    },[props.popupData])
    return (
        <>
            {
                props.show && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle textTitle={'스마트 스테이킹'} rightSrc={require('../../assets/images/home/close.png')}
                                           onClickRight={()=>{props.setShow(false)}}/>
                                <PageContent>
                                    <FlexColumn className={styles.white_in_btn} style={{borderRadius:'10px', border:'0.5px solid #DADADA', padding:'20px 16px'}} gap={20}>
                                        <FlexRowStart gap={5} className={styles.txt16_500}>
                                            <img src={require('../../assets/images/product/defaultIcon.png')} width={14} />{t('스테이킹 정보')}
                                        </FlexRowStart>
                                        <div className={styles.gray_line} style={{width:'calc(100% + 32px)', marginLeft:'-16px'}} />
                                        <FlexRowSpace>
                                            <FlexRowStart>스테이킹 수량</FlexRowStart>
                                            <FlexRowEnd>{balance} DRM</FlexRowEnd>
                                        </FlexRowSpace>
                                        <FlexRowSpace>
                                            <FlexRowStart>남은 스테이킹 시간</FlexRowStart>
                                            <FlexRowEnd>D-{leftDate}</FlexRowEnd>
                                        </FlexRowSpace>
                                        <FlexRowSpace>
                                            <FlexRowStart>스테이킹 시작일시</FlexRowStart>
                                            <FlexRowEnd>{startDate}</FlexRowEnd>
                                        </FlexRowSpace>
                                        <FlexRowSpace>
                                            <FlexRowStart>스테이킹 종료일시</FlexRowStart>
                                            <FlexRowEnd>{endDate}</FlexRowEnd>
                                        </FlexRowSpace>

                                        {/*<FlexColumn className={styles.white_in_btn} style={{borderRadius:'12px'}} gap={10}>*/}
                                        {/*    <div className={styles.txt16_700}>*/}
                                        {/*        주의사항*/}
                                        {/*    </div>*/}
                                        {/*    <div className={styles.txt13_696969}> 블라블라블라블라블라블라블라블라블라블라블라블라블*/}
                                        {/*        블라블라블라블라블라블라블라블라블라블라블라블라*/}
                                        {/*        블라블라블라블라블라블라블라블라블라블라블라블라블라블라*/}
                                        {/*        블라블라블라블라블라블라블라블라블라블라블라블라블라블라블라블라</div>*/}
                                        {/*</FlexColumn>*/}
                                    </FlexColumn>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>

    )
}