import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import authService from '../../services/auth.service';
import tokenService from '../../services/token.service';
import axios, { AxiosError } from 'axios';
import {
    AuthState,
    User,
    UserCredentials,
    UserRegister,
    UserRefCode,
    AgreeTermsParam,
    ChangeNick, ChangePass, ChangeEmail, FindPw, UserCert
} from 'UserModels';

const user: User = tokenService.getUser();



const initialState: AuthState = user.usrId
  ? {
      isLoading: false,
      isLoggedIn: true,
      user: user,
      asyncError: '',
      checkRef : false,
      refCode: '',
    }
  : {
      isLoading: false,
      isLoggedIn: false,
      user: {
          ...user,
          keypairVo: {
              accessToken:''
          }
      },
      asyncError: '',
      checkRef : false,
      refCode: '',
    };

export const certNumAsync = createAsyncThunk<AuthState, UserCert>(
    'auth/certNum',
    async (userCert: UserCert, thunkApi) => {
        if (userCert.usrPw !== userCert.usrPwConf) {
            thunkApi.dispatch(setAsyncError(`Your password doesn't match`));
            return thunkApi.rejectWithValue(`Your password doesn't match`);
        }
        try {

            const response = await authService.certNum(
                userCert.usrId,
                userCert.usrPw,
                userCert.usrNm,
                userCert.upRefCd
            );
            if (response.resultCode === "SUCCESS") {
                return response.data;
            }else{
                return thunkApi.rejectWithValue(response?.message);
            }
        } catch (_error) {
            const error = _error as Error | AxiosError;
            console.log(error);
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setAsyncError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setAsyncError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);
export const registerAsync = createAsyncThunk<AuthState, UserRegister>(
  'auth/register',
  async (userRegister: UserRegister, thunkApi) => {
    if (userRegister.usrPw !== userRegister.usrPwConf) {
      thunkApi.dispatch(setAsyncError(`Your password doesn't match`));
      return thunkApi.rejectWithValue(`Your password doesn't match`);
    }
    try {

      const response = await authService.register(
        userRegister.usrId,
        userRegister.usrPw,
        userRegister.usrNm,
        userRegister.upRefCd,
        userRegister.certNum
      );
      console.log("response==================",response);
        if (response.resultCode === "SUCCESS") {
            return response.data;
        }else{
            return thunkApi.rejectWithValue(response?.message);
        }
    } catch (_error) {
      const error = _error as Error | AxiosError;
      console.log(error);
      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(setAsyncError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      }
      thunkApi.dispatch(setAsyncError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);


export const loginAsync = createAsyncThunk<AuthState['user'], UserCredentials>(
  'auth/login',
  async (userCredentials: UserCredentials, thunkApi) => {
    console.log(userCredentials);


    try {
      const response = await authService.login(
        userCredentials.usrId,
        userCredentials.usrPw
      );
        console.log("response======================",response);
      if (response) {
        return response.data;
      }else{

          return thunkApi.rejectWithValue(response.data?.message);
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;
      console.log("error=====", error);
      if (axios.isAxiosError(error)) {
          if(error.response){
              thunkApi.dispatch(setAsyncError(error.response.data.message));
          }
        return thunkApi.rejectWithValue(error?.message);
      }
      thunkApi.dispatch(setAsyncError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const agreeTermsAsync = createAsyncThunk<AuthState['user'], AgreeTermsParam>(
    'auth/agreeTermsAsync',
    async (agreeTermsParam: AgreeTermsParam, thunkApi) => {
        console.log("string",agreeTermsParam.usrId);
        try {
            const response = await authService.agreeTerms(
                agreeTermsParam.usrId,
                agreeTermsParam.usrPw,
            );
            if (response) {

                return response.data;
            }
        } catch (_error) {
            const error = _error as Error | AxiosError;
            console.log("error=====", error);
            if (axios.isAxiosError(error)) {
                if(error.response){
                    thunkApi.dispatch(setAsyncError(error.response.data.message));
                }
                return thunkApi.rejectWithValue(error?.message);
            }
            thunkApi.dispatch(setAsyncError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);
export const checkRefCd = createAsyncThunk<AuthState, UserRefCode>(
    'auth/checkRefCd',
    async (userRefCode: UserRefCode, thunkApi) => {
        console.log("string",userRefCode.refCd);

        thunkApi.dispatch(setRefCode(userRefCode.refCd));
        try {
            const response = await authService.checkRefCd(userRefCode.refCd);

            console.log(response);
            if (response) {
                return response.data;
            }
        } catch (_error) {
            const error = _error as Error | AxiosError;
            console.log("error=====", error);
            if (axios.isAxiosError(error)) {
                if(error.response){
                    thunkApi.dispatch(setAsyncError(error.response.data.message));
                }
                return thunkApi.rejectWithValue(error?.message);
            }
            thunkApi.dispatch(setAsyncError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);
export const getUserInfoAsync = createAsyncThunk<AuthState['user']>(
    'auth/getUserInfo',
    async (_, thunkApi) => {

        try {
            const response = await authService.getUserInfo();
            if (response) {
                return response.data;
            }
        } catch (_error) {
            const error = _error as Error | AxiosError;
            console.log("error=====", error);
            if (axios.isAxiosError(error)) {
                if(error.response){
                    thunkApi.dispatch(setAsyncError(error.response.data.message));
                }
                return thunkApi.rejectWithValue(error?.message);
            }
            thunkApi.dispatch(setAsyncError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);
export const changeNickNameAsync = createAsyncThunk<AuthState, ChangeNick>(
    'auth/changeNickName',
    async (changeNick: ChangeNick, thunkApi) => {
        console.log("string",changeNick.usrNm);
        try {
            const response = await authService.changeNickName(changeNick.usrNm);
            if (response.resultCode === "SUCCESS") {
                await thunkApi.dispatch(getUserInfoAsync());
                return response.data;
            }else{
                return thunkApi.rejectWithValue(response?.message);
            }

        } catch (_error) {
            const error = _error as Error | AxiosError;
            console.log("error=====", error);
            if (axios.isAxiosError(error)) {
                if(error.response){
                    thunkApi.dispatch(setAsyncError(error.response.data.message));
                }
                return thunkApi.rejectWithValue(error?.message);
            }
            thunkApi.dispatch(setAsyncError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const changePassAsync = createAsyncThunk<AuthState, ChangePass>(
    'auth/changePass',
    async (changePass: ChangePass, thunkApi) => {
        console.log("string",changePass.curUsrPw);
        try {
            const response = await authService.changePass(changePass.curUsrPw, changePass.newUsrPw, changePass.newUsrPw1);
            if (response.resultCode === "SUCCESS") {
                await thunkApi.dispatch(getUserInfoAsync());
                return response.data;
            }else{
                return thunkApi.rejectWithValue(response?.message);
            }
        } catch (_error) {
            const error = _error as Error | AxiosError;
            console.log("error=====", error);
            if (axios.isAxiosError(error)) {
                if(error.response){
                    thunkApi.dispatch(setAsyncError(error.response.data.message));
                }
                return thunkApi.rejectWithValue(error?.message);
            }
            thunkApi.dispatch(setAsyncError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const changeEmailAsync = createAsyncThunk<AuthState, ChangeEmail>(
    'auth/changeEmail',
    async (changeEmail: ChangeEmail, thunkApi) => {
        console.log("string",changeEmail.email);
        try {
            const response = await authService.changeEmail(changeEmail.email);
            if (response) {
                await thunkApi.dispatch(getUserInfoAsync());
                return response.data;
            }
        } catch (_error) {
            const error = _error as Error | AxiosError;
            console.log("error=====", error);
            if (axios.isAxiosError(error)) {
                if(error.response){
                    thunkApi.dispatch(setAsyncError(error.response.data.message));
                }
                return thunkApi.rejectWithValue(error?.message);
            }
            thunkApi.dispatch(setAsyncError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const findPwAsync = createAsyncThunk<AuthState, FindPw>(
    'auth/findPw',
    async (findPw: FindPw, thunkApi) => {

        try {
            const response = await authService.findPw(findPw.usrId);
            if (response.resultCode === "SUCCESS") {
                return response.data;
            }else{
                return thunkApi.rejectWithValue(response?.message);
            }
        } catch (_error) {
            const error = _error as Error | AxiosError;
            console.log("error=====", error);
            if (axios.isAxiosError(error)) {
                if(error.response){
                    thunkApi.dispatch(setAsyncError(error.response.data.message));
                }
                return thunkApi.rejectWithValue(error?.message);
            }
            thunkApi.dispatch(setAsyncError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);



export const logoutAsync = createAsyncThunk('auth/logout', async () => {
    console.log("logoutAsync")
  authService.logout();
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoding: (state, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
    },
    setRefCode: (state, action: PayloadAction<string>) => {
          state.refCode = action.payload;
    },
    setAsyncError: (state, action: PayloadAction<string>) => {
        console.log("setError", action);
      state.asyncError = action.payload;
    },
    refreshToken: (state, { payload }) => {
        console.log("refreshToken", payload);
      state.user.keypairVo.accessToken = payload.token;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
          console.log("state", state);
          console.log("fulfilled", payload);

        state.isLoggedIn = true;
        state.user = payload;
        state.asyncError = '';
      })
      .addCase(loginAsync.rejected, (state) => {
        state.isLoggedIn = false;
      })

    .addCase(getUserInfoAsync.fulfilled, (state, { payload }) => {
      console.log("getUserInfoAsync state", state);
      console.log("getUserInfoAsync fulfilled", payload);

      state.isLoggedIn = true;
      state.user = payload;
      state.asyncError = '';
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.user = {  ...user,
            usrUid: '',
            usrId: '',
            usrPw: '',
            nickNm : '',
            walletAddr: '',
            email:'',
            termsAcptYn: '',
            myRefCd: '',
            upRefCd: '',
            usrTpCd: '',
            stusCd: '',
            grdCd: '',
            keypairVo: {accessToken:''}
        };
        state.asyncError = '';
      })
        .addCase(registerAsync.fulfilled, (state, { payload }) => {
            console.log("fulfilled", payload);
            state.asyncError = '';
        })
        .addCase(registerAsync.rejected, (state) => {
            state.asyncError = '';
        })
        .addCase(certNumAsync.fulfilled, (state, { payload }) => {
            console.log("fulfilled", payload);
            state.checkRef = true;
            state.asyncError = '';
        })
        .addCase(checkRefCd.fulfilled, (state, { payload }) => {
            console.log("fulfilled", payload);
            state.checkRef = true;
            state.asyncError = '';
        })
        .addCase(agreeTermsAsync.fulfilled, (state, { payload }) => {
            console.log("fulfilled", payload);

            state.isLoggedIn = true;
            state.user = payload;
            state.asyncError = '';
        });
  }
});

export const { setAsyncError, refreshToken,setRefCode,setLoding } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
