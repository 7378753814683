import { Navigate } from 'react-router-dom';
import React from 'react';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import { history } from '../../helpers/history';
import {selectAuth} from "../../store/modules/authSlice";
import {FlexColumn} from "./pageArea";
import PrivateLogin from "../../pages/login/privateLogin";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn } = useAppSelector(selectAuth);

  if (!isLoggedIn) {
    // not logged in so redirect to login page with the return url
    return (
        <PrivateLogin />
    )
  }

  // authorized so return child components
  return children;
};

export default PrivateRoute;