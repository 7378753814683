import PageTitle, {
    FlexBottom,
    FlexColumn,
    FlexColumnCenter,
    FlexRowCenter,
    FullScreen,
    PageContent,
    PageRootContainer,
    PopupBodyWrapper
} from "../../components/common/pageArea";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import QRCode from "qrcode.react";
import styled from "styled-components";
import {useAppSelector} from "../../store/hooks";
import {selectAuth} from "../../store/modules/authSlice";
import {ellipseAddress} from "../../helpers/utilities";

import styles from "../../assets/styles/uni.module.scss";
import {Toast} from "../../components/common/popup/ToastAlert";
import CopyToClipboard from "react-copy-to-clipboard";
import BottomButton from "../../components/common/button/BottomButton";
import React from "react";


const QrArea = styled.div`
  width: 160px;
  height: 160px;
  flex-grow: 0;
  margin: 0 4px 20px;
  padding: 10.5px 13.2px 11.4px 10.5px;
  border-radius: 24px;
  border: solid 1px #d4d8e2;
`;
export interface IQrPopup{
    show:boolean;
    setShow:any;
    walletAddr:any;
}
export default function QrPopup(props:IQrPopup){
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { user}  = useAppSelector(selectAuth);

    return (
        <>
            {
                props.show && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle textTitle={t('지갑주소')}
                                   rightSrc={require('../../assets/images/home/close.png')}
                                   onClickRight={()=>{props.setShow(false)}}
                                />
                                <PageContent style={{paddingTop:'78px'}}>
                                    <FlexColumnCenter>
                                        <QrArea>
                                            <QRCode
                                                id="qrCodeEl"
                                                size={261}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={props.walletAddr}
                                            />
                                        </QrArea>
                                        <FlexRowCenter gap={3}>
                                            <div className={styles.txt15_500}>{props.walletAddr ? ellipseAddress(props.walletAddr, 8) : null}</div>
                                            <CopyToClipboard text={props.walletAddr}
                                                             onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                                            <img src={require('../../assets/images/copy.png')} width={20} />
                                            </CopyToClipboard>
                                        </FlexRowCenter>
                                    </FlexColumnCenter>
                                </PageContent>
                                <FlexBottom>
                                    <FlexColumn>
                                        <BottomButton
                                            isActive={true}
                                            backgroundColor={"#3A71FF"}
                                            textColor={"#FFFFFF"}
                                            // border={"solid 1px #dbdee6"}
                                            onClick={()=> props.setShow(false)}
                                            text={t("닫기")}
                                        />
                                    </FlexColumn>
                                </FlexBottom>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}