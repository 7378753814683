import PageTitle, {
    Bar,
    FlexColumEnd,
    FlexColumn,
    FlexColumnCenter,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart,
    PageContent,
    PageRootContainer
} from "../../components/common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import QRCode from "qrcode.react";
import React, {useEffect, useState} from "react";
import QrPopup from "../../components/coin/qrPopup";
import WalletPopup from "../../components/coin/walletPopup";
import {useInView} from "react-intersection-observer";
import EmptyContent from "../../components/common/EmptyContent";
import {decimalFormat, ellipseAddress} from "../../helpers/utilities";
import {ITcCrncy, WalletHis} from "./coinModule";
import {Toast, ToastError} from "../../components/common/popup/ToastAlert";
import CopyToClipboard from "react-copy-to-clipboard";
import WalletService from "../../services/wallet.service";

export const HisTpCd = {
    "WITHDRAW" :"출금"
    ,"BUY" :"구매출금"
    ,"STAKING" :"스테이킹"
    ,"DIVIDEND" :"배당"
    ,"INTEREST" :"배당"
    ,"BONUS" :"매칭보너스"
    ,"GRD1" :"1직급수당"
    ,"GRD2" :"2직급수당"
    ,"GRD3" :"3직급수당"
    ,"GRD4" :"4직급수당"
    ,"GRD5" :"5직급수당"
    ,"SWAP" :"스왑"
    ,"DEAL" :"거래"
    ,"DEPOSIT" :"입금"
    ,"REF" :"추천인보상"
    ,"ADMIN" :"관리자"
}

export default function CoinDetail(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();
    const [showPop, setShowPop] = useState(false);
    const [showWallet, setShowWallet] = useState(false);
    const [coinView,setCoinView] = useState<ITcCrncy>();
    const [dataList, setDataList]= useState<Array<WalletHis>>([]);
    const [currPage, setCurrPage] = useState(1);
    const [blockSize] = useState(10);
    const [searchTxt, setSearchTxt] = useState('');
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);

    useEffect(() => {
        const fetchData = async(coinCode: any) =>{
            const response = await WalletService.getSelectWalletHis(coinCode, currPage, blockSize, true);
            setDataList([...dataList, ...response.data]);
            if (!response.data.length) {
                setLastList(true);
                return;
            }
        }
        if (!lastList && params.coinCode) {
            fetchData(params.coinCode);
        }
    },[currPage, searchTxt, params.coinCode]);

    useEffect(()=>{
        if(params.coinCode){
            WalletService.getCoint(params.coinCode).then(async res=>{
                setCoinView(res.data)
            })
        }
    },[params.coinCode]);
    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    useEffect(()=>{
        console.log("coinView",coinView)
    },[coinView]);
    const checkWallet = ()=>{
        if (coinView?.inWthdrwYn === 'N') {
            ToastError('해당코인은 출금이 불가능 합니다.');
        } else {
            setShowWallet(true);
        }
    }


    return (
        <PageRootContainer>
            <QrPopup show={showPop} setShow={setShowPop} walletAddr={coinView?.walletAddr} />
            <WalletPopup show={showWallet} setShow={setShowWallet} coinView={coinView}/>
            <PageTitle
                textTitle={t('지갑')}
                onClickLeft={()=>{navigate(-1)}}
            />
            <PageContent >
                <FlexColumn gap={25}>
                    <FlexColumn gap={8}>
                        <FlexRowSpace>
                            <FlexRowStart className={styles.txt20_500} gap={10}>
                                <FlexColumn gap={4}>
                                    <div className={styles.txt14_500}>
                                        {coinView?.crncyCd}({coinView?.mainnetCd})
                                    </div>
                                    <FlexRowStart gap={10} className={styles.txt38_700}>
                                        <img src={coinView?.imgPath} width={40}/>{ decimalFormat(coinView?.usrBalance!, 3)}
                                    </FlexRowStart>
                                    <div className={styles.txt14_500_828282}>
                                        ≈ ${ coinView?.usrBalancePrice }
                                    </div>
                                </FlexColumn>
                            </FlexRowStart>
                            <FlexRowEnd>
                                <FlexColumnCenter>
                                    <FlexColumnCenter style={{width:'83px', marginLeft:'50px', borderRadius:'12px', backgroundColor:'#FFFFFF'}}>
                                        <QRCode
                                            id="qrCodeEl"
                                            size={83}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={coinView?.walletAddr!}
                                        />
                                    </FlexColumnCenter>
                                    <FlexRowEnd className={styles.txt12_500} gap={4}>
                                        {ellipseAddress(coinView?.walletAddr!,6)}
                                        <CopyToClipboard text={coinView?.walletAddr!}
                                                         onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                                            <img src={require('../../assets/images/copy.png')} width={24} />
                                        </CopyToClipboard>
                                    </FlexRowEnd>
                                </FlexColumnCenter>
                            </FlexRowEnd>
                        </FlexRowSpace>
                        {
                            coinView?.hldLock>0?
                                (
                                <FlexRowCenter className={styles.white_in_btn} gap={10}
                                               style={{borderRadius:'12px', border:'1px solid #E4E8EC'}}>
                                        <div className={styles.txt14_500}>{t('외부출금 제한 수량')}</div>
                                        <div className={styles.txt14_500_9B9B9B}>
                                            {decimalFormat(coinView?.hldLock,6)}
                                            <span style={{marginLeft:'2px'}}> {coinView?.crncyCd}</span>
                                        </div>
                                </FlexRowCenter>
                                )
                                :
                                (
                                    <></>
                                )
                        }

                    </FlexColumn>
                    <FlexRowCenter gap={12}>
                        <FlexColumnCenter className={styles.white_in_btn} style={{borderRadius:'10px', width:'50%', border:'1px solid #DBDEE6', fontSize:'15px', fontWeight:'700'}} onClick={()=>{setShowPop(true)}}>{t('입금')}</FlexColumnCenter>
                        <FlexColumnCenter className={styles.blue_in_btn} style={{borderRadius:'10px', width:'50%', fontSize:'15px', fontWeight:'700'}} onClick={()=>{checkWallet()}}>{t('출금')}</FlexColumnCenter>
                    </FlexRowCenter>
                    <Bar/>
                    {
                        dataList.length > 0 ? (
                            dataList.map((item, index) => {
                                return (
                                    <FlexRowSpace key={index}>
                                        <FlexRowStart>
                                            <FlexColumn>
                                                <div className={styles.txt16_500}>
                                                    <span>{HisTpCd[item.tpCd]?HisTpCd[item.tpCd]:item.tpCd}</span>
                                                    <span className={styles.txt14_500_9B9B9B} style={{marginLeft:'10px'}}>
                                                        {
                                                            item.tpCd==='ADMIN' || item.tpCd==='STAKING'?(<></>):item.addr==='kdream_admin@kdream.net'? (
                                                                <>(K-DREAM)</>
                                                            ):(<>({ellipseAddress(item.addr,3)})</>)
                                                        }
                                                    </span>
                                                </div>
                                                <div className={styles.txt14_500_828282}>
                                                    {
                                                        item.stusCtnt?
                                                            (<FlexRowStart>
                                                                {item.stusCtnt}
                                                            </FlexRowStart>):
                                                        item.exTxid ? (
                                                            <FlexRowStart>
                                                                {ellipseAddress(item.exTxid,6)}
                                                                <CopyToClipboard text={item.exTxid}
                                                                        onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                                                                <img src={require('../../assets/images/copy.png')} width={24} />
                                                            </CopyToClipboard>
                                                            </FlexRowStart>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                                <div className={styles.txt14_500_828282}>
                                                    <span style={{marginRight:'5px'}}>{item.formatDate}</span>
                                                    {
                                                        item.stusCd==='FAIL'?
                                                            <span className={styles.txt14_500_red}> (실패)</span>
                                                            :item.stusCd==='COMPLEATE'?
                                                                <span> (완료)</span>
                                                            :<span className={styles.txt14_500_green}>(진행중)</span>
                                                    }
                                                </div>
                                            </FlexColumn>
                                        </FlexRowStart>
                                        <FlexRowEnd>
                                            <FlexColumEnd>
                                                <div className={styles.txt18_700}  style={{color:item.txTp === 'W' ? '#B30A0AFF' : '#3A71FF'}}>
                                                    {item.txTp === 'D' ? '+' : '-'}{decimalFormat(item.vlm,2)}
                                                </div>
                                                <div className={styles.txt14_500_828282}>{t(item.txTpNm)}</div>
                                            </FlexColumEnd>
                                        </FlexRowEnd>
                                    </FlexRowSpace>
                                )
                            })
                        ) : (
                            <EmptyContent titleText={t("내역이 없습니다.")} titleImage={require('../../assets/images/list_x.png')}/>
                        )
                    }
                    {
                        Object.values(dataList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                    }
                </FlexColumn>
            </PageContent>
        </PageRootContainer>
    )
}