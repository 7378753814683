import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PageTitle, {
    Bar,
    FlexColumn,
    FlexColumnCenter, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../../components/common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import Footer from "../../components/layer/footer";
import SettingPopup from "./settingPopup";
import {useEffect, useState} from "react";
import {getUserInfoAsync, logoutAsync, selectAuth} from "../../store/modules/authSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {firstCntAsync, getRefOrgAsync, selectProfile} from "../../store/modules/profileSlice";
import localJson from "../../helpers/Locales/local.json";
import i18next from "i18next";
import {ToastError} from "../../components/common/popup/ToastAlert";
import ProfileService from "../../services/profile.service";

export const GRD_MAP={
    "GRD0" : "일반"
    ,"GRD1" : "1직급"
    ,"GRD2" : "2직급"
    ,"GRD3" : "3직급"
    ,"GRD4" : "4직급"
    ,"GRD5" : "5직급"
}

export default function Setting(){
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [pageMode, setPageMode] = useState('');
    const [grdCd, setGrdCd] = useState('GRD0');
    const [showPop, setShowPop] = useState(false);
    const dispatch = useAppDispatch();
    const {refCnt, miningCnt } = useAppSelector(selectProfile);
    const {isLoggedIn, user, asyncError}  = useAppSelector(selectAuth);
    const [countryList, setCountryList] = useState<Array<{image:string, localType:string, localTxt:string}>>(localJson);
    const openPopup = (pageData:string)=>{
        setPageMode(pageData);
        setShowPop(true);
    }
    const onChangeLang = (localType:string) => {
        i18next.changeLanguage(localType);
        localStorage.setItem('language', localType);
    }
    useEffect(()=>{
        // console.log("user.grdCd", user.grdCd);
        // if (!user.grdCd) {
        //     ToastError('다시로그인해 주세요.');
        //     dispatch(logoutAsync());
        // }
        ProfileService.getUserInfoRealtime().then(res=>{
            setGrdCd(res.data.grdCd);
        })

        dispatch(firstCntAsync());
        // dispatch(getRefOrgAsync());
    },[])
    return (
        <PageRootContainer>
            <SettingPopup show={showPop} setShow={setShowPop} pageMode={pageMode} />
            <PageTitle
                textTitle={t('설정')}
                onClickLeft={() => {
                    navigate(-1)
                }}
            />
            <PageContent>
                <FlexColumn gap={20}>
                    <FlexColumn gap={3}>
                        <FlexRowStart gap={10}>
                            <div className={styles.txt20_400}>{user.nickNm}</div>
                            <img src={require('../../assets/images/setting/pencil-alt.png')} width={20}  onClick={()=>{openPopup('nick');}}/>
                        </FlexRowStart>
                        <div className={styles.txt14_300}>{user.usrId}</div>
                    </FlexColumn>
                    <FlexColumn className={styles.white_in_btn} gap={14} style={{color:'#374151', fontSize:'14px', padding:'15px', borderRadius:'12px'}}>
                        <FlexRowSpace>
                            <FlexRowStart>{t("직급")}</FlexRowStart>
                            <FlexRowEnd style={{color:'#374151'}}>{GRD_MAP[grdCd]}</FlexRowEnd>
                        </FlexRowSpace>
                        <div className={styles.gray_line}/>
                        <FlexRowSpace>
                            <FlexRowStart>{t("상위추천인코드")}</FlexRowStart>
                            <FlexRowEnd style={{color:'#374151'}}>{user.upRefCd}</FlexRowEnd>
                        </FlexRowSpace>
                        <div className={styles.gray_line}/>
                        <FlexRowSpace>
                            <FlexRowStart><div>{t("나를 추천한 친구")}</div><div>{t("{0}명", {0:refCnt})}</div> {'>'} </FlexRowStart>
                            <FlexRowEnd>
                                <div className={styles.yellow_in_box} onClick={()=>{navigate('/ref')}}>{t("추천하기")}</div>
                            </FlexRowEnd>
                        </FlexRowSpace>
                    </FlexColumn>
                    <Bar />
                    <FlexColumn gap={32} className={styles.txt16_500}>
                        <FlexRowStart gap={8}  onClick={()=>{openPopup('pass');}}>
                            <img src={require('../../assets/images/setting/icon_20.png')} width={20} />{t("패스워드 변경")}
                        </FlexRowStart>
                        <FlexRowSpace>
                        <FlexRowStart gap={8}>
                            <img src={require('../../assets/images/setting/icon_23.png')} width={20} />{t("언어변경")}
                        </FlexRowStart>
                            <FlexRowEnd>
                                <div style={{marginTop:'10px'}} className={styles.dropdown}>
                                    <FlexRowCenter gap={9} className={styles.mainCountySelect}>
                                        <div><img src={'../home/'+ (localStorage.getItem("language") ? localStorage.getItem("language")?.toUpperCase() : 'KR') +'.png'} width={15} /></div>
                                        <div className={styles.txt12_500}>{t('한국어')}</div>
                                        <div><img src={require('../../assets/images/arrow_bottom.png') } width={20} /></div>
                                    </FlexRowCenter>
                                    <div className={styles.dropdownContent}>
                                        {
                                            countryList.map((item, index) =>{
                                                return (
                                                    <a key={index} onClick={()=>{onChangeLang(item.localType)}}><img src={item.image} width={15}/>  {item.localTxt}</a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </FlexRowEnd>
                        </FlexRowSpace>
                        <FlexRowStart gap={8} onClick={()=>{dispatch(logoutAsync())}}>
                            <img src={require('../../assets/images/setting/icon_24.png')} width={20} />{t("로그아웃")}
                        </FlexRowStart>
                    </FlexColumn>
                    <Bar/>
                </FlexColumn>
            </PageContent>
            <Footer/>
        </PageRootContainer>
    )
}