import {FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/pageArea";
import {useScript} from "../../helpers/hooks";
import {useEffect} from "react";
import { FacebookShareButton, TelegramShareButton, TwitterShareButton } from "react-share";
import {inspect} from "util";
import styles from "../../assets/styles/uni.module.scss";
declare global {
    interface Window {
        Kakao: any;
    }
}
export default function Footer(){
    const status = useScript("https://developers.kakao.com/sdk/js/kakao.js");
    const linkUrl = window.location.protocol + "//" + window.location.host;
    const handleKakaoButton = (currentUrl:any) => {
        window.Kakao.Share.sendScrap({
            requestUrl: currentUrl,
        });
    };
    useEffect(() => {
        if (status === "ready" && window.Kakao) {
            // 중복 initialization 방지
            if (!window.Kakao.isInitialized()) {
                // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
                window.Kakao.init(process.env.REACT_APP_KAKAO_AUTH_KEY);
            }
        }
    }, [status]);

    return(
        <FlexRowSpace style={{marginTop:'50px'}}>
            <FlexRowStart>
                {/*<img src={require('../../assets/images/home/logo.png')} width={94} />*/}
                <div className={styles.txt14_500_828282}>JVC Co.Ltd</div>
            </FlexRowStart>
            <FlexRowEnd gap={10}>
                {/*<TelegramShareButton url={linkUrl}>*/}
                {/*    <div><img src={require('../../assets/images/sns/telegramBtn.png')} width={24}/></div>*/}
                {/*</TelegramShareButton>*/}
                <div onClick={()=>handleKakaoButton(linkUrl)}>
                    <img src={require('../../assets/images/sns/kakaoBtn.png')} width={24}/>
                </div>
                {/*<TwitterShareButton url={linkUrl}>*/}
                {/*    <div><img src={require('../../assets/images/sns/twitterBtn.png')} width={24}/></div>*/}
                {/*</TwitterShareButton>*/}
                <div><img src={require('../../assets/images/sns/bandBtn.png')} width={24}/></div>
            </FlexRowEnd>
        </FlexRowSpace>
    )
}