import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Trans, useTranslation } from 'react-i18next';
import styles from "../../assets/styles/uni.module.scss";

import PageTitle, {
    FlexBottom,
    FlexBottomFree, FlexColumn, FlexColumnCenter, FlexRowCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart,
    PageRootContainer
} from "../../components/common/pageArea";
import {selectAuth} from "../../store/modules/authSlice";
import React, {useCallback, useEffect, useState} from "react";
import PrivateRoute from "../../components/common/privateRoute";
import localJson from "../../helpers/Locales/local.json";
import i18next from "i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/styles/slick.scss'
import HomeLayer1 from "../../components/home/home_layer1";
import HomeLayer2 from "../../components/home/home_layer2";
import HomeLayer3 from "../../components/home/home_layer3";
import Footer from "../../components/layer/footer";
import ProductService from "../../services/main.service";
import {ISlideData} from "./homeModule";
import {NetConfirmPopup} from "../../components/common/popup/ConfirmPopup";

export default function Home() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [countryList, setCountryList] = useState<Array<{image:string, localType:string, localTxt:string}>>(localJson);
    const {isLoggedIn, user}  = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const [slideContents, setSlideContents] = useState<Array<ISlideData>>();
    const onChangeLang = (localType:string) => {
        i18next.changeLanguage(localType);
        localStorage.setItem('language', localType);
    }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover : true,
        draggable : true,
        // appendDots: (dots: any) => (
        //     <FlexRowStart gap={3}
        //                   style={{
        //                       width: '100%',
        //                       position: 'absolute',
        //                       bottom: '-18px'
        //                   }}
        //     >
        //         <ul style={{marginLeft:'-23px'}}> {dots} </ul>
        //     </FlexRowStart>
        // ),
        // dotsClass: 'dots_custom'
    }
    // useEffect(()=>{
    //     ProductService.getContetns().then((res:any)=> {
    //
    //         setSlideContents(res.data)
    //     });
    // },[])
/*
 ●사기주의보●
최근 당사의 관계자임을 사침하여
코인구매를 권유한후 입금을받고
코인을 지급하지않은 사례들이
빈번해지고있습니다

 당사는 구매신청한 모든코인을 즉시 지급하였으며, DRM토큰을 구매와동시에 본인계정에  들어오지 않은경우 사기일 가능성이 농후함으로
입금자에게 즉시 반환또는 입금문의 부탁드립니다

당사는 코인구매와 지급에 관한 어떠한 책임도 지지않습니다.
 */
    // useEffect(() => {
    //     NetConfirmPopup(<div>●사기주의보●</div>
    //         ,<FlexColumn className={styles.txt14_828282} style={{textAlign:'left'}}>
    //             최근 당사의 관계자임을 사침하여
    //             코인구매를 권유한후 입금을받고
    //             코인을 지급하지않은 사례들이
    //             빈번해지고있습니다.
    //             <br/><br/>
    //             당사는 구매신청한 모든코인을 즉시 지급하였으며,
    //             DRM토큰을 구매와동시에 본인계정에  들어오지 않은경우 사기일 가능성이 농후함으로
    //             입금자에게 즉시 반환또는 입금문의 부탁드립니다<br/><br/>
    //
    //             당사는 코인구매와 지급에 관한 어떠한 책임도 지지않습니다.</FlexColumn>
    //         , ""
    //         ,{btnTxt:t("닫기"),
    //             onClick: () => {
    //
    //             }}
    //         , {btnTxt:"",
    //             onClick: () => {
    //             }}
    //     );
    // }, []);
    return (
        <>
            <FlexBottomFree style={{ top: '0px', borderRadius:'0px', zIndex:'100', height:'90px', background:'#F8F8F8'}}>
                <FlexRowSpace>
                    <div><img src={require('../../assets/images/home/logo.png')} width={160} onClick={()=>navigate('/')} /></div>
                </FlexRowSpace>
            </FlexBottomFree>
            <PageRootContainer style={{paddingTop:'115px'}}>
                <FlexColumn gap={35}>
                    <FlexColumn gap={5}>
                        <div className={styles.txt30_700}>수익사업 연동으로 </div>
                        <div className={styles.txt30_700}>스마트한 스테이킹 서비스</div>
                        {/*<div><img src={require('../../assets/images/home/logo.png')} width={207} /></div>*/}
                    </FlexColumn>
                    <FlexRowCenter gap={12}>
                        <FlexColumnCenter className={styles.black_in_btn} style={{
                            borderRadius:'10px'
                            , width:'100%'
                            , fontSize:'20px'
                            , fontWeight:'700'
                            , border:'8px solid #f2be74'
                        }} onClick={()=>{navigate('/my')}}>
                            {t('DRM 스마트 스테이킹 참여하기')}
                        </FlexColumnCenter>
                    </FlexRowCenter>

                    <div style={{width:'100%', height:'240px',  borderRadius:'20px'}}>
                        <Slider {...settings}>
                            <FlexColumnCenter gap={16}>
                                <video autoPlay={true}  preload={"auto"} controls loop style={{width:'100%'}}>
                                    <source src={require('../../assets/intro.mp4')} type="video/mp4"/>
                                </video>
                            </FlexColumnCenter>
                            {/*<FlexColumnCenter gap={16} className={styles.slider_bg}>*/}
                            {/*    <FlexColumnCenter gap={60} style={{padding:'60px 30px'}}>*/}
                            {/*        <div className={styles.txt18_700} style={{padding:'12px 32px', background:'#FFFFFF',opacity:'0.8', color:'#0F8CFF', borderRadius:'30px'}}>{t('수익사업 진행 현황')}</div>*/}
                            {/*        <FlexColumnCenter className={styles.txt20_700} style={{*/}
                            {/*            color:'black'*/}
                            {/*            ,backgroundColor:'#f2be74'*/}
                            {/*            ,opacity:'0.7'*/}
                            {/*            ,padding:'10px'*/}
                            {/*            ,borderRadius:'20px'*/}
                            {/*            ,fontSize:'16px'*/}
                            {/*        }}>*/}
                            {/*            <div>{t('정캣계약')}</div>*/}
                            {/*            <div>{t('국내 외국인전용 카지노(영종도)')}</div>*/}
                            {/*        </FlexColumnCenter>*/}
                            {/*    </FlexColumnCenter>*/}
                            {/*</FlexColumnCenter>*/}
                        </Slider>
                    </div>
                    <HomeLayer1 imgSrc={'main_icon.png'} contents={'실제 수익사업과 연동되어<br/>보다 안정적인 투자가능'}/>

                    <HomeLayer2 title={'🏦 스마트 스테이킹이란?'} contents={
                        'DRM 토큰을 스마트 스테이킹 서비스를 통해 예치하면, 카지노 정켓 운영수익에 대한 배당을 매월 받을 수 있는 수익 사업이 확실한 서비스 입니다.<br/><br/>추후 카지노뿐만이 아닌 게임, 엔터네이먼트, 쇼핑등의 다양한 사업에서 발생한 수익 또한 투자자에게 배당 합니다.'
                    }/>
                    <HomeLayer1 imgSrc={'casino.png'} contents={'DRM으로 <br/>카지노 투자 및 수익배당'}/>
                    <HomeLayer2 title={'\uD83C\uDF24 프로젝트 소개'} contents={
                        'JVC 재단과 서브 정켓 운영 계약을 맺은 외국계 대형 카지노 정켓 운영사는 필리핀 상위 카지노에 많은 테이블을 보유하고 있으며 2023년도 상반기 기준 업계 매출 1위를 달성한 큰 기업 입니다.<br/><br/>' +
                        '국내 영종도 및 서울에 소재하고 있는 대규모 카지노 정켓을 중점으로 운영함과 동시에 국내 대규모 카지노들이 구축한 넓고 다양한 인프라를 통해 방대한 VIP회원 유입과 매출을 일으킬 예정 입니다.' +
                        '<br/><br/>이를 바탕으로 DRM프로젝트는 블록체인 기술을 기반으로 투명한 생태계를 만들어가며, 다양한 엔터테이먼트 산업을 통합하여 실물 자산 기반의 수익형 토큰을 발행하여 카지노 수익과 배당을 연계하며, 카지노뿐만 아닌 게임,쇼핑,엔터테이먼트 분야에서 발생하는 수익 또한 연계하여 DRM 홀더에게 다양하고 안정적인 수익 기회를 제공합니다.'}/>
                    <HomeLayer1 imgSrc={'drm.png'} contents={'수수료가 저렴하고 빠른<br/>BIO 메인넷 기반의 DRM 토큰'}/>
                    <HomeLayer2 title={'\uD83D\uDE80 ️DRM 토큰정보'} contents={
                        '- 코인이름 :  Dream Token<br/>' +
                        '- 심볼 : DRM<br/>' +
                        '- 총발행량 : 1,000,000,000<br/>' +
                        '- 상장거랙소 : LBank Exchange (2월초 상장 예정)<br/><br/>' +
                        '재단(40%) , 마케팅(5%)<br/>코인세일(10%) , 유통(45%)'
                    }/>
                    <div className={styles.gray_line} />
                    <div className={styles.txt26_700}>{t('\uD83E\uDDD1\u200D\uD83C\uDF93  재단소개')}</div>
                        <FlexColumn className={styles.layer_box} style={{padding:'0px 20px 20px 20px'}} gap={15}>
                            <FlexColumnCenter style={{padding:'15px 0px', textAlign:'center'}} className={styles.txt18_500}>CEO 연혁</FlexColumnCenter>
                            <FlexRowStart>
                                <FlexColumn className={styles.txt14_500_828282} style={{width:'120px',marginBottom:'auto'}}>2001 ~ 2003</FlexColumn>
                                <FlexColumn className={styles.txt14_500_828282}>(주)하이월드 | CEO</FlexColumn>
                            </FlexRowStart>
                            <FlexRowStart>
                                <FlexColumn className={styles.txt14_500_828282} style={{width:'120px',marginBottom:'auto'}}>2002 ~ 2008</FlexColumn>
                                <FlexColumn className={styles.txt14_500_828282}>
                                    (주)RMI | CEO(필리핀현지법인)<br/>인터넷카지노 18곳 운영 필리핀정부 라이센스취득
                                </FlexColumn>
                            </FlexRowStart>
                            <FlexRowStart>
                                <FlexColumn className={styles.txt14_500_828282} style={{width:'120px',marginBottom:'auto'}}>2005 ~ 2008</FlexColumn>
                                <FlexColumn className={styles.txt14_500_828282}>
                                    (주)디지털웨이블 | CEO (필리핀현지법인)<br/>
                                    인터넷 카지노 소프트웨어 납품<br/>
                                    필리핀 정보 PAGCOR (필리핀현지법인)
                                </FlexColumn>
                            </FlexRowStart>
                            <FlexRowStart>
                                <FlexColumn className={styles.txt14_500_828282} style={{width:'120px',marginBottom:'auto'}}>2007 ~ 2012</FlexColumn>
                                <FlexColumn className={styles.txt14_500_828282}>
                                    PEP Co.Ltd | CEO 및 대주주 (엔터테이먼트 전문기업)<br/>
                                    필리핀 증권거래소 상장기업
                                </FlexColumn>
                            </FlexRowStart>
                            <FlexRowStart>
                                <FlexColumn className={styles.txt14_500_828282} style={{width:'120px',marginBottom:'auto'}}>2008 ~ 2012</FlexColumn>
                                <FlexColumn className={styles.txt14_500_828282}>
                                    마닐라 소피텔 카지노 운영<br/>
                                    마닐라 뉴월드 호텔 카지노 정켓 운영
                                </FlexColumn>
                            </FlexRowStart>
                            <FlexRowStart>
                                <FlexColumn className={styles.txt14_500_828282} style={{width:'120px',marginBottom:'auto'}}>2013 ~ 2018</FlexColumn>
                                <FlexColumn className={styles.txt14_500_828282}>
                                    GGIC&D | CEO (필리핀현지법인)<br/>
                                    LED광고판 설치 광고 사업
                                </FlexColumn>
                            </FlexRowStart>
                            <FlexRowStart>
                                <FlexColumn className={styles.txt14_500_828282} style={{width:'120px',marginBottom:'auto'}}>2015 ~ 현재</FlexColumn>
                                <FlexColumn className={styles.txt14_500_828282}>
                                     JVC Co.Ltd | CEO (필리핀현지법인)
                                </FlexColumn>
                            </FlexRowStart>
                            <FlexRowStart>
                                <FlexColumn className={styles.txt14_500_828282} style={{width:'120px',marginBottom:'auto'}}>2019 ~ 현재</FlexColumn>
                                <FlexColumn className={styles.txt14_500_828282}>
                                    비트원 재단 | 의장<br/>
                                    (주)리셋 | CEO
                                </FlexColumn>
                            </FlexRowStart>
                        </FlexColumn>
                    <Footer />
                </FlexColumn>
            </PageRootContainer>
        </>
    )
}