import styles from "../../assets/styles/uni.module.scss";
import {FlexColumEnd, FlexColumn, FlexColumnCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/pageArea";
import {Trans, useTranslation} from "react-i18next";
import Toggle from "react-toggle";
import React, {useEffect, useState} from "react";
import {IStakingList} from "../../pages/staking/stakingPage";
import moment from "moment";
import StakingService from "../../services/staking.service";
import {Toast} from "../common/popup/ToastAlert";
import CopyToClipboard from "react-copy-to-clipboard";


export default function StakingEndList(props:{stkData:IStakingList, onClickViewItem?:(item: any)=> void;}){
    const { t } = useTranslation();
    const [endDate, setEndDate] = useState("");
    useEffect(()=>{
        if(props.stkData){
            setEndDate(moment(props.stkData.endDtm).format("YYYY-MM-DD HH:mm:ss"));
        }

    },[props.stkData])
    return (
        <FlexColumn gap={3}>
            <FlexRowSpace>
                <FlexRowStart style={{width:'70%'}}>
                    <FlexColumn>
                        <div className={styles.txt16_500_374151}>스마트 스테이킹</div>
                        <div className={styles.txt14_828282}>
                            {props.stkData.dpstInTxid}
                            <CopyToClipboard text={props.stkData.dpstInTxid}
                                             onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                                <img src={require('../../assets/images/copy.png')} width={20} />
                            </CopyToClipboard>
                        </div>
                        <div className={styles.txt14_828282}>{endDate}</div>
                    </FlexColumn>
                </FlexRowStart>
                <FlexRowEnd style={{width:'30%'}}>
                    <FlexColumEnd>
                        <div className={styles.txt18_700_374151}>{props.stkData.stkVlm} DRM</div>
                        <div className={styles.txt14_500_3A71FF}>스테이킹 완료</div>
                    </FlexColumEnd>
                </FlexRowEnd>
            </FlexRowSpace>
        </FlexColumn>
    )
}
