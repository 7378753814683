import PageTitle, {
    Bar, FlexBottom,
    FlexColumn, FlexColumnCenter, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../../components/common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import {useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {IRefMining} from "./refMining";
import React, {useEffect, useState} from "react";
import BottomButton from "../../components/common/button/BottomButton";
import {IHarvestNftDto} from "../my/myModule";
import ProfileService from "../../services/profile.service";
export default function RefMiningComplete(props:IRefMining){
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [viewData, setViewData] = useState<IHarvestNftDto>({
        inTxid: '',
        hrvstVlm: 0,
        stusCd:  '',
        stusCtnt: '',
        regDtm:  '',
    });
    useEffect(()=>{
        if(props.show){
            ProfileService.getHarvestNft(props.inTxId).then(res=>{
                setViewData(res.data);
            });
        }
    },[props.show])

    return (
        <>
            {
                props.show && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle textTitle={t('수확완료')} rightSrc={require('../../assets/images/home/close.png')} onClickRight={()=>{props.setShow(false)}}/>
                                <div className={styles.gray_line} style={{width:'calc(100% + 40px)', marginLeft:'-20px'}} />
                                <PageContent style={{paddingTop:'47px'}}>
                                    <FlexColumn gap={18}>
                                        <FlexColumnCenter>
                                            <img src={require('../../assets/images/home/welcome.png')} width={225}/>
                                            <div className={styles.txt30_700} style={{textAlign:'center'}}><Trans t={t} i18nKey={("👩‍🌾 추천인 보상채굴을<br/>받았습니다.")}/></div>
                                        </FlexColumnCenter>
                                        <div className={styles.gray_line} style={{width:'calc(100% + 40px)', marginLeft:'-20px'}} />
                                        <FlexColumn gap={22}>
                                            <FlexRowSpace className={styles.txt16_500_828282}>
                                                <FlexRowStart>TXID</FlexRowStart>
                                                <FlexRowEnd>
                                                    {viewData.inTxid}
                                                    <img src={require('../../assets/images/copy.png')} width={24} />
                                                </FlexRowEnd>
                                            </FlexRowSpace>
                                            <FlexRowSpace className={styles.txt16_500_828282}>
                                                <FlexRowStart>{t('받은수량')}</FlexRowStart>
                                                <FlexRowEnd>{viewData.hrvstVlm} BOOST</FlexRowEnd>
                                            </FlexRowSpace>
                                        </FlexColumn>
                                        <div className={styles.gray_line} style={{width:'calc(100% + 40px)', marginLeft:'-20px'}} />
                                        <FlexColumnCenter className={styles.white_in_box} style={{fontSize:'14px', color:'#828282', borderRadius:'12px', padding:'20px'}}>
                                            {t('BOOST코인 전송은 네트워크 사정에 따라 다소 지연이 될수 있습니다. 출금 TXID는 신청 완료 후 출금 내역에서 확인이 가능 합니다.')}
                                        </FlexColumnCenter>
                                    </FlexColumn>
                                    <FlexBottom>

                                        <FlexColumn>
                                            <BottomButton
                                                isActive={true}
                                                backgroundColor={"#FEE500"}
                                                textColor={"#000000"}
                                                // border={"solid 1px #dbdee6"}
                                                onClick={()=>{navigate("/my")}}
                                                text={t("자산관리 바로가기")}
                                            />
                                        </FlexColumn>
                                    </FlexBottom>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}