import PageTitle, {
    FlexBottom,
    FlexColumn,
    FlexColumnCenter, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../../components/common/pageArea";
import React, {useEffect, useState} from "react";
import styles from "../../assets/styles/uni.module.scss";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import CopyToClipboard from "react-copy-to-clipboard";
import {selectAuth} from "../../store/modules/authSlice";
import {Toast} from "../../components/common/popup/ToastAlert";
import {firstCntAsync, getRefOrgAsync, selectProfile} from "../../store/modules/profileSlice";
import RefPopup from "./RefPopup";
import BottomButton from "../../components/common/button/BottomButton";
import {useTranslation} from "react-i18next";
import profileService from "../../services/profile.service";

export default function RefPage(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {isLoggedIn, user}  = useAppSelector(selectAuth);

    const [stkCnt, setStkCnt] = useState(0);
    const [showPop, setShowPop] = useState(false);
    const {refList ,refCnt} = useAppSelector(selectProfile);
    const [pageView, setPageView] = useState('ref');

    useEffect(() => {
        let cnt = 0;
        refList.map(item=> {
            if (item.stkVlm > 0) {
                cnt++;
            }
        });
        setStkCnt(cnt);
    }, [refList]);

    useEffect(() => {
        console.info("refList", refList);
    }, [refList]);

    useEffect(()=>{
        dispatch(firstCntAsync());
        dispatch(getRefOrgAsync());
    },[])

    return (
        <PageRootContainer>
            <RefPopup setShow={setShowPop} show={showPop}/>
            <PageTitle
                textTitle ={t("추천하기")}
                onClickLeft={()=>{
                    navigate(-1);
                }}
            />
            <PageContent>
                <FlexColumn gap={49}>
                    <FlexColumn gap={4}>
                        <div className={styles.txt20_400}>{t("추천 링크를 통해 지인을 초대하면,")}</div>
                        <div className={styles.txt20_700}>{t("추가 보상이 지급 됩니다!")}</div>
                    </FlexColumn>
                    <FlexColumnCenter>
                        <img src={require('../../assets/images/home/welcole.png')} width={207} />
                    </FlexColumnCenter>
                    <FlexColumn gap={10}>
                        <FlexRowSpace>
                            <div className={styles.txt13_B02}>{t("추천하기")}</div>
                            <div className={styles.yellow_in_box} style={{fontSize:'14px', fontWeight:'400'}}
                                 onClick={()=>{
                                setPageView('ref');
                                setShowPop(true);
                                    }}
                            >{t("조직도 보기")}</div>
                        </FlexRowSpace>
                        <FlexColumn className={styles.white_in_btn} style={{borderRadius:'12px'}} gap={15}>
                            <FlexRowSpace>
                                <FlexRowStart className={styles.txt14_500}>{t("추천링크")}</FlexRowStart>
                                <FlexRowEnd className={styles.txt14_9B9B9B} gap={3}>
                                    {t('추천링크복사')}
                                    <CopyToClipboard text={window.location.protocol + "//" + window.location.host+"/?refCode="+user.myRefCd}
                                                     onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                                        <img src={require('../../assets/images/copy.png')} width={24} />
                                    </CopyToClipboard>
                                </FlexRowEnd>
                            </FlexRowSpace>
                            <div className={styles.gray_line}></div>
                            <FlexRowSpace>
                                <FlexRowStart className={styles.txt14_500}>{t("추천인코드")}</FlexRowStart>
                                <FlexRowEnd className={styles.txt14_9B9B9B} gap={3}>
                                    {user.myRefCd}
                                    <CopyToClipboard text={user.myRefCd} onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                                        <img src={require('../../assets/images/copy.png')} width={24} />
                                    </CopyToClipboard>
                                </FlexRowEnd>
                            </FlexRowSpace>
                            <div className={styles.gray_line}></div>
                            <FlexRowSpace>
                                <FlexRowStart className={styles.txt14_500}>{t("직대 추천인수")}</FlexRowStart>
                                <FlexRowEnd className={styles.txt14_9B9B9B} gap={3}>
                                    {t("{0}명", {0:refCnt})}
                                </FlexRowEnd>
                            </FlexRowSpace>
                            <div className={styles.gray_line}></div>
                            <FlexRowSpace>
                                <FlexRowStart className={styles.txt14_500}>{t("스테이킹중인 친구")}</FlexRowStart>
                                <FlexRowEnd className={styles.txt14_9B9B9B} gap={3}>
                                    {t("{0}명", {0:stkCnt})}
                                </FlexRowEnd>
                            </FlexRowSpace>
                        </FlexColumn>
                    </FlexColumn>
                </FlexColumn>
            </PageContent>
            <FlexBottom>
                <CopyToClipboard text={window.location.protocol + "//" + window.location.host+"/?refCode="+user.myRefCd}
                                 onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#FFDD00"}
                        textColor={"#000000"}
                        // border={"solid 1px #dbdee6"}
                        onClick={()=>{}}
                        text={t("추천인링크 복사")}
                    />
                </CopyToClipboard>
            </FlexBottom>
        </PageRootContainer>
    )
}