import styles from "../../assets/styles/uni.module.scss";
import {FlexColumn, FlexColumnCenter} from "../common/pageArea";
import {Trans, useTranslation} from "react-i18next";


export default function HomeLayer1(props:{imgSrc:string, contents:string}){
    const { t } = useTranslation();
    return (
        <FlexColumnCenter>
            <img src={require('../../assets/images/home/'+props.imgSrc)} width={115} />
            <div className={styles.txt18_500} style={{textAlign:'center'}}><Trans t={t} i18nKey={props.contents} /></div>
        </FlexColumnCenter>
    )
}
