import {IJoinDepth} from "../../pages/login/join";
import {FlexBottom, FlexColumn, FlexRowStart, PageContent} from "../common/pageArea";
import {useForm} from "react-hook-form";
import {certNumAsync, registerAsync} from "../../store/modules/authSlice";
import {useAppDispatch} from "../../store/hooks";
import styles from "../../assets/styles/uni.module.scss";
import DefaultInput from "../common/input/DefaultInput";
import BottomButton from "../common/button/BottomButton";
import {useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {useEffect} from "react";


export default function MailConfirm(props:IJoinDepth){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
        setError,
        clearErrors
    } = useForm();
    const onSubmit = async (data?:any): Promise<void> => {
        const {usrId, usrPw, usrPwConf, usrNm, upRefCd} = props.joinData;
        const certNum = "111111";
        dispatch(registerAsync({ usrId, usrPw, usrPwConf, usrNm, upRefCd, certNum }))
            .unwrap()
            .then(() => {
                navigate("/welcome");
            })
            .catch(() => {

            });
    }

    useEffect(()=>{
        console.log(props.joinData);
        onSubmit();
    },[props.joinData]);

    return (
        <>
            <PageContent style={{paddingTop:'26px'}}>
                <FlexColumn gap={50}>
                    {/*<DefaultInput*/}
                    {/*    lable={t("인증번호 입력")}*/}
                    {/*    error={errors.certNum}*/}
                    {/*    titleClassName={styles.txt16_500}*/}
                    {/*    inputElement={*/}
                    {/*        <input*/}
                    {/*            type={"text"}*/}
                    {/*            className={styles.inputText_Bottom}*/}
                    {/*            placeholder={t("인증번호 6자리를 입력해 주세요.")}*/}
                    {/*            {...register("certNum", {*/}
                    {/*                required: t("인증번호 6자리를 입력해 주세요.")*/}
                    {/*            })}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*/>*/}
                    <FlexColumn className={styles.white_in_btn} style={{borderRadius:'12px'}} gap={4}>
                        <FlexRowStart className={styles.txt18_700} gap={4}><img src={require('../../assets/images/home/mark_blue.png')} width={20} />{t('회원가입 완료.')}</FlexRowStart>
                        <div className={styles.txt14_828282}>
                            <br/>
                            <div>
                                {props.joinData.usrId}으로 회원가입을 진행 합니다.<br/><br/>회원가입 완료 버튼을 클릭해 주세요.
                            </div>
                            {/*<Trans t={t} i18nKey={("{0}으로 회원가입을 진행 합니다.<br/><br/>회원가입 완료 버튼을 클릭해 주세요.")} values={{0:props.joinData.usrId}}/>*/}
                        </div>
                    </FlexColumn>
                </FlexColumn>
            </PageContent>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#0F8CFF"}
                        textColor={"#FFFFFF"}
                        // border={"solid 1px #dbdee6"}
                        onClick={handleSubmit(onSubmit)}
                        text={t("회원가입 완료.")}
                    />
                </FlexColumn>
            </FlexBottom>
        </>
    )
}