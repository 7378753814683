import React, { InputHTMLAttributes } from "react";
import styles from "../../../assets/styles/uni.module.scss";
import {FlexColumn, FlexRowCenter} from "../pageArea";

interface IDefaultInput extends InputHTMLAttributes<HTMLInputElement> {
  lable?: string;
  error?: any | undefined;
  marginBottom?: number;
  icon?: string;
  iconClick?: () => void;
  inputElement: any;
  titleClassName? : string;
}
export default function DefaultInput(props: IDefaultInput) {
  return (
    <FlexColumn gap={3}
      style={{
        marginBottom: props.marginBottom ? props.marginBottom + "px" : "24px",
      }}
    >
      {props.lable && (<div className={props.titleClassName ? props.titleClassName : styles.txt14_500_9B9B9B}>{props.lable}</div>)}

      <FlexRowCenter>
        {props.inputElement}
        {props.icon && (
          <img
            src={props.icon}
            width={28}
            height={28}
            alt={"eye"}
            style={{ marginLeft: "-28px" }}
            onClick={() => {
              if (props.iconClick) {
                props.iconClick();
              }
            }}
          />
        )}
      </FlexRowCenter>
      {props.error && (
        <div
          className={styles.txt13_red}
          style={{ marginTop: "4px", marginBottom: "4px" }}
        >
          {props.error?.message}
        </div>
      )}
    </FlexColumn>
  );
}
