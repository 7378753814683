import React, { useEffect, useState } from "react";
import styled from "styled-components";
import styles from "../../assets/styles/uni.module.scss";
import {FlexColumn} from "./pageArea";

interface IEmptyProps {
    titleText?: string;
    titleImage?: string;
    inputElement?: any;
}
export default function EmptyContent(props: IEmptyProps) {
    return (
        <FlexColumn className={styles.white_in_btn} style={{alignItems:'center', borderRadius:'12px', paddingTop:'64px'}}>
            {props.titleImage && (
                <div><img src={props.titleImage} height={100}/></div>
            )}
            <div className={styles.txt12_828282} style={{paddingTop:'24px'}}>{props.titleText}</div>
            {props.inputElement}
        </FlexColumn>
    )
}