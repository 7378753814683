import DefaultInput from "../common/input/DefaultInput";
import styles from "../../assets/styles/uni.module.scss";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import PageTitle, {
    FlexBottom,
    FlexColumEnd,
    FlexColumn, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen, PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../../components/common/pageArea";
import BottomButton from "../common/button/BottomButton";
import {Toast} from "../common/popup/ToastAlert";
import {Trans, useTranslation} from "react-i18next";
import ProfileService from "../../services/profile.service";
import CopyToClipboard from "react-copy-to-clipboard";


export default function SecretKey(props:{setShow:any;}){
    const { t } = useTranslation();
    const [passCheck, setPassCheck] = useState(false);
    const [secretKey, setSecretKey] = useState('');
    const {
        register,
        formState: { errors, isDirty, isValid },
        setError,
        clearErrors,
        handleSubmit
    } = useForm();
    const onSubmit = (data: any) => {
        ProfileService.getPrivateKey(data.curUsrPw).then(res=>{
            if(res.resultCode === "SUCCESS"){
                setPassCheck(true);
                setSecretKey(res.data);
            }
        })
    }
    useEffect(()=>{
        setPassCheck(false);
        setSecretKey('');
    },[])
    return (
        <>
            <FlexColumn gap={20}>
                {
                    passCheck ? (
                        <FlexColumn gap={20}>
                            <div className={styles.txt20_500}>
                                <Trans t={t} i18nKey={"개인키를 통해<br/>부스트 지갑과 메타마스크등<br/>다양한 개인지갑과 연동이 가능 합니다."} />
                            </div>

                            <FlexColumn className={styles.white_in_btn} style={{borderRadius:'12px'}} gap={5}>
                                <FlexRowStart className={styles.txt16_500} gap={5}><img src={require('../../assets/images/setting/secret_key.png')} width={28}/>{t("개인키 입니다.(클릭하여 복사)")}</FlexRowStart>

                                    <CopyToClipboard text={secretKey} onCopy={() => Toast(t("복사 하였습니다."))}>
                                        <div className={styles.txt14_828282} style={{wordBreak:'break-all'}}>{secretKey}</div>
                                    </CopyToClipboard>

                            </FlexColumn>
                        </FlexColumn>
                    ) : (
                        <>
                            <div className={styles.txt20_500}>
                                {t("지갑 패스워드를 입력해 주세요.")}
                            </div>
                            <DefaultInput
                                error={errors.curUsrPw}
                                inputElement={
                                    <input
                                        type={"password"}
                                        className={styles.inputText_Bottom}
                                        placeholder={t("패스워드를 입력해주세요.")}
                                        {...register("curUsrPw", {
                                            required: t("패스워드를 입력해주세요."),
                                            pattern: {
                                                value: /^(?=.*).{4,30}$/i,
                                                message: t("패스워드를 4자리 이상 입력해 주세요."),
                                            }
                                        })}
                                    />
                                }
                            />
                        </>
                    )
                }

                <FlexColumn className={styles.white_in_btn} style={{borderRadius:'12px'}} gap={5}>
                    <FlexRowStart className={styles.txt16_500} gap={5}><img src={require('../../assets/images/setting/red_mark.png')} width={28}/>{t("절대주의")}</FlexRowStart>
                    <div className={styles.txt14_828282}>{t("개인키를 절대 노출하지 마십시오. 개인키가 노출되면 누구나 당신의 계정에서 자산을 빼갈 수 있습니다.")}</div>
                </FlexColumn>
            </FlexColumn>
            <FlexBottom>
                <FlexColumn>
                    {
                        passCheck ? (
                            <BottomButton
                                isActive={true}
                                backgroundColor={"#FFDD00"}
                                textColor={"#000000"}
                                // border={"solid 1px #dbdee6"}
                                onClick={()=>{props.setShow(false)}}
                                text={t("설정 바로가기")}
                            />
                        ) : (
                            <BottomButton
                                isActive={true}
                                backgroundColor={"#FFDD00"}
                                textColor={"#000000"}
                                // border={"solid 1px #dbdee6"}
                                onClick={handleSubmit(onSubmit)}
                                text={t("개인키 보기")}
                            />
                        )
                    }
                </FlexColumn>
            </FlexBottom>
        </>
    )
}