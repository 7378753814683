import PageTitle, {
    Bar,
    FlexColumEnd,
    FlexColumn, FlexColumnCenter, FlexRowAround, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart,
    PageContent,
    PageRootContainer
} from "../../components/common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Footer from "../../components/layer/footer";
import React, {useEffect, useState} from "react";
import ProductService from "../../services/product.service";
import ProgressBar from "../../components/common/ProgressBar";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import SliderComponent from "../../components/common/SlideInputBar";
import bigDecimal from "js-big-decimal";
import {decimalNoFormat} from "../../helpers/utilities";
import DefaultInput from "../../components/common/input/DefaultInput";
import {useForm} from "react-hook-form";
import BottomButton from "../../components/common/button/BottomButton";
import WalletService from "../../services/wallet.service";
import StakingService from "../../services/staking.service";
import {IStakingList} from "./stakingPage";
import StakingPopup from "./stakingPopup";
import StakingOkPopup from "./stakingOkPopup";
import {getValue} from "@testing-library/user-event/dist/utils";

export interface IProductDepth {
    setPayDepth : any;
    prdCode?:string;
    setNftBuyData?:any;
    nftBuyData?:any;
}

export default function StakingView(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();

    const [usrBalance, setUsrBalance] = useState(0);

    const [range, setRange ]= useState(0);
    const [btnActive, setBtnActive] = useState(false);
    const [showPop, setShowPop] = useState(false);
    const [popupData, setPopupData] = useState<IStakingList>()
    const {
        register,
        formState: { errors, isDirty, isValid },
        setError,
        getValue,
        setValue,
        clearErrors,
        handleSubmit
    } = useForm();
    const onSubmit = (data: any) => {
        if(btnActive){
            if (new bigDecimal(data.vlm).compareTo(new bigDecimal(0)) <= 0) {
                setError("vlm", {
                    type: "custom",
                    message: "수량을 입력해 주세요.",
                });
                setBtnActive(false);
            } else if (new bigDecimal(usrBalance).compareTo(new bigDecimal(data.vlm)) < 0) {
                setError("vlm", {
                    type: "custom",
                    message: "잔고가 부족 합니다.",
                });
                setBtnActive(false);
            }else if (new bigDecimal(data.vlm).compareTo(new bigDecimal(5000)) < 0) {
                setError("vlm", {
                    type: "custom",
                    message: "5,000개 이상부터 스테이킹이 가능 합니다.",
                });
                setBtnActive(false);
            } else {
                StakingService.buyStaking(data.vlm, 'DRM').then((res)=>{
                    console.log("res", res)
                    if(res.data){
                        console.log(res.data);
                        setPopupData(res.data);
                        setShowPop(true);
                    }
                })
            }
        }
    }
    useEffect(()=>{
        WalletService.getCoint("DRM").then(async res => {
            setUsrBalance(res.data.usrBalance);
        });
    },[]);


    useEffect(() => {
        clearErrors("vlm");
        let  vlm = new bigDecimal("0");
        try {
            vlm = new bigDecimal(range);
        } catch (e){
            vlm = new bigDecimal("0");
        }
        console.info("vlmvlm", vlm.getValue());
        console.info("lm.compareTo(new bigDecimal((5000)", vlm.compareTo(new bigDecimal((5000))));
        if (vlm.compareTo(new bigDecimal(0)) <= 0) {
            setError("vlm", {
                type: "custom",
                message: "수량을 입력해 주세요.",
            });
            setBtnActive(false);
        } else if (vlm.compareTo(new bigDecimal((5000))) < 0) {
            setError("vlm", {
                type: "custom",
                message: "5,000개 이상부터 스테이킹이 가능 합니다.",
            });
            setBtnActive(false);
        } else if (new bigDecimal(usrBalance).compareTo(vlm) >= 0) {
            clearErrors("vlm");
            setBtnActive(true);
            setValue('vlm', decimalNoFormat(vlm.getValue(), 6));
        } else {
            setError("vlm", {
                type: "custom",
                message: "잔고가 부족 합니다.",
            });
            setBtnActive(false);
        }
    }, [range]);
    return(
        <PageRootContainer>
            <StakingOkPopup show={showPop} setShow={setShowPop} popupData={popupData}/>
            <PageTitle
                textTitle={t('스마트 스테이킹')}
                onClickLeft={()=>{
                    navigate(-1)
                }}
            />
            <PageContent>
                <FlexColumn gap={25}>
                    <FlexColumn gap={15}>
                        <div className={styles.gray_line} style={{width:'calc(100% + 40px)', marginLeft:'-20px'}} />
                        <FlexRowSpace style={{padding:'20px 20px'}} gap={10}>

                            <img src={require('../../assets/images/staking/list_banner.png')} width={'100%'} style={{borderRadius:'12px'}}/>
                            {/*<FlexColumn gap={5}>*/}
                            {/*    <div className={styles.txt14_0F8CFF}>{t('Maked by JVC')}</div>*/}
                            {/*    <div className={styles.txt18_500}>{t('JVC 스마트')}</div>*/}
                            {/*    <div className={styles.txt14_500}>{t('staking')}</div>*/}
                            {/*</FlexColumn>*/}
                        </FlexRowSpace>
                        <Bar/>
                    </FlexColumn>
                    <FlexColumn gap={10}>
                        <div className={styles.txt19_700_424242}>
                            스테이킹 참여하기 <br/>(최소 5,000 DRM부터 참여가 가능합니다.)
                        </div>
                        <FlexColumn className={styles.white_in_btn} style={{borderRadius:'12px'}} gap={20}>
                            <FlexColumn gap={5}>
                                <SliderComponent
                                    max={usrBalance}
                                    min={0}
                                    step={1}
                                    value={range}
                                    onChange={(value)=>{setRange(value); }}
                                />
                                <FlexRowSpace className={styles.txt14_696969}>
                                    <FlexRowStart>0 DRM</FlexRowStart>
                                    <FlexRowEnd>{usrBalance} DRM</FlexRowEnd>
                                </FlexRowSpace>
                            </FlexColumn>

                            <FlexRowSpace className={styles.gray_in_box3} style={{alignItems:'flex-start'}} gap={10}>
                                <FlexRowStart style={{width:'80%'}}>
                                    <FlexColumn>
                                        <FlexColumn  className={styles.txt13_696969}>참여수량</FlexColumn>
                                        <FlexColumnCenter>
                                            <DefaultInput
                                                style={{width:'100px'}}
                                                marginBottom={5}
                                                inputElement={
                                                <>
                                                    <input
                                                        type={"number"}
                                                        className={styles.inputText_None}
                                                        placeholder={t("출금 수량을 입력해주세요.")}
                                                        value={range}
                                                        {...register("vlm", {
                                                            required: t("출금 수량을 입력해주세요.")
                                                            ,onChange: (el) => {
                                                                setRange(el.target.value);
                                                            }
                                                        })}
                                                    />
                                                    <div className={styles.txt22_700}>DRM</div>
                                                </>
                                                }
                                            />
                                        </FlexColumnCenter>
                                    </FlexColumn>
                                </FlexRowStart>
                                <FlexRowEnd style={{width:'30%'}}>
                                    <FlexColumnCenter className={styles.blue_in_btn} style={{borderRadius:'12px', fontSize:'15px', padding:'12px 16px',  width:'54px', height:'36px'}}
                                                      onClick={() => { clearErrors("vlm"); setBtnActive(true); setRange(usrBalance) }} >{t("MAX")}</FlexColumnCenter>
                                </FlexRowEnd>
                            </FlexRowSpace>
                            {
                                errors?.vlm && (
                                    <div className={styles.txt13_red} style={{ marginTop: "4px", marginBottom: "4px" }}>
                                        {errors?.vlm?.message?.toString()}
                                    </div>
                                )
                            }
                            <FlexRowAround gap={20}>
                                <FlexColumnCenter className={styles.blue_in_btn} style={{borderRadius:'16px', width:'70%', fontSize:'15px', fontWeight:'700', backgroundColor: btnActive ? '#317FF4' : '#E9E8E9'}} onClick={handleSubmit(onSubmit)}>{t('참여하기')}</FlexColumnCenter>
                                <FlexColumnCenter className={styles.gray_in_btn3} style={{borderRadius:'16px', width:'30%', fontSize:'15px', fontWeight:'700'}} onClick={()=>{setRange(0); setBtnActive(false)}}>{t('초기화')}</FlexColumnCenter>
                            </FlexRowAround>
                        </FlexColumn>
                        <FlexColumn className={styles.white_in_btn} style={{borderRadius:'12px',border:'1px solid #9B9B9B'}} gap={10}>
                            <div className={styles.txt16_700}>
                                주의사항
                            </div>
                            <div className={styles.txt16_500_374151} style={{lineHeight:'30px'}}>
                                스테이킹 참여시 지급되는 매칭 보너스는<br/>
                                스테이킹 참여 후 <span className={styles.txt16_700} style={{textDecorationLine:'underline'}}>48시간 이후에 자동 지급</span> 됩니다.
                            </div>
                        </FlexColumn>
                    </FlexColumn>
                </FlexColumn>
            </PageContent>
        </PageRootContainer>

    )
}