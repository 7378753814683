import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store/store';
import axios, {AxiosError} from "axios";
import {MyAssetDto, RefState, RoundResDto} from "ProfileModels";
import profileService from '../../services/profile.service';
import {authSlice, loginAsync, setAsyncError} from "./authSlice";


const initialState: RefState = {
    refList : [{
        id:'',
        parent:'',
        usrId:'',
        usrNm:'',
        shortNm:'',
        grdCd:'',
        stkVlm: 0,
        depth: 0
    }],
    refCnt : 0,
    miningCnt : 0,
}
export const firstCntAsync = createAsyncThunk(
    "profile/firstCnt",
    async (_, thunkApi) => {
        try {
            const response = await profileService.getFirstCnt();
            if (response) {
                return response.data;
            }
        } catch (_error) {
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setAsyncError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setAsyncError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

// export const miningFriendAsync = createAsyncThunk(
//     "profile/miningFriend",
//     async (_, thunkApi) => {
//         try {
//             const response = await profileService.getMiningFriendAsync();
//             if (response) {
//                 return response.data;
//             }
//         } catch (_error) {
//             const error = _error as Error | AxiosError;
//             if (axios.isAxiosError(error)) {
//                 thunkApi.dispatch(setAsyncError(error.response?.data.message));
//                 return thunkApi.rejectWithValue(error.response?.data.message);
//             }
//             thunkApi.dispatch(setAsyncError(error.message));
//             return thunkApi.rejectWithValue(error.message);
//         }
//     }
// );
export const getRefOrgAsync= createAsyncThunk(
    "profile/getRefOrg",
    async (_, thunkApi) => {
        try {
            const response = await profileService.getRefOrg();
            if (response) {
                return response.data;
            }
        } catch (_error) {
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setAsyncError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setAsyncError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(firstCntAsync.fulfilled, (state, {payload}) => {
                state.refCnt = payload;
            })
            .addCase(getRefOrgAsync.fulfilled, (state, {payload}) => {
                state.refList = payload;
            })
    }
});
export const {  } = profileSlice.actions;

export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
