import http from './http';
import TokenService from './token.service';
import {IWalletSetup} from "../components/coin/walletPopup";
import {ISwap} from "../components/my/swapView";
///////////////// ajeitar /////////
class profileService {
    async getSummaryCoinPrice(){
        return http
            .get('/user/wallet/summaryCoinPrice' )
            .then((response) => {
                return response.data;
            });
    }

    async selectCoin(){
        return http
            .get('/user/wallet/selectCoin' )
            .then((response) => {
                return response.data;
            });
    }

    async getCoint(crncyCd:string){
        return http
            .get('/user/wallet/getCoin/'+crncyCd )
            .then((response) => {
                return response.data;
            });
    }

    async checkAddr(crncyCd:string,addr:string){
        return http
            .get('/user/wallet/checkAddr/'+crncyCd+'/'+addr )
            .then((response) => {
                return response.data;
            });
    }

    async getAddrByUsrId(usrId:string,crncyCd:string){
        return http
            .get('/user/wallet/getAddrByUsrId/'+crncyCd+'/'+usrId )
            .then((response) => {
                return response.data;
            });
    }
    async setWithdraw(data:IWalletSetup){
        return http
            .post('/user/wallet/withdraw', data)
            .then((response) => {
                return response.data;
            });
    }

    async getWithdraw(inTxid:string){
        return http
            .get('/user/wallet/getWithdraw/'+inTxid, )
            .then((response) => {
                return response.data;
            });
    }

    async getSelectWalletHis(crncyCd:string, currentPage?:number, blockSize?:number, paging?:boolean){
        return http
            .get('/user/wallet/selectWalletHis/', {
                params:{
                    crncyCd:crncyCd,
                    currentPage:currentPage,
                    blockSize:blockSize,
                    paging:paging
                }
            })
            .then((response) => {
                return response.data;
            });
    }

    async selectSwap(){
        return http
            .get('/anony/selectSwap')
            .then((response) => {
                return response.data;
            });
    }
    async getSwapRt(fromCrncyCd:string,toCrncyCd:string){
        return http
            .get('/swap/getSwapRt/'+fromCrncyCd+'/'+toCrncyCd)
            .then((response) => {
                return response.data;
            });
    }

    async swap(data:ISwap){
        return http
            .post('/user/wallet/swap', data)
            .then((response) => {
                return response.data;
            });
    }




}

export default new profileService();