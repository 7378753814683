import PageTitle, {
    FlexBottom,
    FlexBottomFree,
    FlexColumn,
    FlexColumnCenter,
    FlexRowSpace, FlexRowStart, PageContent,
    PageRootContainer
} from "../../components/common/pageArea";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {agreeTermsAsync, checkRefCd, findPwAsync, loginAsync, selectAuth} from "../../store/modules/authSlice";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import DefaultInput from "../../components/common/input/DefaultInput";
import styles from "../../assets/styles/uni.module.scss";
import BottomButton from "../../components/common/button/BottomButton";
import {Toast} from "../../components/common/popup/ToastAlert";

export default function EmailAuth(){
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit
    } = useForm();

    const onSubmit = async (data:any): Promise<void> => {
        const {usrId} = data;
        dispatch(findPwAsync({ usrId}))
            .unwrap()
            .then(() => {
                Toast(t('이메일이 발송되었습니다.'));
                navigate('/');
            })
            .catch(() => {
            });
    };
    return (
        <PageRootContainer>
            <PageTitle textTitle={t('패스워드 분실')} onClickLeft={()=>{navigate(-1)}} />
            <PageContent>
                <FlexColumn gap={24}>
                        <DefaultInput
                            lable={t("아이디")}
                            error={errors.usrId}
                            inputElement={
                                <input
                                    type={"text"}
                                    className={styles.inputText_44}
                                    placeholder={t("가입하신 아이디를 입력해주세요.")}
                                    {...register("usrId", {
                                        required: t("가입하신 아이디를 입력해주세요.")
                                    })}
                                />
                            }
                        />
                        <FlexColumn className={styles.white_in_btn} style={{borderRadius:'12px'}} gap={4}>
                            <FlexRowStart className={styles.txt16_500_828282} gap={5}>
                                <img src={require('../../assets/images/home/mark_blue.png')} width={20} />
                                <div>{t('임시 패스워드를 발급해 드립니다.')}</div>
                            </FlexRowStart>
                            <div className={styles.txt14_500_828282}>{t('가입시 작성한 아이디로 패스워드를 임시 패스워드를 발급해 드립니다. 로그인 후 설정 페이지에서 원하시는 패스워드로 변경해 주세요.')}</div>
                        </FlexColumn>
                    </FlexColumn>
                </PageContent>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#0F8CFF"}
                        textColor={"#FFFFFF"}
                        // border={"solid 1px #dbdee6"}
                        onClick={
                            handleSubmit(onSubmit)
                        }
                        text={t("임시패스워드 발급")}
                    />
                </FlexColumn>
            </FlexBottom>
        </PageRootContainer>
    )
}