import moment from "moment";
import React, {useEffect, useState} from "react";

import PageTitle, {
    FlexColumEnd,
    FlexColumn, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen, PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../../components/common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import EmptyContent from "../../components/common/EmptyContent";
import {decimalFormat, ellipseAddress, maskingEmail} from "../../helpers/utilities";
import AuthService from "../../services/auth.service";
import ProfileService from "../../services/profile.service";
import {WalletHis} from "../coin/coinModule";
import {useInView} from "react-intersection-observer";
import {useTranslation} from "react-i18next";

interface IRefPopup {
    show: boolean;
    setShow: any;
    pageView:any;
}
interface IFriendResDto{
    usrId:string;
    usrNm:string;
    hrvstVlm:string;
    regDtm:string;
}
export default function RefPopup_old(props:IRefPopup) {
    const { t } = useTranslation();
    const [dataList, setDataList]= useState<Array<IFriendResDto>>([]);
    const [currPage, setCurrPage] = useState(1);
    const [blockSize] = useState(10);
    const [searchTxt, setSearchTxt] = useState('');
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [tmpView, setTmpView] = useState(props.pageView)
    const fetchData = async() =>{
        let response;
        if(props.pageView === 'ref')
        {
            response = await ProfileService.seelctFriend(currPage, blockSize, true);
        }else{
            response = await ProfileService.seelctMiningFriend(currPage, blockSize, true);
        }

        setDataList([...dataList, ...response.data]);
        if (!response.data.length) {
            setLastList(true);
            return;
        }
    }
    useEffect(() => {

        if (!lastList && props.show) {
            fetchData();
        }
    },[currPage, searchTxt, props.show]);
    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    useEffect(()=>{
        if(tmpView !== props.pageView){
            setLastList(false);
            setCurrPage(1);
            setDataList([]);
            fetchData();
        }
        console.log("props.pageView",props.pageView)
    },[props.pageView])
    return (
        <>
            {
                props.show && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle textTitle={props.pageView==='ref' ? t('추천인내역') : t('채굴중인 추천인내역')} rightSrc={require('../../assets/images/home/close.png')} onClickRight={()=>{props.setShow(false)}}/>
                                <PageContent style={{paddingTop:'47px'}}>
                                    <FlexColumn gap={10}>
                                        {

                                            dataList.length > 0 ? (

                                                props.pageView==='ref' ? (
                                                        dataList.map((item, index) => {
                                                            return (
                                                                <FlexRowSpace key={index} className={styles.white_in_btn} style={{borderRadius:'12px'}}>
                                                                    <FlexRowStart className={styles.txt12_828282} style={{width:'70%'}}>
                                                                        <FlexColumn gap={2}>
                                                                            <div>{item.usrNm}</div>
                                                                            <div style={{color:'#000000'}}>{maskingEmail(item.usrId)}</div>
                                                                        </FlexColumn>
                                                                    </FlexRowStart>
                                                                    <FlexRowEnd className={styles.txt12_828282}>
                                                                        {item.regDtm}
                                                                    </FlexRowEnd>
                                                                </FlexRowSpace>
                                                            )
                                                        })
                                                    ) : (
                                                        dataList.map((item, index) => {
                                                            return (
                                                                <FlexRowSpace key={index} className={styles.white_in_btn} style={{borderRadius:'12px'}}>
                                                                    <FlexRowStart className={styles.txt12_828282} style={{width:'70%'}}>
                                                                        <FlexColumn gap={2}>
                                                                            <div>{t('총 채굴량(BOOST)')}</div>
                                                                            <div style={{color:'#000000'}}>{decimalFormat(item.hrvstVlm, 6)}</div>
                                                                        </FlexColumn>
                                                                    </FlexRowStart>
                                                                    <FlexRowEnd className={styles.txt12_828282}>
                                                                        <FlexColumn gap={2}>
                                                                            <div>{item.usrNm}</div>
                                                                            <div>{item.regDtm}</div>
                                                                        </FlexColumn>
                                                                    </FlexRowEnd>
                                                                </FlexRowSpace>
                                                            )
                                                        })
                                                    )
                                            ) : (
                                                <EmptyContent titleText={t("내역이 없습니다.")} titleImage={require('../../assets/images/list_x.png')}/>
                                            )
                                        }
                                        {
                                            Object.values(dataList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                                        }
                                    </FlexColumn>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
            </>
    );
}
