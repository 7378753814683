import http from './http';
import TokenService from './token.service';
import {IWalletSetup} from "../components/coin/walletPopup";
///////////////// ajeitar /////////
class profileService {


    async getFirstCnt() {
        return http
        .get('/user/getFirstCnt')
        .then((response) => {
            return response.data;
        });
    }
    // async getMiningFriendAsync() {
    //     return http
    //         .get('/user/getMiningFriend')
    //         .then((response) => {
    //             return response.data;
    //         });
    // }
    async checkLoginPw(usrPw:string){
        return http
            .post('/user/checkLoginPw', {
                usrPw
            })
            .then((response) => {
                return response.data;
            });
    }
    async setWithdraw(data:IWalletSetup){
        return http
            .post('/user/wallet/withdraw', data)
            .then((response) => {
                return response.data;
            });
    }

    async seelctFriend(currentPage?:number, blockSize?:number, paging?:boolean){
        return http
            .get('/user/seelctFriend', {
                params:{
                    currentPage:currentPage,
                    blockSize:blockSize,
                    paging:paging
                }
            })
            .then((response) => {
                return response.data;
            });
    }
    async seelctMiningFriend(currentPage?:number, blockSize?:number, paging?:boolean){
        return http
            .get('/user/seelctMiningFriend', {
                params:{
                    currentPage:currentPage,
                    blockSize:blockSize,
                    paging:paging
                }
            })
            .then((response) => {
                return response.data;
            });
    }

    async getWithdraw(inTxid:string){
        return http
            .get('/user/wallet/getWithdraw/'+inTxid, )
            .then((response) => {
                return response.data;
            });
    }


    async getHarvestNft(nftCd:string){
        return http
            .get('/user/wallet/getHarvest/'+nftCd, )
            .then((response) => {
                return response.data;
            });
    }

    async getRefOrg(){
        return http
            .get('/user/getRefOrg')
            .then((response) => {
                return response.data;
            });
    }
    async getBoostRef(){
        return http
            .get('/user/getBoostRef')
            .then((response) => {
                return response.data;
            });
    }
    async selectRefPolicy(){
        return http
            .get('/user/selectRefPolicy')
            .then((response) => {
                return response.data;
            });
    }
    async rcvBoostRef(){
        return http
            .post('/user/rcvBoostRef')
            .then((response) => {

                return response.data;
            });
    }

    /**
     * 추천인 스테이킹 목록
     */
    async dnStakingRef(){
        return http
            .get('/user/dnStakingRef')
            .then((response) => {
                return response.data;
            });
    }


    /**
     * 매칭보너스 설정.
     */
    async getUserBnsConfig(){
        return http
            .get('/user/getUserBnsConfig')
            .then((response) => {
                return response.data;
            });
    }


    /**
     * 매칭보너스 정책.
     */
    async getBonusConfig(){
        return http
            .get('/user/getBonusConfig')
            .then((response) => {
                return response.data;
            });
    }
    async getUserInfoRealtime(){
        return http
            .get('/user/getUserInfoRealtime')
            .then((response) => {
                return response.data;
            });
    }
}

export default new profileService();