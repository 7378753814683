import styles from "../../assets/styles/uni.module.scss";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    FlexColumEnd,
    FlexColumn,
    FlexColumnCenter,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../../components/common/pageArea";
import {useAppSelector} from "../../store/hooks";
import {selectAuth} from "../../store/modules/authSlice";

interface IMenuProps {
    open: boolean;
    setOpen: any;
}

export default function BurgerMenu(props: IMenuProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {isLoggedIn, user, asyncError}  = useAppSelector(selectAuth);

    const { open, setOpen } = props;

    useEffect(() => {
        if(open){
            document.body.style.overflow= "hidden";
            window.scrollTo({top:0, left:0, behavior:'auto'});
        }else{
            document.body.style.overflow= "auto";
        }
        return () =>{document.body.style.overflow= "auto";}
    }, [open])

    return (
        <>
            <div
                onClick={()=>{setOpen(!open)}}
                className={styles.overlay}
                style={{ display:open ? 'block' :'none' }}
            />

            <div style={{position:'relative', width:'100%'}}>
                <FlexColumn className={styles.menu} style={{padding:'24px 20px', display:open ? 'block' :'none', transform:open ? 'translateX(0)' :'translateX(-10%)'}} >
                    <FlexRowSpace style={{ width:'100%', paddingBottom:'48px'}}>
                        <FlexRowStart><img src={require('../../assets/images/home/logo.png')} width={112} /></FlexRowStart>
                        <FlexRowEnd><img src={require('../../assets/images/home/close.png')} width={14} onClick={()=>{setOpen(false)}} /></FlexRowEnd>
                    </FlexRowSpace>
                    <FlexColumn gap={22}>
                        {
                            isLoggedIn === true ? (
                                <FlexColumn className={styles.txt18_700}>
                                    <div>{user.nickNm}{t('님 안녕하세요')}</div>
                                    <div className={styles.txt16_500}>{t('K-DREAM의 다양한 서비스를 경험해 보세요.')}</div>
                                </FlexColumn>
                            ) : (
                                <FlexRowCenter gap={12}>
                                    <FlexColumnCenter className={styles.blue_in_btn} style={{borderRadius:'10px', width:'50%'}} onClick={()=>{navigate('/join')}}>{t('회원가입')}</FlexColumnCenter>
                                    <FlexColumnCenter className={styles.black_in_btn} style={{borderRadius:'10px', width:'50%'}} onClick={()=>{navigate('/login')}}>{t('로그인')}</FlexColumnCenter>
                                </FlexRowCenter>
                            )
                        }
                        <div className={styles.gray_line} />
                        <FlexColumn gap={28} className={styles.txt18_500}>
                            <FlexColumEnd onClick={()=>{navigate('/');}}>{t("K-DREAM 소개")}</FlexColumEnd>
                            <FlexColumEnd onClick={()=>{navigate('/my');setOpen(!open)}}>{t("자산관리")}</FlexColumEnd>
                            <FlexColumEnd onClick={()=>{navigate('/staking');setOpen(!open)}}>{t("스테이킹")}</FlexColumEnd>
                            <FlexColumEnd onClick={()=>{navigate('/setting');}}>{t("설정")}</FlexColumEnd>
                            <FlexColumEnd onClick={()=>{navigate('/boost');}}>{t("자산운영 현황")}</FlexColumEnd>
                            {/*<FlexRowEnd gap={4}><div className={styles.black_in_box}>{t("준비중")}</div><div style={{color:'##828282'}}>{t("DRM 거래")}</div></FlexRowEnd>*/}
                            {/*<FlexRowEnd gap={4}><div className={styles.black_in_box}>{t("준비중")}</div><div style={{color:'##828282'}}>{t("DRM 담보대출")}</div></FlexRowEnd>*/}
                        </FlexColumn>
                    </FlexColumn>

                </FlexColumn>
            </div>
        </>
    );
}