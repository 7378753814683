import styles from "../../assets/styles/uni.module.scss";
import DefaultInput from "../common/input/DefaultInput";
import {
    Bar,
    FlexBottom,
    FlexColumn,
    FlexColumnCenter, FlexRowAround,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/pageArea";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import BottomButton from "../common/button/BottomButton";
import {Trans, useTranslation} from "react-i18next";
import AuthService from "../../services/auth.service";
import {IWithdrawDepth} from "./withdrawDepth1";
import {decimalFormat, ellipseAddress, getOnchainBalance} from "../../helpers/utilities";
import {useAppSelector} from "../../store/hooks";
import {selectAuth} from "../../store/modules/authSlice";
import bigDecimal from "js-big-decimal";
import {IWalletSetup} from "./walletPopup";
import {Toast} from "../common/popup/ToastAlert";
import CopyToClipboard from "react-copy-to-clipboard";
import {useNavigate} from "react-router-dom";
import ProfileService from "../../services/profile.service";

interface IWithdrawDepth4{
    crncyCd:string;
    exTxid:string;
    formatDate:string;
    inTxid:string;
    regDtm:string;
    stusCd:string;
    stusCdNm:string;
    tpCd:string;
    tpCdNm:string;
    txTpNm:string;
    vlm:string;
    dpstAddr:string;
}
export default function WithdrawDepth4(props: { inTxId:string, coinView:any }){
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [resultData, setResultData] = useState<IWithdrawDepth4>({
        crncyCd:'',
        exTxid:'',
        formatDate:'',
        inTxid:'',
        regDtm:'',
        stusCd:'',
        stusCdNm:'',
        tpCd:'',
        tpCdNm:'',
        txTpNm:'',
        vlm:'',
        dpstAddr:'',
    })
    useEffect(()=>{
        if(props.inTxId){
            ProfileService.getWithdraw(props.inTxId).then(res=>{
                setResultData(res.data);
            })
        }
    },[props.inTxId])
    return (
        <>
            <FlexColumn gap={30}>
                <FlexColumnCenter className={styles.txt20_500} gap={15}>
                    <img src={props.coinView.imgPath} width={60}/>
                    <div><Trans t={t} i18nKey={"{0}로<br/>출금을 신청 하였습니다."} values={{0:ellipseAddress(resultData.inTxid,5)}}/> </div>
                </FlexColumnCenter>
                <FlexColumn gap={15} className={styles.txt14_500}>
                    <FlexRowSpace>
                        <FlexRowStart className={styles.txt16_500_828282} style={{width:'70%'}}>{t("메인넷")}</FlexRowStart>
                        <FlexRowEnd  style={{width:'30%'}}>{props.coinView.mainnetCd}</FlexRowEnd>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <FlexRowStart className={styles.txt16_500_828282} style={{width:'70%'}}>{t("출금수량")}</FlexRowStart>
                        <FlexRowEnd style={{width:'30%'}} gap={5}>{decimalFormat(resultData.vlm, 6)}{resultData.crncyCd}</FlexRowEnd>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <FlexRowStart className={styles.txt16_500_828282} style={{width:'70%'}}>{t("받을주소")}</FlexRowStart>
                        <FlexRowEnd  style={{width:'30%'}}>{ellipseAddress(resultData.dpstAddr,6)}
                            <CopyToClipboard text={resultData.dpstAddr}
                                             onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                                <img src={require('../../assets/images/copy.png')} width={24} />
                            </CopyToClipboard>
                        </FlexRowEnd>
                    </FlexRowSpace>
                </FlexColumn>
            </FlexColumn>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#317FF4"}
                        textColor={"#FFFFFF"}
                        // border={"solid 1px #dbdee6"}
                        onClick={()=>{document.location.reload()}}
                        text={t("내역 바로가기")}
                    />
                </FlexColumn>
            </FlexBottom>
        </>
    )
}