import {useAppDispatch, useAppSelector} from "../store/hooks";
import React, {useCallback, useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import PrivateRoute from "../components/common/privateRoute";
import styled from "styled-components";
import ScrollToTop from "../helpers/ScrollTop";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import Loading from "../components/common/Loading";
import {ToastError} from "../components/common/popup/ToastAlert";
import {useTranslation} from "react-i18next";
import {selectAuth, setAsyncError} from "../store/modules/authSlice";
import Home from "./home/home";
import Join from "./login/join";
import Welcome from "./login/welcome";
import Login from "./login/login";
import Setting from "./setting/setting";
import RefPage from "./ref/RefPage";
import RefMining from "./mining/refMining";
import My from "./my/my";
import CoinDetail from "./coin/coinDetail";
import EmailAuth from "./login/emailAuth";
import Boost from "./boost/boost";
import StakingPage from "./staking/stakingPage";
import StakingView from "./staking/stakingView";

const ContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
`;
const BodyWrapper = styled.div`
  box-sizing: content-box;
  min-width: 360px;
  max-width: 412px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #F8F8F8;
  font-size:18px;
  //padding: 0px 20px 0px 20px;
`;

const tagManagerArgs = {
    gtmId: "G-5PQ7KDS6S6",
};

const AppRouter = () => {
    const { t } = useTranslation();
    const { search } = useLocation();

    const {refCode} = queryString.parse(search);
    const {isLoggedIn, user, asyncError}  = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const getInitInfo = useCallback(async () => {
        console.log("user.usrUid",user.usrUid)
        if(typeof user.usrUid == "undefined" || user.usrUid == null || user.usrUid == ""){
        }else{
            console.log("user",user);
            await Promise.all([

            ]);
        }
    }, [dispatch, user]);

    useEffect(() => {
        getInitInfo();
    }, [getInitInfo]);
    useEffect(()=>{
        if (typeof refCode === "string") {
            localStorage.setItem("refCode", refCode);
        }
    },[refCode]);
    useEffect(()=>{
        if(asyncError){
            ToastError(t(asyncError));
            dispatch(setAsyncError(''));
        }
    },[asyncError]);
    return (
        <>
        <ContentsWrapper>
            <ScrollToTop />
            <BodyWrapper>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/main" element={<PrivateRoute><My /></PrivateRoute>} />
                    <Route path="/join" element={<Join />} />
                    <Route path="/emailAuth" element={<EmailAuth />} />
                    <Route path="/welcome" element={<Welcome />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/boost" element={<Boost />} />
                    <Route path="/setting" element={<PrivateRoute><Setting /></PrivateRoute>} />
                    <Route path="/staking" element={<PrivateRoute><StakingPage /></PrivateRoute>} />
                    <Route path="/ref" element={<PrivateRoute><RefPage /></PrivateRoute>} />
                    <Route path="/refMining" element={<PrivateRoute><RefMining /></PrivateRoute>} />
                    <Route path="/my" element={<PrivateRoute><My /></PrivateRoute>} />
                    <Route path="/staking/:prdCode" element={<StakingView />} />
                    <Route path="/coin/:coinCode" element={<PrivateRoute><CoinDetail /></PrivateRoute>} />
                </Routes>
                <ToastContainer toastStyle={{backgroundColor:'#9B9B9B'}}/>
            </BodyWrapper>
        </ContentsWrapper>
        <Loading />
        </>
    );
}
export default AppRouter;
