import React from "react";
import styled from "styled-components";

export const SocialButton = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  border-radius: 8px;
`;

export const ZButton = styled.div<IBtnStyle>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 14px;
  gap: 10px;

  flex: none;
  order: 1;
  flex-grow: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.6px;
  
  ${(props) => props.border ? props.border : ""};
  width: ${(props) => props.width+"px"};
  height: ${(props) => props.height+"px"};
  background: ${(props) => props.background};
  border-radius: ${(props) => props.radius ? props.radius + "px" : "100px"};
  color: ${(props) => props.txtColor};
`;

export interface IBtnStyle {
  txtColor?: string | null;
  background?: string | null;
  border?: string | null;
  width?: string | null;
  height?: string | null;
  radius?: string | null;
}
export const blackBtn: IBtnStyle = {
  background: "#111111",
  txtColor: "#FFFFFF",
  border: null,
  width: null,
};

export const whiteBtn: IBtnStyle = {
  background: "#FFFFFF",
  txtColor: "#111111",
  border: "border: solid 1px #d4d8e2",
  width: null,
};

export const blueBtn: IBtnStyle = {
  background: "#3A71FF",
  txtColor: "#FFFFFF",
  border: null,
  width: null,
};

export const skyBtn: IBtnStyle = {
  background: "#ebf2ff",
  txtColor: "#3a71ff",
  border: null,
  width: null,
};

interface IButton34 {
  text: string;
  type: "black" | "blue" | "white" | "sky";
  onClick?: () => void;
  width?: string;
  height?: string;
  radius?: string;
}
export default function Button34(props: IButton34) {
  let btnStyle: IBtnStyle = whiteBtn;
  if (props.type === "black") {
    btnStyle = blackBtn;
  } else if (props.type === "blue") {
    btnStyle = blueBtn;
  } else if (props.type === "sky") {
    btnStyle = skyBtn;
  }
  btnStyle = {
    ...btnStyle,
    width: props.width ?? null,
    height: props.height ?? "34",
    radius: props.radius ?? "100",
  };

  return (
    <ZButton
      width={btnStyle.width}
      txtColor={btnStyle.txtColor}
      border={btnStyle.border}
      background={btnStyle.background}
      radius={btnStyle.radius}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.text}
    </ZButton>
  );
}
