import moment from "moment";
import React, {useEffect, useState} from "react";

import PageTitle, {
    FlexBottom,
    FlexColumEnd,
    FlexColumn, FlexColumnCenter, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen, PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../../components/common/pageArea";
import BottomButton from "../../components/common/button/BottomButton";
import NickItem from "../../components/setting/nickItem";
import PassItem from "../../components/setting/passItem";
import EmailItem from "../../components/setting/emailItem";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import SecretKey from "../../components/setting/secretKey";
import {inspect} from "util";
import styles from "../../assets/styles/uni.module.scss";
import {useNavigate} from "react-router-dom";
import {Toast} from "../../components/common/popup/ToastAlert";
import CopyToClipboard from "react-copy-to-clipboard";

export interface ISettingPopup {
    show: boolean;
    setShow: any;
    popupData:any;
}
export default function StakingOkPopup(props:ISettingPopup){
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <>
            {
                props.show && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle textTitle={'스마트 스테이킹 완료'} rightSrc={require('../../assets/images/home/close.png')}
                                           onClickRight={()=>{navigate('/staking')}}/>
                                <PageContent>

                                    <FlexColumn gap={20}>
                                        <div className={styles.gray_line} style={{width:'calc(100% + 40px)', marginLeft:'-20px'}} />
                                        <FlexColumnCenter>
                                            <img src={require('../../assets/images/staking/staking_ok.png')} width={'225px'}/>
                                            <div className={styles.txt40_700}>👏 스마트 스테이킹을 완료 하였습니다.</div>
                                        </FlexColumnCenter>
                                        <div className={styles.gray_line} style={{width:'calc(100% + 40px)', marginLeft:'-20px'}} />
                                        <FlexColumn gap={20}>
                                            <FlexRowSpace>
                                                <FlexRowStart className={styles.txt16_500_828282}>TXID</FlexRowStart>
                                                <FlexRowEnd className={styles.txt14_500_374151}>
                                                    {props.popupData?.wthdrwInTxid}
                                                    <CopyToClipboard text={props.popupData?.wthdrwInTxid}
                                                                     onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                                                        <img src={require('../../assets/images/copy.png')} width={20} />
                                                    </CopyToClipboard>
                                                </FlexRowEnd>
                                            </FlexRowSpace>
                                            <FlexRowSpace>
                                                <FlexRowStart className={styles.txt16_500_828282}>전송수량</FlexRowStart>
                                                <FlexRowEnd className={styles.txt14_500_374151}>{props.popupData?.stkVlm} DRM</FlexRowEnd>
                                            </FlexRowSpace>
                                        </FlexColumn>
                                        <div className={styles.gray_line} style={{width:'calc(100% + 40px)', marginLeft:'-20px'}} />
                                    </FlexColumn>
                                </PageContent>
                                <FlexBottom>
                                    <FlexColumn>
                                        <BottomButton
                                            isActive={true}
                                            backgroundColor={"#317FF4"}
                                            textColor={"#FFFFFF"}
                                            // border={"solid 1px #dbdee6"}
                                            onClick={()=>{navigate("/staking")}}
                                            text={t("스테이킹 바로가기")}
                                        />
                                    </FlexColumn>
                                </FlexBottom>
                            </PageRootContainer>

                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>

    )
}