import DefaultInput from "../common/input/DefaultInput";
import styles from "../../assets/styles/uni.module.scss";
import React from "react";
import {useForm} from "react-hook-form";
import PageTitle, {
    FlexBottom,
    FlexColumEnd,
    FlexColumn, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen, PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../../components/common/pageArea";
import BottomButton from "../common/button/BottomButton";
import {changeNickNameAsync, checkRefCd, selectAuth} from "../../store/modules/authSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Toast} from "../common/popup/ToastAlert";
import {useTranslation} from "react-i18next";

export default function NickItem(props:{setShow:any;}){
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {isLoggedIn, user, asyncError}  = useAppSelector(selectAuth);
    const {
        register,
        formState: { errors, isDirty, isValid },
        setError,
        clearErrors,
        handleSubmit
    } = useForm();
    const onSubmit = (data: any) => {
        console.log(data);
        const {usrNm} = data;

        dispatch(changeNickNameAsync( {usrNm}))
        .unwrap()
        .then(() => {
            Toast(t('변경 되었습니다.'))
            props.setShow(false);
        })
        .catch(() => {
        });

    }
    return (
        <>
            <PageContent style={{paddingTop:'83px'}}>
                <DefaultInput
                    lable={t("변경할 닉네임")}
                    error={errors.usrNm}
                    inputElement={
                        <input
                            type={"text"}
                            className={styles.inputText_Bottom}
                            placeholder={t("변경할 닉네임을 입력해주세요.")}
                            defaultValue={user.nickNm}
                            {...register("usrNm", {
                                required: t("변경할 닉네임을 입력해주세요."),
                            })}
                        />
                    }
                />
            </PageContent>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#FFFFFF"}
                        textColor={"black"}
                        // border={"solid 1px #dbdee6"}
                        onClick={()=>{props.setShow(false);}}
                        text={t("취소하기")}
                    />
                </FlexColumn>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#FFDD00"}
                        textColor={"black"}
                        // border={"solid 1px #dbdee6"}
                        onClick={handleSubmit(onSubmit)}
                        text={t("변경하기")}
                    />
                </FlexColumn>
            </FlexBottom>
        </>
    )
}