import styles from "../../assets/styles/uni.module.scss";
import DefaultInput from "../common/input/DefaultInput";
import {
    Bar, Bar01,
    FlexBottom,
    FlexColumEnd,
    FlexColumn,
    FlexColumnCenter,
    FlexRowAround,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/pageArea";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import BottomButton from "../common/button/BottomButton";
import {Trans, useTranslation} from "react-i18next";
import {IWithdrawDepth} from "./withdrawDepth1";
import bigDecimal from "js-big-decimal";
import {IWalletSetup} from "./walletPopup";
import WalletService from "../../services/wallet.service";
import {
    decimal,
    decimalAdd,
    decimalCompare,
    decimalFormat,
    decimalNoFormat,
    decimalSubtract, ellipseAddress
} from "../../helpers/utilities";
import {ToastError} from "../common/popup/ToastAlert";
import {NetConfirmPopup} from "../common/popup/ConfirmPopup";
import {useNavigate} from "react-router-dom";


export default function WithdrawDepth3(props:IWithdrawDepth){
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [btnActive, setBtnActive] = useState(false);
    const [wthdrwVlm, setWthdrwVlm] = useState('0');
    const [wthdrwFeeVlm, setWthdrwFeeVlm] = useState('0');
    const [feeVlm, setFeeVlm] = useState(0);
    const [remainVlm, setRemainVlm] = useState('0');
    const [totalWthdrwVlm, setTotalWthdrwVlm] = useState('0');
    const [sameFeeCrncyCd, setSameFeeCrncyCd] = useState(true);
    const {
        register,
        formState: { errors, isDirty, isValid },
        setError,
        setValue,
        clearErrors,
        handleSubmit
    } = useForm();
    const onSubmit = (data: any) => {
        if (props.coinView?.inWthdrwYn === 'N') {
            ToastError('해당코인은 출금이 불가능 합니다.');
            return;
        }
        const formData:IWalletSetup = {
            crncyCd: props.walletSetup.crncyCd,
            usrPw: props.walletSetup.usrPw,
            rcvAddr: props.walletSetup.rcvAddr,
            vlm:data.vlm,
            inTxId:''
        }
        WalletService.setWithdraw(formData).then(res=>{
            if(res.data){
                if(res.data.successYn === "Y"){
                    props.setWalletSetup({
                        ...props.walletSetup,
                        inTxId : res.data.inTxid
                    })
                    props.setPageDepth(4);
                }
            }
        })
    }

    let tronBan = () =>{
        return NetConfirmPopup(<FlexColumnCenter><img src={require('../../assets/images/product/WarningCircle.png')} width={56} /></FlexColumnCenter>
            ,<FlexColumnCenter className={styles.txt20_700}><Trans t={t} i18nKey={"TRON 외부 출금은<br/>3월4일 부터 가능 합니다."} /></FlexColumnCenter>
            , ""
            ,{btnTxt:t("닫기"),
                onClick: () => {
                    navigate("/my");
                }}
            , {btnTxt:"",
                onClick: () => {
                    navigate("/my");
                }}
        );
    }

    //수수료 코인과 출금 코인이 다른지 확인.
    useEffect(()=>{
        if (props.coinView.crncyCd !== props.coinView.wthdrwFeeCrncyCd) {
            setSameFeeCrncyCd(false);
            //수수료 코인 잔액 불러온기
            WalletService.getCoint(props.coinView.wthdrwFeeCrncyCd).then(async res => {
                setWthdrwFeeVlm(res.data.usrBalance);
            });
        } else {
            setWthdrwFeeVlm(props.coinView.usrBalance);
        }
        setWthdrwVlm("0");
        if (props.walletSetup.inUser) {
            setFeeVlm(0);
        } else {
            setFeeVlm(props.coinView.wthdrwFeeVlm);
        }

    },[props.coinView])
    useEffect(() => {
        setBtnActive(false);
        //출금요청 수량 : wthdrwVlm
        //수수료 수량 : wthdrwFeeVlm
        //출금 후 잔고 변경 remainVlm
        //총 출금 수량 변경.totalWthdrwVlm

        //props.coinView.usrBalance
        setTotalWthdrwVlm("0");
        //출금 코인과 수수료 코인이 같을 경우

        //출금 제한 체크.
        if(props.coinView.hldLock>0 &&  !props.walletSetup.inUser){
            let vlm = new bigDecimal(props.coinView.usrBalance);
            vlm = vlm.subtract(new bigDecimal(props.coinView.hldLock));
            if (vlm.compareTo(new bigDecimal(wthdrwVlm)) <= 0) {
                setRemainVlm('잔고부족');
                setBtnActive(false);
                return;
            }
        }


        if (sameFeeCrncyCd) {
            let tmpTotal = decimalAdd(wthdrwVlm, feeVlm);
            setTotalWthdrwVlm(tmpTotal);


            let tmpRemainVlm = decimalSubtract(props.coinView.usrBalance, tmpTotal);
            if (decimalCompare(tmpRemainVlm, '0') < 0) {
                setRemainVlm('잔고부족');
            } else {
                setBtnActive(true);
                setRemainVlm(decimalFormat(tmpRemainVlm,6));
            }
        } else {
            //출금 코인과 수수료 코인이 다를 경우.
            let tmpTotal = wthdrwVlm;
            if (wthdrwVlm === '0') {
                setBtnActive(false);
                return;
            }
            setTotalWthdrwVlm(tmpTotal);
            let tmpRemainVlm = decimalSubtract(props.coinView.usrBalance, tmpTotal);
            if (decimalCompare(tmpRemainVlm, '0') < 0) {
                setRemainVlm('잔고부족');
            } else {
                setBtnActive(true);
                setRemainVlm(decimalFormat(tmpRemainVlm,6));
            }
        }

    }, [wthdrwVlm]);

    return (
        <>
            <FlexColumn gap={20}>
                <div className={styles.txt20_500}>
                    <img src={props.coinView.imgPath} width={30}/> {t("출금 수량 입력")}
                </div>
                <FlexRowSpace>
                    <FlexRowStart style={{width: '80%'}}>
                        <DefaultInput
                            error={errors.vlm}
                            inputElement={
                                <input
                                    type={"number"}
                                    className={styles.inputText_Bottom}
                                    placeholder={t("출금 수량을 입력해주세요.")}
                                    {...register("vlm", {
                                        required: t("출금 수량을 입력해주세요.")
                                        ,onChange: (el) => {
                                            let vlm = new bigDecimal(props.coinView.usrBalance);
                                            let inputVlm = new bigDecimal(0);
                                            try {
                                                inputVlm = new bigDecimal(el.target.value);
                                            }catch (e){
                                                inputVlm = new bigDecimal(0);
                                            }
                                            if(props.coinView.hldLock>0 &&  !props.walletSetup.inUser){
                                                vlm = vlm.subtract(new bigDecimal(props.coinView.hldLock));
                                            }
                                            if (new bigDecimal(el.target.value).compareTo(new bigDecimal(0)) <= 0) {
                                                setError("vlm", {
                                                    type: "custom",
                                                    message: "수량을 입력해 주세요.",
                                                });
                                                setWthdrwVlm("0");
                                            } else if (vlm.compareTo(inputVlm) >= 0) {
                                                clearErrors("vlm");
                                                setWthdrwVlm(el.target.value);
                                                setValue('vlm', decimalNoFormat(el.target.value, 6));
                                            } else {
                                                setError("vlm", {
                                                    type: "custom",
                                                    message: "잔고가 부족 합니다.",
                                                });
                                                setWthdrwVlm(el.target.value);
                                                setValue('vlm', decimalNoFormat(el.target.value, 6));
                                            }
                                        }
                                    })}
                                />
                            }
                        />
                    </FlexRowStart>
                    <div className={styles.txt20_B01} style={{
                        width: '20%',
                        textAlign: 'right',
                        marginTop: '27px',
                        marginBottom: 'auto',
                        borderBottom: 'solid 1px #0F8CFF'
                    }}>
                        {props.coinView.crncyCd}
                    </div>
                </FlexRowSpace>
                <FlexColumEnd gap={5}>
                    <FlexRowEnd gap={8}>
                        <div className={styles.txt16_700}>{t("보유수량")}</div>
                        <div className={styles.txt16_500_828282}>
                            {decimalFormat(props.coinView.usrBalance, 6)} DRM
                        </div>
                    </FlexRowEnd>
                    {
                        props.coinView.hldLock>0?
                            (
                                <FlexRowEnd className={styles.txt14_828282}>
                                    외부출금 제한수량 {decimalFormat(props.coinView.hldLock,6)} DRM
                                </FlexRowEnd>
                            )
                            :
                                (
                                    <></>
                        )
                    }

                </FlexColumEnd>
                <Bar/>


                <FlexColumnCenter style={{padding: '20px 0px'}}>
                    <FlexColumEnd gap={15} style={{
                        border: '1px solid #0F8CFF',
                        width: '100%',
                        background: '#FFFFFF',
                        borderRadius: '12px',
                        padding: '20px 20px'
                    }}>
                        <FlexRowSpace gap={10}>
                            <FlexRowStart className={styles.txt14_828282}>{t('출금주소')} :</FlexRowStart>
                            <FlexColumEnd className={styles.txt14_500} >
                                <div>

                                {ellipseAddress(props.walletSetup.rcvAddr,6)}
                                </div>
                                {
                                    props.walletSetup.inUser ?
                                <div className={styles.txt14_828282}>
                                    ({props.walletSetup.inUsrId})
                                </div>
                                        :<></>
                                }
                            </FlexColumEnd>
                        </FlexRowSpace>
                        <FlexRowSpace gap={10} >
                            <div className={styles.txt16_500_828282}>{t('출금요청 수량')} :</div>
                            <div className={styles.txt16_500}>
                                {decimalFormat(wthdrwVlm,6)}
                                <span className={styles.txt16_500_828282} style={{marginLeft:'5px'}}> {props.coinView.crncyCd}</span>
                            </div>
                        </FlexRowSpace>
                        <Bar01/>
                        {/*수수료 코인이 다를 경우.*/}
                        {
                            !sameFeeCrncyCd ?
                                <FlexRowSpace gap={10}>
                                    <div className={styles.txt14_828282}>{t('수수료 코인 잔고')} :</div>
                                    <div className={styles.txt14_500}>
                                        {decimalFormat(wthdrwFeeVlm, 6)}
                                        <span className={styles.txt14_828282} style={{marginLeft:'5px'}}>{props.coinView.wthdrwFeeCrncyCd}</span>
                                    </div>
                                </FlexRowSpace>
                                :
                                <></>
                        }
                        <FlexRowSpace gap={10}>
                            <div className={styles.txt14_828282}>{t('수수료')} :</div>
                            <div className={styles.txt14_500}>
                                {decimalFormat(feeVlm, 6)}
                                <span className={styles.txt14_828282} style={{marginLeft:'5px'}}>{props.coinView.wthdrwFeeCrncyCd}</span>
                            </div>
                        </FlexRowSpace>
                        <FlexRowSpace gap={10}>
                            <FlexRowStart className={styles.txt14_828282} style={{marginBottom: 'auto'}}>
                                {t('필요한 수량')} :
                                {
                                    !sameFeeCrncyCd ?
                                        <>
                                            <br/>(출금수량+수수료)
                                        </>
                                        :<></>
                                }
                            </FlexRowStart>
                            <FlexColumEnd className={styles.txt14_500}>
                                <div>
                                    {decimalFormat(totalWthdrwVlm, 6)}
                                    <span className={styles.txt14_828282} style={{marginLeft:'5px'}}>{props.coinView.crncyCd}</span>
                                </div>
                                {
                                    !sameFeeCrncyCd ?
                                        <div>
                                           {feeVlm}
                                            <span className={styles.txt14_828282} style={{marginLeft:'5px'}}>
                                                {props.coinView.wthdrwFeeCrncyCd}
                                            </span>


                                        </div>
                                        :
                                        <></>
                                }
                            </FlexColumEnd>
                        </FlexRowSpace>
                        <Bar01/>
                        <FlexRowSpace gap={10} className={styles.txt16_700_5B5B5B}>
                            <FlexRowStart  style={{marginBottom: 'auto'}}>
                                {t('출금 후 잔고')} :
                            </FlexRowStart>
                            <FlexColumEnd gap={5}>
                                <div>
                                    {remainVlm}
                                    <span className={styles.txt14_828282} style={{marginLeft:'5px'}}>{props.coinView.crncyCd}</span>
                                </div>
                                {
                                    !sameFeeCrncyCd ?
                                        <div>
                                            {
                                                decimalCompare(wthdrwFeeVlm,feeVlm)<0?
                                                    <>
                                                    {t('수수료 부족')}
                                                    </>
                                                    :
                                                    <>
                                                    {decimalFormat(decimalSubtract(wthdrwFeeVlm, feeVlm),6)}
                                                    </>


                                            }
                                            <span className={styles.txt14_828282} style={{marginLeft:'5px'}}>
                                                {props.coinView.wthdrwFeeCrncyCd}
                                            </span>
                                        </div>
                                        :
                                        <></>
                                }
                            </FlexColumEnd>
                        </FlexRowSpace>

                        {/*<div className={styles.txt13_700} >*/}
                        {/*    {props.coinView.wthdrwFeeCrncyCd}*/}
                        {/*</div>*/}
                        {/*<div>{t('보유수량')} : {decimalFormat(gasFee,6)}</div>*/}
                    </FlexColumEnd>
                </FlexColumnCenter>


                <FlexColumn style={{padding: '20px 0px'}}>
                    <FlexRowAround className={styles.white_in_btn} style={{borderRadius: '12px'}} gap={10}>
                        <img src={require('../../assets/images/product/WarningCircle.png')} width={20}/>
                        <div className={styles.txt14_828282}>
                            <Trans key={t}
                                   i18nKey={'외부지갑 전송은 네트워크 사정에 따라 다소 지연이 될수 있습니다. 출금 TXID는 신청 완료 후 출금 내역에서 확인이 가능 합니다.'}/>
                        </div>
                    </FlexRowAround>
                </FlexColumn>
            </FlexColumn>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={btnActive}
                        backgroundColor={btnActive ? "#0F8CFF" : "#8D94A0"}
                        textColor={"#FFFFFF"}
                        // border={"solid 1px #dbdee6"}
                        onClick={handleSubmit(onSubmit)}
                        text={t("출금하기")}
                    />
                </FlexColumn>
            </FlexBottom>
        </>
    );
}