import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import styles from "../../assets/styles/uni.module.scss";
import {useState} from "react";


export const PageRootContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  background-color: #F8F8F8;
  z-index: 99;
`;

export const PageContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;  
  margin-bottom: 56px;
  font-size:16px;
`;

interface IFlex {
    gap?: number;
    bgColor?: string;
}

export const FC = styled.div<IFlex>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => (props.gap ? "gap:" + props.gap + "px" : "")};
`;

export const FR = styled.div<IFlex>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  ${(props) => (props.gap ? "gap:" + props.gap + "px" : "")};
`;

export const FlexColumn = styled(FC)`
  align-items: flex-start;
  justify-content: center;
`;

export const FlexColumnCenter = styled(FC)`
  justify-content: center;
align-items: center;
`;


export const FlexColumEnd = styled(FC)`
  align-items: flex-end;
  justify-content: center;
`;

export const FlexColumSpace = styled(FC)`
  align-items: center;
  justify-content: space-between;
`;


export const FlexRowSpace = styled(FR)`
  justify-content: space-between;
  align-items: center;
`;

export const FlexRowEnd = styled(FR)`
  justify-content: flex-end;
  align-items: center;
`;

export const FlexRowStart = styled(FR)`
  justify-content: flex-start;
  align-items: center;
`;

export const FlexRowAround = styled(FR)`
  justify-content: space-around;
  align-items: center;
`;

export const FlexRowCenter = styled(FR)`
  justify-content: center;
  align-items: center;
`;

export const FlexBottom = styled(FR)`
  position:fixed;
  top: calc(100% - 56px);
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
  min-width: 360px;
  max-width: 412px;
  height: 56px;
  margin-left:-20px;
`;

export const FlexBottom86 = styled(FR)`
  position:fixed;
  top: calc(100% - 86px);
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
  min-width: 360px;
  max-width: 412px;
  height: 86px;
  margin-left:-20px;
`;

export const FlexBottom143 = styled(FC)`
  position:fixed;
  top: calc(100% - 143px);
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
  min-width: 360px;
  max-width: 412px;
  height: 86px;
  margin-left:-20px;
`;
export const FlexBottomStart = styled(FC)`
 position:fixed;
  top: calc(100% - 96px);
  justify-content: flex-start;
  align-items: center;
  width: calc(100%);
  min-width: 360px;
  max-width: 412px;
  height: 56px;
  margin-left:-20px;
`;
export const FlexBottomFree = styled(FC)`
  position:fixed;
  justify-content: flex-start;
  align-items: center;
  width: calc(100%);
  min-width: 360px;
  max-width: 412px;
  padding:24px 20px;
  animation: fadeInUp 0.5s;
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
`;
export const FullScreen = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
`;

export const PopupBodyWrapper = styled.div`
  box-sizing: border-box;
  min-width: 360px;
  max-width: 412px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background:#F8F8F8;
  //padding: 0px 20px 0px 20px;
`;
export const RightToLeft = styled.div`
  width: 100%;
  animation: smoothAppear 0.3s;

  @keyframes smoothAppear {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const CoinBox = styled(FR)`
  height: 80px;
  align-self: stretch;
  flex-grow: 0;
  justify-content: space-between;
  align-items: center;
  padding: 21px 16px;
  box-sizing: border-box;
  border-radius: 16px;
  border: solid 1px #f2f4f6;
`;

export const Bullet = styled(FR)`
  &::before {
    content: " ";
    //content: "●";
    background-color: ${(props) => (props.color ? props.color : "#66bf9f")};
    font-size: 8px;
    margin-right: 4px;
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }
  align-items: center;
`;


export const ZSelect = styled.select`
  border: 0px;
  text-align: right;
  padding: 0 10px;
  background: #FFFFFF;
`;
export const Bar = styled.div`
  background-color: #F3F4F5;
  height: 16px;
  width: calc(100% + 40px);
  margin-left: -20px;
`;

export const Bar01 = styled.div`
  background-color: #F3F4F5;
  height: 5px;
  width: 100%;
  //margin-left: 5px;
`;

export const EMPTY_SRC = " ";



interface IPageTitleProps {
    leftText?: string;
    leftSrc?: string;
    leftSrcSize?: number;
    mainnetName?: string;
    onClickMainnet?: () => void;
    onClickLeft?: () => void;
    textSrc?: string;
    textTitle?: string;
    rightSrc?: string;
    rightText?: string;
    rightSrcSize?: number;
    onClickRight?: () => void;
    pwaInstall?: string;
    home?:boolean;
    wallet?:boolean;
    burgerMenu?:boolean;
}


export default function PageTitle(props: IPageTitleProps) {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    return (
        <FlexColumn className={styles.pageTitle} style={{ height: "56px" }}>
            <FlexRowSpace>
                <FlexRowStart style={{width:'30%'}}>
                    {props.leftText && (<span onClick={() => {if (props.onClickLeft) props.onClickLeft();}}>{props.leftText}</span>)}
                    {(props.leftSrc && props.leftSrc.length>1) && (<img src={props.leftSrc} width={props.leftSrcSize ? props.leftSrcSize : 28} alt={"back_arrow"} onClick={() => { if (props.onClickLeft) props.onClickLeft();}}/>)}
                    {(!props.leftSrc && (props.onClickLeft)) && (<img src={require("../../assets/images/back_arrow.png")} width={16} alt={"back_arrow"} style={{ marginLeft: "-8px" }} onClick={() => { if (props.onClickLeft) props.onClickLeft(); }} />)}
                    {props.burgerMenu && (<img src={require('../../assets/images/menu_btn.png')} width={40} alt={'Menu button'} onClick={()=>{setIsMenuOpen(true)}} />)}
                </FlexRowStart>
                <FlexRowCenter  style={{width:'40%', whiteSpace:'pre-wrap', textAlign:'center'}} className={styles.txt16_500}>{props.textSrc ? (<img src={props.textSrc} width={20} style={{ padding: "2.9px" }} />) : null}{props.textTitle}</FlexRowCenter>


                <FlexRowEnd  style={{width:'30%'}} className={styles.txt15_Crimson}>
                    {props.rightText && (<span onClick={() => { if (props.onClickRight) props.onClickRight(); }} > {props.rightText} </span> )}
                    {props.rightSrc && ( <img src={props.rightSrc} height={props.rightSrcSize ? props.rightSrcSize : 14} alt={"back_arrow"} onClick={() => { if (props.onClickRight) props.onClickRight(); }} /> )}
                    {!props.rightText && !props.rightSrc && ( <div style={{ width: "28px" }}></div> )}
                </FlexRowEnd>
            </FlexRowSpace>
        </FlexColumn>
    );
}
