import {FlexBottomFree, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../../components/common/pageArea";
import {useAppSelector} from "../../store/hooks";

import styles from "../../assets/styles/uni.module.scss";
import {useTranslation} from "react-i18next";
import BurgerMenu from "./burgerMenu";
import {useState} from "react";
import {selectAuth} from "../../store/modules/authSlice";
import {useNavigate} from "react-router-dom";


export default function MainTop() {
    const navigate = useNavigate();
    const { t } = useTranslation();


    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {isLoggedIn, user, asyncError}  = useAppSelector(selectAuth);

    return (
        <>
            <BurgerMenu open={isMenuOpen} setOpen={setIsMenuOpen} />

            <FlexBottomFree style={{ top: '0px', borderRadius:'0px', zIndex:'100', height:'65px', background:'#F8F8F8'}}>
                <FlexRowSpace>
                    <FlexRowStart gap={2}>
                        <div><img src={require('../../assets/images/home/logo.png')} width={112} onClick={()=>navigate("/")}/></div>
                    </FlexRowStart>
                    <FlexRowEnd gap={10}>
                        {
                            isLoggedIn ===false && (<div className={styles.txt16_700} onClick={()=>{navigate('/login')}}>{t("로그인")}</div>)
                        }

                        <div onClick={()=>{setIsMenuOpen(true);}}><img src={require('../../assets/images/btcon_menu.png')} width={21} alt={'Menu button'}/></div>
                    </FlexRowEnd>
                </FlexRowSpace>
            </FlexBottomFree>
        </>
    )
}