import PageTitle, {
    FullScreen,
    PageContent,
    PageRootContainer,
    PopupBodyWrapper
} from "../../components/common/pageArea";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import WithdrawDepth1 from "./withdrawDepth1";
import WithdrawDepth2 from "./withdrawDepth2";
import WithdrawDepth3 from "./withdrawDepth3";
import WithdrawDepth4 from "./withdrawDepth4";


interface ISettingPopup {
    show: boolean;
    setShow: any;
    coinView:any;
}
export interface IWalletSetup{
    crncyCd: string;
    usrPw: string;
    rcvAddr: string;
    vlm:string;
    inTxId:string;
    inUser?:boolean;
    inUsrId?:string;
    crncyTpCd?: string;
}

export default function WalletPopup(props:ISettingPopup){
    const [pageView, setPageView] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [pageDepth, setPageDepth] = useState(1);

    const [walletSetup, setWalletSetup] = useState<IWalletSetup>({
        crncyCd: '',
        usrPw:  '',
        rcvAddr:  '',
        vlm: '',
        inTxId:'',
        inUser:false,
        inUsrId:'',
        crncyTpCd:''
    })
    const {
        register,
        formState: { errors, isDirty, isValid },
        setError,
        clearErrors,
        handleSubmit
    } = useForm();
    useEffect(()=>{
        if(props.coinView){
            console.log("props.coinView.crncyCd", props.coinView.crncyCd);
            console.log("props.coinView.crncyTpCd", props.coinView.crncyTpCd);
            console.log("pageDepth", pageDepth);
            setWalletSetup({
                ...walletSetup,
                crncyCd : props.coinView.crncyCd,
                crncyTpCd : props.coinView.crncyTpCd
            })
        }
    },[props.coinView]);

    return (
        <>
            {
                props.show && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    textTitle={pageDepth === 1 ? t('출금 패스워드 입력') : t('출금하기')}
                                    onClickLeft={()=>{
                                        if(pageDepth === 1)
                                        {
                                            props.setShow(false)
                                        }else {
                                            setPageDepth(pageDepth-1)
                                        }
                                    }}
                                    />
                                <PageContent style={{paddingTop:'20px'}}>
                                    {pageDepth === 1 && (<WithdrawDepth1 coinView={props.coinView} setPageDepth={setPageDepth} walletSetup={walletSetup} setWalletSetup={setWalletSetup}/>)}
                                    {pageDepth === 2 && (<WithdrawDepth2 coinView={props.coinView} setPageDepth={setPageDepth} walletSetup={walletSetup} setWalletSetup={setWalletSetup}/>)}
                                    {pageDepth === 3 && (<WithdrawDepth3 coinView={props.coinView} setPageDepth={setPageDepth} walletSetup={walletSetup} setWalletSetup={setWalletSetup}/>)}
                                    {pageDepth === 4 && (<WithdrawDepth4 coinView={props.coinView} inTxId={walletSetup.inTxId}/>)}
                                </PageContent>

                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>

    )
}