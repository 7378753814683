import PageTitle, {
    FlexBottom,
    FlexColumn,
    FlexColumnCenter, FlexRowCenter,
    PageContent,
    PageRootContainer
} from "../../components/common/pageArea";
import {useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import styles from "../../assets/styles/uni.module.scss";
import React from "react";


export default function PrivateLogin(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <PageRootContainer>
            <PageTitle  textTitle={t('로그인 필요')}
                        onClickLeft={()=>{navigate(-1)}}/>
            <PageContent>
                <FlexColumn gap={20}>
                    <FlexColumnCenter>
                        <img src={require('../../assets/images/home/icon_01.png')} width={310} />
                    </FlexColumnCenter>
                    <FlexColumnCenter className={styles.txt30_700}>
                        🙏
                        <div style={{textAlign:'center'}}>
                        <Trans t={t} i18nKey={('<span className={styles.txt24_400_828282}>죄송합니다. 해당서비스는</span><br/>로그인 후  이용이<br/>가능한 서비스 입니다.')}/>
                        </div>
                    </FlexColumnCenter>
                    <FlexRowCenter gap={12}>
                        <FlexColumnCenter className={styles.blue_in_btn} style={{borderRadius:'10px', width:'50%', fontSize:'15px', fontWeight:'700'}} onClick={()=>{navigate('/join')}}>{t('회원가입')}</FlexColumnCenter>
                        <FlexColumnCenter className={styles.black_in_btn} style={{borderRadius:'10px', width:'50%', fontSize:'15px', fontWeight:'700'}} onClick={()=>{navigate('/login')}}>{t('로그인')}</FlexColumnCenter>
                    </FlexRowCenter>
                </FlexColumn>

            </PageContent>
        </PageRootContainer>
    )
}