import styles from "../../assets/styles/uni.module.scss";
import {
    FlexColumEnd,
    FlexColumn,
    FlexColumnCenter,
    FlexRowAround,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/pageArea";
import {Trans, useTranslation} from "react-i18next";
import Toggle from "react-toggle";
import React, {useEffect, useState} from "react";
import {IStakingList} from "../../pages/staking/stakingPage";
import moment from "moment";
import StakingService from "../../services/staking.service";
import {Toast} from "../common/popup/ToastAlert";
import {decimalFormat, ellipseAddress} from "../../helpers/utilities";
import Countdown from 'react-countdown';
import {renderer} from "./stakinglist";
import {IBnsConf, IBonusConfig, IRefStaking} from "../../pages/staking/stakingNoBnsPopup";
import bigDecimal from "js-big-decimal";
import KdreamCountDown from "./KdreamCountDown";


export default function RefStakingList(props:{stkData:IRefStaking,bnsConf?:IBnsConf,bnsPolicy?:IBonusConfig}){
    const { t } = useTranslation();
    const caculateTron = (lvl:string,stkVlm: string, drmRt: number | undefined, bnsPolicy: Array<IBonusConfig> | undefined) =>{
        let dStkVlm = new bigDecimal(stkVlm);
        let dDrmDt = new bigDecimal(drmRt);
        let policy:IBonusConfig = getBonusPolicy(lvl, bnsPolicy);
        if(policy===null){
            return "0";
        }
        let h100 = new bigDecimal(100);
        let rt = new bigDecimal(policy.bnsRt).divide(h100,6);
        return dStkVlm.multiply(dDrmDt).multiply(rt).getValue();
    }

    const getBonusPolicy = (lvl: string, bnsPolicy: Array<IBonusConfig> | undefined) => {
        let rst: IBonusConfig | null = null;
        bnsPolicy?.map(item=>{
            if (lvl === item.lvl) {
                rst = item;
            }
        })
        return rst;
    };

    const needLvl = (stkData: IRefStaking, userConf: IBnsConf | undefined, bnsPolicy: Array<IBonusConfig> | undefined) =>{
        let text = "";
        let policy:IBonusConfig = getBonusPolicy(stkData.depth, bnsPolicy);
        if (policy.stkVlm > userConf?.stkVlm) {
            text = "추가 스테이킹";
        }
        if (policy.frstRefCnt > userConf?.frstRefCnt) {
            if (text !== "") {
                text = text + " | ";
            }
            text = text + "추천인";
        }
        if (text !== "") {
            text = text + " 필요";
        }
        return text;
    }

    return (
        <FlexColumn className={styles.yellow_in_box} style={{'borderRadius': '20px'}} gap={10}>
            <FlexRowStart className={styles.txt14_500}>
                <FlexRowStart style={{width:'50px'}}>
                    <div className={`${styles.blue01_in_btn} ${styles.txt13_W}`}
                         style={{borderRadius:"15px",width:"15px",padding:"6px 8px"}}>
                        L{props.stkData.depth}
                    </div>
                </FlexRowStart>
                <FlexRowSpace>
                    <FlexColumn gap={5}>
                        <FlexRowStart className={styles.txt14_700_424242}>
                            스테이킹
                        </FlexRowStart>
                        <FlexRowStart>{decimalFormat(props.stkData.stkVlm,2)} DRM</FlexRowStart>
                    </FlexColumn>

                    <FlexColumn style={{paddingRight:"20px"}} gap={5}>
                        <FlexRowEnd>{ellipseAddress(props.stkData.usrId,6)}</FlexRowEnd>
                        <FlexRowEnd className={styles.txt13_696969}>시작일 : {props.stkData.dtmFormat}</FlexRowEnd>
                    </FlexColumn>
                </FlexRowSpace>
            </FlexRowStart>
            <FlexRowSpace className={`${styles.black_in_box} ${styles.txt14_500}`} style={{borderRadius:'12px',padding:'10px 24px'}} gap={0}>
                <FlexRowAround>
                    <FlexColumn gap={5}>

                        <FlexRowStart>수령가능보너스</FlexRowStart>
                        <FlexColumn style={{fontSize:'13px'}}>
                            <div>{decimalFormat(caculateTron(props.stkData.depth,props.stkData.stkVlm,props.bnsConf?.drmRt,props.bnsPolicy),2)} TRON</div>
                            {
                                props.bnsConf?.rlvl>=props.stkData.depth?
                                    props.stkData.bnsYn==='N'?(<div style={{color:'#9B9B9B'}}>보너스수령 가능</div>):
                                        props.stkData.cnctIdx ?(<div style={{color:'#9B9B9B'}}>보너스수령완료</div>): (<div style={{color:'red'}}>지급완료 후 자격달성</div>)
                                    :
                                        (<div style={{color:'red'}}>지급 자격미달</div>)

                            }

                        </FlexColumn>
                        {
                            props.bnsConf?.rlvl>=props.stkData.depth?
                                (<></>)
                                :(<FlexRowStart className={styles.txt14_0F8CFF} style={{fontSize:'13px'}}>
                                    {needLvl(props.stkData,props.bnsConf,props.bnsPolicy)}
                                </FlexRowStart>)
                        }

                    </FlexColumn>

                    {
                        props.stkData.bnsYn==='N'?
                            (<FlexColumEnd style={{marginBottom:'auto'}} gap={5}>
                                <FlexRowEnd>매칭 보너스 대기중</FlexRowEnd>
                                <FlexRowEnd>
                                    {/*<KdreamCountDown dDayTime={props.stkData.regDtm+(1000*60*60*48) }/>*/}

                                    <Countdown
                                        date={Date.now() + props.stkData.regDtmTime+(1000*60*60*48)  - Date.now()}
                                        daysInHours={true}
                                        renderer={renderer}
                                    />
                                </FlexRowEnd>
                            </FlexColumEnd>)
                            :
                                (<FlexColumEnd style={{marginBottom:'auto'}} gap={5}>
                                    <FlexRowEnd>매칭 보너스</FlexRowEnd>
                                    <FlexRowEnd>
                                        지급 완료
                                        {/*총 {decimalFormat(props.stkData.mtchBnsVlm,2)} TRON*/}
                                    </FlexRowEnd>
                                </FlexColumEnd>)
                    }



                </FlexRowAround>
            </FlexRowSpace>
        </FlexColumn>
    )
}
