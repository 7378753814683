import {IJoinDepth} from "../../pages/login/join";
import {FlexBottom, FlexColumn, FlexColumnCenter, PageContent} from "../common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import BottomButton from "../common/button/BottomButton";
import {useAppDispatch} from "../../store/hooks";
import {useForm} from "react-hook-form";
import DefaultInput from "../common/input/DefaultInput";
import {useState} from "react";
import {certNumAsync, registerAsync, setAsyncError} from "../../store/modules/authSlice";
import {useTranslation} from "react-i18next";
import authService from "../../services/auth.service";


export default function JoinForm(props:IJoinDepth){
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [useRefCode, setUseRefCode] = useState<string>(localStorage.getItem("refCode") ? localStorage.getItem("refCode")! : '');

    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
        setError,
        clearErrors
    } = useForm();


    const onSubmit = async (data:any): Promise<void> => {
        console.log(data);
        const {usrId, usrPw, usrPwConf, usrNm} = data;
        const upRefCd = useRefCode;
        if (usrPw !== usrPwConf) {
            setError("usrPwConf", {
                type: "custom",
                message: t("지갑 패스워드가 일치 하지 않습니다."),
            });
        }else{
            authService.certNum(usrId, usrPw, usrNm, upRefCd).then(res=>{
                console.log(res);

                if(res.resultCode === "SUCCESS"){
                    props.setJoinData({ usrId, usrPw, usrPwConf, usrNm, upRefCd });
                    props.setPageDepth(3);
                }

            })
        }

    }
    return (
        <>
            <PageContent style={{paddingTop:'26px'}}>
                <FlexColumn gap={44}>
                    <FlexColumnCenter className={styles.txt34_700}>{t('지갑생성')}</FlexColumnCenter>
                    <FlexColumn gap={24}>
                        <DefaultInput
                            lable={t("이메일")}
                            error={errors.usrId}
                            titleClassName={styles.txt16_500}
                            inputElement={
                                <input
                                    type={"text"}
                                    className={styles.inputText_44}
                                    placeholder={t("이메일을 입력해주세요.")}
                                    {...register("usrId", {
                                        pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                                            message: t("이메일 형식이 올바르지 않습니다."),
                                        },
                                    })}
                                />
                            }
                        />
                        <FlexColumn gap={1}>
                        <DefaultInput
                            lable={t("지갑 패스워드")}
                            error={errors.usrPw}
                            titleClassName={styles.txt16_500}
                            inputElement={
                                <input
                                    type={"password"}
                                    className={styles.inputText_44}
                                    placeholder={t("지갑 패스워드를 입력해주세요.")}
                                    {...register("usrPw", {
                                        required: t("지갑 패스워드를 입력해주세요."),
                                        pattern: {
                                            // value: /^(?=.*[a-zA-Z0-9]).{12,20}$/i,
                                            value: /^(?=.*).{4,30}$/i,
                                            message: t("패스워드를 4자리 이상 입력해 주세요."),
                                        }
                                    })}
                                />
                            }
                        />
                        <div className={styles.txt14_0F8CFF}>* {t("패스워드는 4자리 이상")}</div>
                        </FlexColumn>
                        <DefaultInput
                            lable={t("지갑 패스워드 확인")}
                            error={errors.usrPwConf}
                            titleClassName={styles.txt16_500}
                            inputElement={
                                <input
                                    type={"password"}
                                    className={styles.inputText_44}
                                    placeholder={t("지갑 패스워드를 한번 더 입력해주세요.")}
                                    {...register("usrPwConf", {
                                        required: t("지갑 패스워드를 한번 더 입력해주세요."),
                                    })}
                                />
                            }
                        />
                        <DefaultInput
                            lable={t("닉네임 입력")}
                            error={errors.usrNm}
                            titleClassName={styles.txt16_500}
                            inputElement={
                                <input
                                    type={"text"}
                                    className={styles.inputText_44}
                                    placeholder={t("서비스에서 사용할 이름을 입력해주세요.")}
                                    {...register("usrNm", {
                                        required: t("서비스에서 사용할 이름을 입력해주세요.")
                                    })}
                                />
                            }
                        />
                        <DefaultInput
                            lable={t("추천인코드 입력")}
                            error={errors.refCd}
                            titleClassName={styles.txt16_500}
                            inputElement={
                                <input
                                    type={"text"}
                                    className={styles.inputText_44}
                                    value={useRefCode}
                                    placeholder={t("추천인 코드를 입력해주세요.")}
                                    {...register("refCd", {
                                        onChange:(el)=>{
                                            setUseRefCode(el.target.value);
                                        }
                                    })}
                                />
                            }
                        />
                    </FlexColumn>
                </FlexColumn>
            </PageContent>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#0F8CFF"}
                        textColor={"#FFFFFF"}
                        // border={"solid 1px #dbdee6"}
                        onClick={handleSubmit(onSubmit)}
                        text={t("회원가입")}
                    />
                </FlexColumn>
            </FlexBottom>
        </>
    )
}