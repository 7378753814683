import TokenService from './token.service';
import {refreshToken, logoutAsync, setLoding, setAsyncError} from '../store/modules/authSlice';
import http from './http';
import httpPublic from './httpPublic';

const setup = (store: any) => {
    http.interceptors.request.use(
        (config) => {

            if((config.url !=='/user/main/getRound') && (config.url !=='/user/main/getMyAssets')){
                dispatch(setLoding(true));
            }

            const token = TokenService.getLocalAccessToken();
            if (token) {

                config.headers['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
                //config.headers['x-access-token'] = token; // for Node.js Express back-end
            }
            return config;
        },
        (error) => {
            dispatch(setLoding(false));
            return Promise.reject(error);
        }
    );

    const { dispatch } = store;
    http.interceptors.response.use((res) => {
            dispatch(setLoding(false));
            return res;
        },

        async (err) => {
            dispatch(setLoding(false));
            const originalConfig = err.config;
            console.log("err.response.status",err)
            if (originalConfig.url !== '/anony/login' && err.response) {
                // Access Token was expired

                if(err.response.status === 400){
                    console.log("Access Token was expired====================", err.response)
                    dispatch(setAsyncError(err.response.data.message));
                    return err.response;
                }else if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    dispatch(logoutAsync())
                    // try {
                    //   const token = TokenService.getLocalRefreshToken();
                    //   const rs = await httpPublic.get('/auth/refresh', {
                    //     headers: {
                    //       Authorization: `Bearer ${token}`
                    //     }
                    //   });
                    //   dispatch(refreshToken(rs.data));
                    //   TokenService.updateLocalAccessToken(rs.data.accessToken);
                    //   TokenService.updateLocalRefreshToken(rs.data.refreshToken);
                    //
                    //   return http(originalConfig);
                    // } catch (_error) {
                    //   return Promise.reject(_error);
                    // }
                }
            }else{
                return Promise.reject(err);
            }

            //
        }
    );
};

export default setup;
