import moment from "moment";
import React, {useEffect, useState} from "react";

import PageTitle, {
    FlexBottom,
    FlexColumEnd,
    FlexColumn, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen, PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../../components/common/pageArea";
import BottomButton from "../../components/common/button/BottomButton";
import NickItem from "../../components/setting/nickItem";
import PassItem from "../../components/setting/passItem";
import EmailItem from "../../components/setting/emailItem";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import SecretKey from "../../components/setting/secretKey";

export interface ISettingPopup {
    show: boolean;
    setShow: any;
    pageMode?:any;
}
export default function SettingPopup(props:ISettingPopup){
    const {t} = useTranslation();
    return (
        <>
            {
                props.show && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle textTitle={
                                    props.pageMode === 'nick' ? (t('닉네임 변경')) : (
                                        props.pageMode === 'pass' ? (t('패스워드 변경')) : (
                                            props.pageMode === 'key' ? (t('지갑 개인키 보기')) : (
                                                (t('이메일 변경'))
                                            )
                                        )
                                    )
                                } rightSrc={require('../../assets/images/home/close.png')} onClickRight={()=>{props.setShow(false)}}/>

                                { props.pageMode === 'nick' && (<NickItem setShow={props.setShow}/>) }
                                { props.pageMode === 'pass' && (<PassItem setShow={props.setShow}/>) }
                                { props.pageMode === 'key' && (<SecretKey setShow={props.setShow}/>) }
                                { props.pageMode === 'eamil' && (<EmailItem setShow={props.setShow}/>) }
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>

    )
}