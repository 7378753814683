import {FlexBottom, FlexColumn, PageContent} from "../common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import BottomButton from "../common/button/BottomButton";
import {useAppDispatch} from "../../store/hooks";
import {useForm} from "react-hook-form";
import {IJoinDepth} from "../../pages/login/join";
import {useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import authService from "../../services/auth.service";


export default function TermsPage(props:IJoinDepth){
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [termsCtnt, setTermsCtnt] = useState();
    useEffect(()=>{
        authService.termsView().then((res:any)=> {
            console.log("res",res);
            setTermsCtnt(res.data.termsCtnt)
        });
    },[])
    return (
        <>
            <PageContent style={{paddingTop:'66px'}}>
                <FlexColumn gap={28} className={styles.txt20_500}>
                    <div><Trans t={t} i18nKey={('회원정보 입력전에 지갑생성에 대한<br/><span className={styles.txt20_700}>약관 동의후 지갑생성이 가능 합니다.</span>')}/></div>
                    <pre className={styles.white_in_btn} style={{height:'457px', textWrap:'wrap',  fontSize:'13px', fontWeight:'400', color:'9B9B9B',  borderRadius:'12px', overflow:'scroll'}} dangerouslySetInnerHTML={ {__html: t(termsCtnt)} }>

                    </pre>
                </FlexColumn>
            </PageContent>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#FFFFFF"}
                        textColor={"black"}
                        // border={"solid 1px #dbdee6"}
                        onClick={()=>{
                            navigate(-1);
                        }}
                        text={t("동의 안합니다.")}
                    />
                </FlexColumn>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#0F8CFF"}
                        textColor={"#FFFFFF"}
                        // border={"solid 1px #dbdee6"}
                        onClick={()=>{props.setPageDepth(2)}}
                        text={t("약관에 동의 합니다.")}
                    />
                </FlexColumn>
            </FlexBottom>
        </>
    )
}