import React, {useEffect, useState} from "react";

import PageTitle, {
    FlexColumn, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart,
    FullScreen,
    PageContent,
    PageRootContainer,
    PopupBodyWrapper
} from "../../components/common/pageArea";
import {useTranslation} from "react-i18next";
import styles from "../../assets/styles/uni.module.scss";
import {useInView} from "react-intersection-observer";
import StakingService from "../../services/staking.service";
import {IStakingList} from "./stakingPage";
import EmptyContent from "../../components/common/EmptyContent";
import StakingEndList from "../../components/staking/stakingEndlist";
import ProfileService from "../../services/profile.service";
import {ellipseAddress} from "../../helpers/utilities";
import Countdown from "react-countdown";
import {renderer} from "../../components/staking/stakinglist";
import RefStakingList from "../../components/staking/refStakingList";

export interface ISettingPopup {
    show: boolean;
    setShow: any;
}

export interface IRefStaking {
    id:string;
    usrId:string;
    shortNm:string;
    depth:string;
    stkVlm:string;
    bnsYn:string;
    intrstVlm:string;
    mtchBnsVlm:string;
    cnctIdx:string;
    regDtm:number;
    regDtmTime:number;
    dtmFormat:string;
}
export interface IBonusConfig {
    lvl?:string;
    stkVlm?:string;
    frstRefCnt?:string;
    bnsRt?:string;
    regDtm?:string;
    modDtm?:string;
}
export interface IBnsConf {
    rlvl?:number;
    rbnsRt?:number;
    crncyCd?:string;
    drmRt?:number;
    frstRefCnt?:number;
    stkVlm?:number;
}
export default function StakingNoBnsPopup(props:ISettingPopup){
    const {t} = useTranslation();
    const [showPop, setShowPop] = useState(false);
    const [refStaking, setRefStaking] = useState<Array<IRefStaking>>([])
    const [userBnsConf, setUserBnsConf] = useState<IBnsConf>()
    const [bnsPolicy, setBnsPolicy] = useState<Array<IBonusConfig>>([])

    useEffect(() => {
        const fetchData = async() =>{
            ProfileService.dnStakingRef().then(res=>{
                setRefStaking(res.data);
            });
            ProfileService.getUserBnsConfig().then(res=>{
                console.info("setUserBnsConf",res.data)
                setUserBnsConf(res.data);
            });
            ProfileService.getBonusConfig().then(res=>{
                setBnsPolicy(res.data);
            });
        }
        if (props.show) {
            fetchData();
        }
    },[props.show]);

    useEffect(() => {
        if (refStaking.length>0 && userBnsConf) {

        }
    },[refStaking,userBnsConf]);

    return (
        <>
            {
                props.show && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle textTitle={'하부 스테이킹 현황'} rightSrc={require('../../assets/images/home/close.png')} onClickRight={()=>{props.setShow(false)}}/>
                                <PageContent>
                                    <FlexColumn gap={30}>
                                        <div className={styles.txt14_828282}>스테이킹 내역</div>
                                        <FlexColumn style={{marginTop:"10px"}} gap={20}>

                                            {
                                                refStaking.length > 0 ? (
                                                    refStaking.map((item, index) => {
                                                        return (
                                                            <RefStakingList stkData={item}
                                                                            bnsConf={userBnsConf}
                                                                            bnsPolicy={bnsPolicy}
                                                            />
                                                        )
                                                    })
                                                ) : (
                                                    <EmptyContent titleText={t("하부 추천인의 스테이킹 내역이 없습니다.")} titleImage={require('../../assets/images/list_x.png')}/>
                                                )
                                            }
                                        </FlexColumn>
                                    </FlexColumn>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>

    )
}