import {FlexColumEnd, FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import bigDecimal from "js-big-decimal";
import {decimalFormat} from "../../helpers/utilities";
import WalletService from "../../services/wallet.service";


export default function MyAccount(props:{pageMode:string}){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [reLoad, setReLoad] = useState(false);
    const [viewBalance, setViewBalance] = useState(localStorage.getItem("viewBalance") === "Y" ? localStorage.getItem("viewBalance") : "N");
    const [totalBalance, setTotalBalance] = useState('0');
    const [nftBalance, setNftBalance] = useState(0);

    useEffect(()=>{
        WalletService.getSummaryCoinPrice().then(res=>{
             setTotalBalance(res.data);
        });

    }, [reLoad]);


    return (
        <>
            {
                props.pageMode === "main" ? (
                    <FlexColumn gap={20}>
                        <FlexColumn gap={8}>
                            <FlexRowSpace>
                                <FlexRowStart className={styles.txt20_500} gap={9}>{t('보유자산')}<img src={ viewBalance ==="Y" ? require('../../assets/images/home/view_on.png') : require('../../assets/images/home/view_off.png') } width={18} onClick={()=>{localStorage.setItem("viewBalance", viewBalance ==="Y" ? "N" : "Y"); setViewBalance(viewBalance ==="Y" ? "N" : "Y")}}/></FlexRowStart>
                                <FlexRowEnd className={styles.txt12_6F7275} onClick={()=>{window.location.reload();}}><img src={require('../../assets/images/home/reload.png')} width={16} />{t('새로고침')}</FlexRowEnd>
                            </FlexRowSpace>
                            <FlexRowSpace>
                                <FlexRowStart className={styles.txt38_700}>
                                    <FlexColumn>
                                        <div>${viewBalance ==="Y" ? decimalFormat( new bigDecimal(totalBalance).add(new bigDecimal(nftBalance)).getValue().toString() , 2) : "******"}</div>
                                    </FlexColumn>
                                </FlexRowStart>
                                <FlexRowEnd className={styles.txt12_700}>
                                    <div className={styles.white_in_box} onClick={()=>{navigate("/my")}}>{t('자산관리 바로가기')}</div>
                                </FlexRowEnd>
                            </FlexRowSpace>
                        </FlexColumn>
                    </FlexColumn>
                ) : (
                    <FlexColumn gap={20}>
                        <FlexColumn gap={8}>
                                <FlexRowSpace className={styles.txt20_500} gap={10} style={{marginTop:'20px'}}>
                                        <FlexRowStart>
                                            <FlexColumn gap={10}>
                                                <div>{t('보유자산')}</div>
                                                <div className={styles.txt12_6F7275} onClick={()=>{window.location.reload();}}>
                                                    <img src={require('../../assets/images/home/reload.png')} width={16} />{t('새로고침')}
                                                </div>
                                            </FlexColumn>
                                        </FlexRowStart>
                                        <FlexColumEnd className={styles.txt38_700}>
                                            <FlexRowEnd>
                                                ${decimalFormat( new bigDecimal(totalBalance).add(new bigDecimal(nftBalance)).getValue().toString() , 2)}
                                            </FlexRowEnd>
                                        </FlexColumEnd>
                                </FlexRowSpace>
                        </FlexColumn>
                    </FlexColumn>
                )
            }
        </>
    )
}