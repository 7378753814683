import styles from "../../assets/styles/uni.module.scss";
import DefaultInput from "../common/input/DefaultInput";
import {FlexBottom, FlexColumn} from "../common/pageArea";
import React from "react";
import {useForm} from "react-hook-form";
import BottomButton from "../common/button/BottomButton";
import {useTranslation} from "react-i18next";
import {IWalletSetup} from "./walletPopup";
import ProfileService from "../../services/profile.service";
import {ToastError} from "../common/popup/ToastAlert";

export interface IWithdrawDepth{
    setPageDepth:any;
    setWalletSetup:any;
    walletSetup:IWalletSetup;
    coinView:any;
}
export default function WithdrawDepth1(props:IWithdrawDepth){
    const { t } = useTranslation();
    const {
        register,
        formState: { errors, isDirty, isValid },
        setError,
        clearErrors,
        handleSubmit
    } = useForm();
    const onSubmit = (data: any) => {
        if (props.coinView?.inWthdrwYn === 'N') {
            ToastError('해당코인은 출금이 불가능 합니다.');
            return;
        }
        ProfileService.checkLoginPw(data.curUsrPw).then(res=>{
            if(res.data){
                props.setWalletSetup({
                    ...props.walletSetup,
                    usrPw : data.curUsrPw
                })
                props.setPageDepth(2);
            }
        })
    }
    return (
        <>
            <FlexColumn gap={20}>
                <div className={styles.txt20_500}>
                    {t("지갑 패스워드를 입력해 주세요.")}
                </div>
                <DefaultInput
                    error={errors.curUsrPw}
                    inputElement={
                        <input
                            type={"password"}
                            className={styles.inputText_Bottom}
                            placeholder={t("패스워드를 입력해주세요.")}
                            {...register("curUsrPw", {
                                required: t("패스워드를 입력해주세요."),
                                pattern: {
                                    value: /^(?=.*).{4,30}$/i,
                                    message: t("패스워드를 4자리 이상 입력해 주세요."),
                                }
                            })}
                        />
                    }
                />
            </FlexColumn>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#317FF4"}
                        textColor={"#FFFFFF"}
                        // border={"solid 1px #dbdee6"}
                        onClick={handleSubmit(onSubmit)}
                        text={t("출금신청")}
                    />
                </FlexColumn>
            </FlexBottom>
        </>
    )
}