import styles from "../../assets/styles/uni.module.scss";
import {FlexBottom, FlexColumn, FlexColumnCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/pageArea";
import React from "react";
import BottomButton from "../common/button/BottomButton";
import {Trans, useTranslation} from "react-i18next";
import {Toast} from "../common/popup/ToastAlert";
import CopyToClipboard from "react-copy-to-clipboard";
import {useNavigate} from "react-router-dom";
import {ISwapResult} from "./swapView";
import {decimalFormat, ellipseAddress} from "../../helpers/utilities";

interface IWithdrawDepth4{
    crncyCd:string;
    exTxid:string;
    formatDate:string;
    inTxid:string;
    regDtm:string;
    stusCd:string;
    stusCdNm:string;
    tpCd:string;
    tpCdNm:string;
    txTpNm:string;
    vlm:string;
    dpstAddr:string;
}
export default function SwapCompleate(props: { swapResult:ISwapResult }){
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <FlexColumn gap={30}>
                <FlexColumnCenter gap={10}>
                    <div className={styles.txt20_500}>{t("SWAP을 신청 하였습니다.")} </div>
                    <div>({ellipseAddress(props.swapResult.inTxid,5)}) </div>
                </FlexColumnCenter>
                <FlexColumn gap={15} className={styles.txt14_500}>
                    <FlexRowSpace className={styles.txt16_500_828282}>
                        <FlexRowStart>{t("출금수량")}</FlexRowStart>
                        <FlexRowEnd gap={5}>
                            {decimalFormat(props.swapResult.fromVlm, 6)} {props.swapResult.fromCrncyCd}
                        </FlexRowEnd>
                    </FlexRowSpace>
                    <FlexRowSpace className={styles.txt16_500_828282}>
                        <FlexRowStart>{t("받은수량")}</FlexRowStart>
                        <FlexRowEnd gap={5}>
                            {decimalFormat(props.swapResult.toVlm, 6)} {props.swapResult.toCrncyCd}
                        </FlexRowEnd>
                    </FlexRowSpace>
                </FlexColumn>
            </FlexColumn>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#317FF4"}
                        textColor={"#FFFFFF"}
                        // border={"solid 1px #dbdee6"}
                        onClick={()=>{document.location.reload()}}
                        text={t("내역 바로가기")}
                    />
                </FlexColumn>
            </FlexBottom>
        </>
    )
}