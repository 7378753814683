import {
    FlexBottom,
    FlexColumEnd,
    FlexColumn,
    FlexColumnCenter, FlexRowAround,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import bigDecimal from "js-big-decimal";
import {
    decimal,
    decimalAdd,
    decimalCompare,
    decimalFormat,
    decimalNoFormat,
    decimalSubtract
} from "../../helpers/utilities";
import BottomButton from "../common/button/BottomButton";
import {IWalletSetup} from "../coin/walletPopup";
import WalletService from "../../services/wallet.service";
import {ITcCrncy} from "../../pages/coin/coinModule";
import CoinList from "./coinList";
import SwapCompleate from "./swapCompleate";

export interface ITcSwap  {
    crncyCd:string,
    dealPrc:string,
    modDtmFormat:string
}
export interface ISwap{
    fromCrncyCd: string;
    toCrncyCd: string;
    vlm:string;
}

export interface ISwapResult{
    successYn:string;
    inTxid:string;
    fromCrncyCd:string;
    toCrncyCd:string;
    fromVlm:string;
    toVlm:string;
}


export default function SwapView(props:{pageMode:number}){
    const { t } = useTranslation();
    const [btnActive, setBtnActive] = useState(false);
    const [dropMenu1, setDropMenu1] = useState({display:'none'});
    const [wthdrwVlm, setWthdrwVlm] = useState('0');
    const [usrBalance, setUsrBalance] = useState('0');
    const [tronBalance, setTronBalance] = useState<ITcCrncy>();
    const [drmBalance, setDrmBalance] = useState<ITcCrncy>();
    const [tronSwapConfig, setTronSwapConfig] = useState<ITcSwap>();
    const [drmSwapConfig, setDrmSwapConfig] = useState<ITcSwap>();
    const [swapRt, setSwapRt] = useState("0");
    const [swapTronVlm, setSwapTronVlm] = useState("0");
    const [swapDrmVlm, setSwapDrmVlm] = useState("0");
    const [pageMode, setPageMode] = useState(1);
    const [swapResult, setSwapResult] = useState<ISwapResult>({
        successYn:"N"
        ,inTxid:""
        ,fromVlm:"0"
        ,toVlm:"0"
        ,fromCrncyCd:"TRON"
        ,toCrncyCd:"DRM"
    });


    const {
        register,
        formState: { errors, isDirty, isValid },
        setError,
        setValue,
        clearErrors,
        handleSubmit
    } = useForm();
    const onSubmit = (data: any) => {
        if (!validate(data.vlm)) {
            return
        };
        let iSwap:ISwap = {
          fromCrncyCd:'TRON'
          ,toCrncyCd:'DRM'
          ,vlm:data.vlm
        };
        WalletService.swap(iSwap).then(res => {
            setSwapResult(res.data);
            setPageMode(2);
        });
    }

    useEffect(() => {
        WalletService.getCoint('TRON').then(res=>{
            setTronBalance(res.data);
        })
        WalletService.getCoint('DRM').then(res=>{
            setDrmBalance(res.data);
        })
        WalletService.selectSwap().then(res=>{
            res.data.map((item:ITcSwap) => {
                if (item.crncyCd === 'DRM') {
                    setDrmSwapConfig(item);
                }else if (item.crncyCd === 'TRON') {
                    setTronSwapConfig(item);
                }
            });
        })
        WalletService.getSwapRt("TRON", "DRM").then(res => {
            setSwapRt(res.data);
        });
    }, []);

    const validate = (paramVlm:string)=>{
        let vlm = new bigDecimal(paramVlm);
        if (vlm.compareTo(new bigDecimal(0)) <= 0) {
            setError("vlm", {
                // type: "custom",
                message: "수량을 입력해 주세요.",
            });
            setWthdrwVlm("0");
            setSwapTronVlm("0");
            setSwapDrmVlm("0");
            return false;
        } else if (new bigDecimal(tronBalance?.usrBalance).compareTo(vlm) >= 0) {
            clearErrors("vlm");
            setWthdrwVlm(vlm.getValue());
            setValue('vlm', vlm.getValue());
            setSwapTronVlm(decimalFormat(vlm.getValue(),6));

            let drmVlm:bigDecimal = vlm.multiply(new bigDecimal(swapRt));
            setSwapDrmVlm(decimalFormat(drmVlm.getValue(),6));
            setBtnActive(true);
            return true;
        } else {
            setError("vlm", {
                // type: "custom",
                message: "잔고가 부족 합니다.",
            });
            setWthdrwVlm(vlm.getValue());
            setSwapTronVlm("0");
            setSwapDrmVlm("0");
            setValue('vlm', decimalNoFormat(vlm.getValue(), 6));
            return false;
        }
    }



    return (
        <>
            {
                pageMode === 2 && (<SwapCompleate swapResult={swapResult} ></SwapCompleate>)
            }
            { pageMode === 1 && (
                <>
            <FlexColumn gap={20}>
                <FlexColumn style={{marginTop:'10px'}} className={styles.bigSelect}>
                    <FlexRowSpace gap={20}>
                        <div>
                            <img src={require('../../assets/images/symbol/TRX.png') } width={30} />
                        </div>
                        <FlexColumn gap={5} className={styles.txt14_828282}>
                            <div className={styles.txt15_696969}>
                                보유수량 :
                                <span style={{marginLeft:'5px'}}>
                                    {decimalFormat(tronBalance?.usrBalance,3)} TRON
                                </span>
                            </div>
                            <div>
                                TRON 가격 :
                                <span style={{marginLeft:'5px'}}>
                                    ${decimalFormat(tronSwapConfig?.dealPrc,2)}
                                </span>
                                <span className={styles.txt12_828282} style={{marginLeft:'4px'}}>
                                    (기준일자 : {tronSwapConfig?.modDtmFormat})
                                </span>
                            </div>
                        </FlexColumn>
                    </FlexRowSpace>
                </FlexColumn>
                <DefaultInput
                    lable={'수량입력 (교환비율  1 : '+decimalFormat(swapRt,3)+')'}
                    error={errors.vlm}
                    titleClassName={styles.txt16_500}
                    inputElement={
                        <input
                            style={{border:'none'}}
                            type={"text"}
                            className={styles.inputText_44}
                            placeholder={t("Swap 수량을 입력해주세요.")}
                            {...register("vlm", {
                                required: t("출금 수량을 입력해주세요.")
                                ,onChange: (el) => {
                                    let vlm = new bigDecimal(0);
                                    try {
                                        vlm = new bigDecimal(el.target.value);
                                    } catch (e){
                                        vlm = new bigDecimal(0);
                                        setValue('vlm', 0);
                                        return;
                                    }
                                    setBtnActive(false);
                                    validate(vlm.getValue())
                                }
                            })}
                        />
                    }
                />
                <FlexRowCenter style={{marginTop:'-30px'}}>
                    <img src={require('../../assets/images/arrow_bottom.png') } width={30} />
                </FlexRowCenter>
                <FlexColumn style={{marginTop:'10px'}} className={styles.bigSelect}>
                    <FlexRowStart gap={20} >
                        <div>
                            <img src={"https://image.uniboost.io/uniboost/drm.png"} width={30} />
                        </div>
                        <FlexColumn gap={5} className={styles.txt14_828282}>
                            <div className={styles.txt15_696969}>
                                보유수량 :
                                <span style={{marginLeft:'5px'}}>
                                    {decimalFormat(drmBalance?.usrBalance,3)}  DRM
                                </span>
                            </div>
                            <div>
                                DRM 가격 :
                                <span style={{marginLeft:'5px'}}>
                                    ${decimalFormat(drmSwapConfig?.dealPrc,2)}
                                </span>
                                <span className={styles.txt12_828282} style={{marginLeft:'4px'}}>
                                    (기준일자 : {drmSwapConfig?.modDtmFormat})
                                </span>
                            </div>
                        </FlexColumn>
                    </FlexRowStart>
                    <div className={styles.bigSelectContent} style={dropMenu1} >
                        <a  onClick={()=>{setDropMenu1({display:'none'}); console.log("22222"); }}><img src={require('../../assets/images/symbol/USDT.png') } width={28}/>  USDT</a>
                    </div>
                </FlexColumn>
                <FlexColumnCenter style={{padding: '20px 0px',marginBottom:'40px'}}>
                    <FlexColumEnd className={styles.txt18_700_374151} gap={20} style={{
                        border: '1px solid #0F8CFF',
                        width: '100%',
                        background: '#FFFFFF',
                        borderRadius: '12px',
                        padding: '20px 20px'
                    }}>
                        <FlexRowSpace gap={10}>
                            <div>{t('출금 TRON수량')} :</div>
                            <div>
                                {decimalFormat(swapTronVlm,3)}
                                <span className={styles.txt14_828282} style={{marginLeft:'5px'}}>TRON</span>
                            </div>
                        </FlexRowSpace>
                        <FlexRowSpace gap={10}>
                            <div>{t('입금 DRM수량')} :</div>
                            <div>
                                {decimalFormat(swapDrmVlm, 3)}
                                <span className={styles.txt14_828282} style={{marginLeft:'5px'}}>DRM</span>
                            </div>
                        </FlexRowSpace>
                    </FlexColumEnd>
                </FlexColumnCenter>
            </FlexColumn>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={btnActive}
                        backgroundColor={btnActive ? "#0F8CFF" : "#8D94A0"}
                        textColor={"#FFFFFF"}
                        // border={"solid 1px #dbdee6"}
                        onClick={handleSubmit(onSubmit)}
                        text={t("스왑신청")}
                    />
                </FlexColumn>
            </FlexBottom>
            </>
        )}
        </>
    )
}


