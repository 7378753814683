import http from './http';
///////////////// ajeitar /////////
class StakingService {
    async getSelectStaking(currentPage?:number, blockSize?:number, paging?:boolean){
        return http
            .get('/staking/selectStakingList', {
                params:{
                    currentPage:currentPage,
                    blockSize:blockSize,
                    paging:paging
                }
            })
            .then((response) => {
                return response.data;
            });
    }
    async getSelectStakingEnd(currentPage?:number, blockSize?:number, paging?:boolean){
        return http
            .get('/staking/selectStakingEndList', {
                params:{
                    currentPage:currentPage,
                    blockSize:blockSize,
                    paging:paging
                }
            })
            .then((response) => {
                return response.data;
            });
    }

    async buyStaking(stkVlm:string, crncyCd:string) {
        return http
            .post('/staking/buyStaking', {
                stkVlm,
                crncyCd
            })
            .then((response) => {
                return response.data;
            });
    }
    async autoStaking(stkIdx:string, autoYn:string) {
        return http
            .post('/staking/autoStaking', {
                stkIdx,
                autoYn
            })
            .then((response) => {
                return response.data;
            });
    }

}
export default new StakingService();