import {Bar, FlexColumn, FlexRowAround, PageRootContainer} from "../../components/common/pageArea";
import MainTop from "../../components/layer/mainTop";
import styles from "../../assets/styles/uni.module.scss";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import React, {useState} from "react";
import CoinList from "../../components/my/coinList";
import MyAccount from "../../components/my/myAccount";
import SwapView from "../../components/my/swapView";

const QrArea = styled.div`
  width: 160px;
  height: 160px;
  flex-grow: 0;
  margin: 0 4px 20px;
  padding: 10.5px 13.2px 11.4px 10.5px;
  border-radius: 24px;
  border: solid 1px #d4d8e2;
`;

export default function My(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [pageMode, setPageMode] = useState(1);
    const [showRefPop, setShowRefPop] = useState(false);

    return(
        <>
            <MainTop />
            <PageRootContainer style={{paddingTop:'85px', minHeight:'80vh'}}>
                <FlexColumn gap={25}>
                    <MyAccount pageMode={'my'}/>
                    <FlexRowAround gap={20} className={styles.txt14_500}>
                        <FlexRowAround className={styles.yellow_in_btn} style={{borderRadius:'10px', width:'100%'}} onClick={()=>{navigate('/staking?open=Y')}}>
                            보너스 매칭 현황
                        </FlexRowAround>
                        <FlexRowAround className={styles.blue_in_btn} style={{borderRadius:'10px', width:'100%'}}
                            onClick={()=>{
                                navigate('/staking')
                            }}
                        >
                            스테이킹 참여하기
                        </FlexRowAround>
                    </FlexRowAround>
                    <Bar/>
                    <FlexRowAround style={{marginTop:'-20px'}}>
                        <div className={pageMode === 1 ? styles.topTabActive : styles.topTabDefault} onClick={() => { setPageMode(1); }} >
                            {t('코인')}
                        </div>
                        {/*<div className={pageMode === 2 ? styles.topTabActive : styles.topTabDefault} onClick={() => { setPageMode(2); }} >*/}
                        {/*    {t('대출')}*/}
                        {/*</div>*/}
                        <div className={pageMode === 2 ? styles.topTabActive : styles.topTabDefault} onClick={() => { setPageMode(2); }} >
                            {t('스왑')}
                        </div>
                    </FlexRowAround>
                    { pageMode === 1 && (<CoinList  pageMode={pageMode}/>)}
                    {/*{ pageMode === 2 && (<LoanList  pageMode={pageMode}/>)}*/}
                    { pageMode === 2 && (<SwapView  pageMode={pageMode}/>)}

                </FlexColumn>

            </PageRootContainer>
        </>
    )
}