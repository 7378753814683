import DefaultInput from "../common/input/DefaultInput";
import styles from "../../assets/styles/uni.module.scss";
import React from "react";
import {useForm} from "react-hook-form";
import PageTitle, {
    FlexBottom,
    FlexColumEnd,
    FlexColumn, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen, PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../../components/common/pageArea";
import BottomButton from "../common/button/BottomButton";
import {changeNickNameAsync, changePassAsync} from "../../store/modules/authSlice";
import {Toast} from "../common/popup/ToastAlert";
import {useAppDispatch} from "../../store/hooks";
import {useTranslation} from "react-i18next";

export default function PassItem(props:{setShow:any;}){
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {
        register,
        formState: { errors, isDirty, isValid },
        setError,
        clearErrors,
        handleSubmit
    } = useForm();
    const onSubmit = (data: any) => {
        console.log(data);
        const {curUsrPw, newUsrPw, newUsrPw1} = data;
        if (newUsrPw !== newUsrPw1) {
            setError("newUsrPw1", {
                type: "custom",
                message: t("지갑 패스워드가 일치 하지 않습니다."),
            });
        }else {
            dispatch(changePassAsync({curUsrPw, newUsrPw, newUsrPw1}))
                .unwrap()
                .then(() => {
                    Toast(t('변경 되었습니다.'))
                    props.setShow(false);

                })
                .catch(() => {
                });
        }

    }
    return (
        <>
            <PageContent style={{paddingTop:'83px'}}>
                <DefaultInput
                    lable={t("현재 패스워드")}
                    error={errors.curUsrPw}
                    inputElement={
                        <input
                            type={"password"}
                            className={styles.inputText_Bottom}
                            placeholder={t("현재 패스워드를 입력해주세요.")}
                            {...register("curUsrPw", {
                                required: t("현재 패스워드를 입력해주세요."),
                                pattern: {
                                    value: /^(?=.*).{4,30}$/i,
                                    message: t("패스워드를 4자리 이상 입력해 주세요."),
                                }
                            })}
                        />
                    }
                />
                <DefaultInput
                    lable={t("변경할 패스워드")}
                    error={errors.newUsrPw}
                    inputElement={
                        <input
                            type={"password"}
                            className={styles.inputText_Bottom}
                            placeholder={t("변경할 패스워드를 입력해주세요.")}
                            {...register("newUsrPw", {
                                required: t("변경할 패스워드를 입력해주세요."),
                                pattern: {
                                    value: /^(?=.*).{4,30}$/i,
                                    message: t("패스워드를 4자리 이상 입력해 주세요."),
                                }
                            })}
                        />
                    }
                />
                <DefaultInput
                    lable={t("변경할 패스워드 확인")}
                    error={errors.newUsrPw1}
                    inputElement={
                        <input
                            type={"password"}
                            className={styles.inputText_Bottom}
                            placeholder={t("변경할 패스워드를 한번 더 입력해주세요.")}
                            {...register("newUsrPw1", {
                                required: t("변경할 패스워드를 한번 더 입력해주세요."),
                            })}
                        />
                    }
                />
            </PageContent>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#FFFFFF"}
                        textColor={"black"}
                        // border={"solid 1px #dbdee6"}
                        onClick={()=>{props.setShow(false);}}
                        text={t("취소하기")}
                    />
                </FlexColumn>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#FFDD00"}
                        textColor={"black"}
                        // border={"solid 1px #dbdee6"}
                        onClick={handleSubmit(onSubmit)}
                        text={t("변경하기")}
                    />
                </FlexColumn>
            </FlexBottom>
        </>
    )
}