import styles from "../../assets/styles/uni.module.scss";
import {FlexColumn, FlexColumnCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/pageArea";
import {Trans, useTranslation} from "react-i18next";
import Toggle from "react-toggle";
import React, {useEffect, useState} from "react";
import {IStakingList} from "../../pages/staking/stakingPage";
import moment from "moment";
import StakingService from "../../services/staking.service";
import {Toast} from "../common/popup/ToastAlert";
import {decimalFormat} from "../../helpers/utilities";
import Countdown from 'react-countdown';

export const ft = (val:number):string =>{
    if (val < 10) {
        return "0" + val;
    }
    return val+"";
}

export const renderer = ({ days,hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <span className={styles.txt14_500} onClick={()=>{window.location.reload();}}>
                        처리중
                        <img src={require('../../assets/images/home/reload.png')} width={16} style={{marginBottom:'4px'}} />
                    </span>;
    } else {
        // Render a countdown
        return <span>{ft(hours+(days*24))}:{ft(minutes)}:{ft(seconds)}</span>;
    }
};
export default function Stakinglist(props:{stkData:IStakingList, onClickViewItem?:(item: any)=> void;}){
    const { t } = useTranslation();
    return (
        <FlexColumnCenter className={styles.blue01_in_btn} style={{borderRadius:'12px'}} gap={15}>
            <FlexRowSpace gap={10} onClick={()=>{
                if(props.onClickViewItem) {
                    props.onClickViewItem(props.stkData);
                }
            }}>
                <FlexRowStart>스테이킹 수량</FlexRowStart>
                <FlexRowEnd className={styles.black_in_box} style={{width:"200px"}} gap={10}>
                    {
                        props.stkData.stusCd === "PENDING" ? (
                            <>
                                <div className={styles.txt15_W}>처리중</div>
                            </>
                        ) : (
                            <>
                                <div className={styles.txt15_W}>남은기간</div>
                                <div className={styles.txt15_700_W}>D-{moment(props.stkData.endDtm).diff(moment(new Date()) ,"days")}</div>
                            </>
                        )
                    }
                </FlexRowEnd>
            </FlexRowSpace>
            <FlexRowSpace>
                <FlexRowStart gap={5}>
                    <span className={styles.txt20_700}>{decimalFormat(props.stkData.stkVlm,2)}</span> <span>DRM</span>
                </FlexRowStart>
                <FlexRowEnd className={styles.txt15_W} gap={10}>
                                        <span style={{marginBottom:"auto",marginTop:"auto",fontSize:'12px'}}>
                                            자동연장
                                        </span>
                    <Toggle
                        defaultChecked={props.stkData.autoYn==="Y" ? true : false}
                        icons={{
                            checked: <span>On</span>,
                            unchecked: <span>Off</span>,
                        }}
                        onChange={(e)=>{
                            StakingService.autoStaking(String(props.stkData.stkIdx), e.target.checked ? "Y" : "N").then((res)=>{
                                if(res.data){
                                    Toast('자동연장이 수정되었습니다.');
                                }
                            });
                        }} />
                </FlexRowEnd>
            </FlexRowSpace>
            {
                props.stkData.bnsYn==='N'?(
                    <FlexRowSpace className={`${styles.yellow_in_btn} ${styles.txt14_500}`} style={{borderRadius:'12px',padding:'10px 24px'}} gap={0}>
                        <FlexRowStart>매칭 보너스 대기중</FlexRowStart>
                        <FlexRowEnd className={styles.txt16_700}>
                            <Countdown
                                date={Date.now() + props.stkData.regDtmTime+(1000*60*60*48)  - Date.now()}
                                daysInHours={true}
                                renderer={renderer}
                            />
                        </FlexRowEnd>
                    </FlexRowSpace>
                ):
                (
                  <></>
                )
            }
            {
                props.stkData.intrstYn==='Y'?(
                        <FlexRowSpace className={`${styles.white_in_btn} ${styles.txt14_500}`} style={{borderRadius:'12px',padding:'10px 24px'}} gap={0}>
                            <FlexRowStart className={styles.txt15_500}>배당완료</FlexRowStart>
                            <FlexRowEnd className={styles.txt15_500}>
                                {decimalFormat(props.stkData.intrstVlm,3)} TRON
                            </FlexRowEnd>
                        </FlexRowSpace>
                    ):
                    (
                        <></>
                    )
            }
        </FlexColumnCenter>
    )
}
