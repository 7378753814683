import PageTitle, {
    FlexColumn,
    FlexColumnCenter,
    FlexRowStart,
    PageContent,
    PageRootContainer
} from "../../components/common/pageArea";
import styles from "../../assets/styles/uni.module.scss";
import HomeLayer2 from "../../components/home/home_layer2";
import Footer from "../../components/layer/footer";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import DefaultInput from "../../components/common/input/DefaultInput";
import {useForm} from "react-hook-form";
import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {loginAsync, selectAuth} from "../../store/modules/authSlice";


export default function Login(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { asyncError } = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
        setError,
        clearErrors
    } = useForm();
    const onSubmit = async (data:any): Promise<void> => {

        const {usrId, usrPw} = data;
        setLoading(true);

        dispatch(loginAsync({ usrId, usrPw }))
            .unwrap()
            .then(() => {
                setLoading(true);
                navigate('/main');
                window.location.reload();
            })
            .catch(() => {
                setLoading(false);
            });
    }

    return (
        <PageRootContainer>
            <PageTitle
                leftSrc={require('../../assets/images/home/logo.png')}
                leftSrcSize={113}
                onClickLeft={()=>{navigate("/")}}
                rightSrc={require('../../assets/images/home/close.png')}
                onClickRight={()=>{navigate(-1)}}
            />
            <PageContent style={{height:'80vh', paddingTop:'96px'}}>
                <FlexColumn gap={44}>
                    <FlexColumnCenter className={styles.txt34_700}>{t('로그인')}</FlexColumnCenter>
                    <FlexColumn gap={22}>
                        <DefaultInput
                            lable={t('이메일')}
                            error={errors.usrId}
                            titleClassName={styles.txt16_500}
                            inputElement={
                                <input
                                    type={"text"}
                                    className={styles.inputText_44}
                                    placeholder={t("이메일을 입력해주세요.")}
                                    {...register("usrId", {
                                        pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                                            message: t("이메일 형식이 올바르지 않습니다."),
                                        },
                                    })}
                                />
                            }
                        />
                        <DefaultInput
                            lable={t("패스워드")}
                            error={errors.usrPw}
                            titleClassName={styles.txt16_500}
                            inputElement={
                                <input
                                    type={"password"}
                                    className={styles.inputText_44}
                                    placeholder={t("지갑 패스워드를 입력해주세요.")}
                                    {...register("usrPw", {
                                        pattern: {
                                            value: /^(?=.*).{4,30}$/i,
                                            message: t("패스워드를 4자리 이상 입력해 주세요."),
                                        }
                                    })}
                                />
                            }
                        />
                    </FlexColumn>
                    <FlexColumnCenter className={styles.blue_in_btn} style={{borderRadius:'10px', width:'100%', fontSize:'15px', fontWeight:'700'}} onClick={handleSubmit(onSubmit)}>{t('로그인')}</FlexColumnCenter>
                    <FlexColumnCenter className={styles.txt16_500} onClick={()=>{navigate('/emailAuth')}}>{t('패스워드 찾기')}</FlexColumnCenter>
                </FlexColumn>
            </PageContent>
            <Footer />
        </PageRootContainer>
    )
}