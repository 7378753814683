import styles from "../../assets/styles/uni.module.scss";
import DefaultInput from "../common/input/DefaultInput";
import {FlexBottom, FlexColumn, FlexRowAround, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/pageArea";
import React, {useCallback, useMemo} from "react";
import {useForm} from "react-hook-form";
import BottomButton from "../common/button/BottomButton";
import {useTranslation} from "react-i18next";
import {IWithdrawDepth} from "./withdrawDepth1";
import ProfileService from "../../services/profile.service";
import WalletService from "../../services/wallet.service";
import Button34 from "../common/button/Button34";
import {ToastError} from "../common/popup/ToastAlert";


export default function WithdrawDepth2(props:IWithdrawDepth){
    const { t } = useTranslation();
    const {
        register,
        formState: { errors, isDirty, isValid },
        setError,
        clearErrors,
        setValue,
        getValues,
        handleSubmit
    } = useForm();
    const onSubmit = (data: any) => {
        if (props.coinView?.inWthdrwYn === 'N') {
            ToastError('해당코인은 출금이 불가능 합니다.');
            return;
        }
        WalletService.checkAddr(props.coinView.crncyCd,data.addr).then(res=>{
            if(res.data){
                props.setWalletSetup({
                    ...props.walletSetup,
                    rcvAddr : data.addr,
                    inUser : res.data.inUser,
                    inUsrId : res.data.inUsrId
                })
                props.setPageDepth(3);
            }
        })
    }

    const searchRcvAddr = useCallback(() => {
        clearErrors();
        let rcvUsrId = getValues('rcvUsrId');
        if (rcvUsrId === '') {
            setError('rcvUsrId'
                , {
                    message: '아이디를 입력해 주세요.'
                }
                , {shouldFocus: true}
            );
            return;
        }

        WalletService.getAddrByUsrId(rcvUsrId,props.coinView.crncyCd).then(res=>{
            let data = res.data;
            if(data.status==='ID'){
                setError('rcvUsrId'
                    , {
                        message: '아이디를 찾을 수 없습니다.'
                    }
                    , {shouldFocus: true}
                );
                return;
            }else if (data.status === 'ADDR') {
                setError('rcvUsrId'
                    , {
                        message: '주소를 찾을 수 없습니다.'
                    }
                    , {shouldFocus: true}
                );
                return;
            } else {
                setValue('addr', data.addr);
            }
        })



    }, []);

    return (
        <>
            <FlexColumn gap={20}>
                <div className={styles.txt20_500}>
                    {
                        props.coinView.imgPath?
                            <div>
                                <img src={props.coinView.imgPath} width={30} style={{paddingRight:'5px'}}/>
                                {t("받을 지갑 주소")}
                            </div>
                            :<div>{t("받을 지갑 주소")}</div>
                    }

                </div>
                <DefaultInput
                    error={errors.addr}
                    inputElement={
                        <input
                            type={"text"}
                            className={styles.inputText_Bottom}
                            placeholder={t("입금 받을 주소를 입력해주세요.")}
                            {...register("addr", {
                                required: t("입금 받을 주소를 입력해주세요.")
                            })}
                        />
                    }
                />
            </FlexColumn>

            <FlexColumn style={{marginTop:'30px'}}>
                <FlexRowStart className={styles.txt16_500_828282}>{t("아이디로 지갑주소 검색이 가능 합니다.")}</FlexRowStart>
                <FlexRowSpace gap={10}>
                    <FlexRowStart gap={10}>
                        <DefaultInput
                            error={errors.rcvUsrId}
                            inputElement={
                                <input
                                    type={"text"}
                                    className={styles.inputText_Bottom}
                                    placeholder={t("아이디를 입력해 주세요.")}
                                    {...register("rcvUsrId", {
                                        // required: t("아이디를 입력해 주세요.")
                                    })}
                                />
                            }
                        />
                    </FlexRowStart>
                    <Button34 text={t("조회하기")} type={'blue'} onClick={()=>{
                        searchRcvAddr();
                    }}/>
                </FlexRowSpace>
            </FlexColumn>





            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#317FF4"}
                        textColor={"#FFFFFF"}
                        // border={"solid 1px #dbdee6"}
                        onClick={handleSubmit(onSubmit)}
                        text={t("다음")}
                    />
                </FlexColumn>
            </FlexBottom>
        </>
    )
}